//-----------Action types----------//

export const NOTIFICATION_WINDW_SHOW = "showNotificationWindow";
export const NOTIFICATION_WINDOW_HIDE = "hideNotificationWindow";
export const SET_NOTIFICATION_LEVEL = "SET_NOTIFICATION_LEVEL";
export const SET_NOTIFICATION_TYPE = "SET_NOTIFICATION_TYPE";
export const SET_NOTIFICATION_MAP = "SET_NOTIFICATION_MAP";

//---------Action creators-------------//

export const showNotificationWindow = payload => {
  return {
    type: NOTIFICATION_WINDW_SHOW,
    payload
  };
};

export const hideNotificationWindow = payload => {
  return {
    type: NOTIFICATION_WINDOW_HIDE,
    payload
  };
};

export const setNotificationLevel = payload => {
  return {
    type: SET_NOTIFICATION_LEVEL,
    payload
  };
};

export const setNotificationType = payload => {
  return {
    type: SET_NOTIFICATION_TYPE,
    payload
  };
};

export const setNotificationMap = payload => {
  return {
    type: SET_NOTIFICATION_MAP,
    payload
  };
};
