import { createSelector } from "reselect";

export const selectPeers = createSelector(
  state => {
    return state.peers;
  },
  state => {
    return state.participants.displayedUsers;
  },
  (peers, displayedUsers) => {
    const peersArray = [];
    for (const user of displayedUsers) {
      const peer = peers[user];
      if (peer) {
        peersArray.push(peer);
      }
    }
    return peersArray;
  }
);
