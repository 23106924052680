import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PortalLogo from "../Icons/PortalLogo";
import { PortalLogoutModal } from "../modals";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  logoDimension: {
    height: "30px",
    maxWidth: "180px",
    objectFit: "contain"
  },
  logo: {
    height: "100%",
    width: "180px",
    display: "flex",
    alignItems: "center",
    marginLeft: "30px"
  },
  salutationLogoutDiv: {
    display: "flex",
    alignItems: "center",
    color: theme.colors.primaryMainTextColor
  },
  salutation: {
    alignSelf: "center",
    marginRight: "20px"
  }
});

class PortalHeader extends Component {
  getDisplayName = () => {
    const { session } = this.props;

    return session.portalUser
      ? session.portalUser.firstName + " " + session.portalUser.lastName
      : "";
  };

  render() {
    const { classes, displayLogout } = this.props;
    return (
      <AppBar position="fixed">
        <Toolbar disableGutters={true}>
          <div className={classes.logo}>
            <PortalLogo
              classes={{ root: classes.logoDimension }}
              logoType="header"
            />
          </div>
          <div className={classes.salutationLogoutDiv}>
            {displayLogout && (
              <Fragment>
                <LocalizedText value="hi" variant="h6" />
                <Typography color="inherit" variant="h6">
                  {", " + this.getDisplayName()}
                </Typography>
              </Fragment>
            )}
            {displayLogout && <PortalLogoutModal />}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(connect(mapStateToProps)(PortalHeader));
