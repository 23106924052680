import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  MenuItem,
  InputBase
} from "@material-ui/core";
import { getIntl } from "../../utils";
import Stop from "@material-ui/icons/Stop";
import { MainPageDummy } from "../pages";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import { getThemes, updatePersistentSpaceThemeID } from "./actions";
import CTXSelect from "../reusable/CTXSelect";

const styles = theme => ({
  root: {
    minWidth: "660px",
    width: "35%",
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px 5px 10px"
  },
  rootFullHeight: {
    height: "100%"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.primaryTextColor}`
  },
  rootBackgroundColor: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  cardHeaderRow: {
    padding: "0px 16px 0px 16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  cardHeader: {
    width: "100%",
    padding: "0px",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  content: {
    flex: "1 1 0",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    display: "inline"
  },
  cardContent: {
    padding: "0px 16px 0px 16px",
    flexGrow: 1
  },
  buttonMargin: {
    margin: "5px"
  },
  valueTypography: {
    width: "60%",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  circle: {
    width: "2em",
    height: "1.5em"
  },
  svg: {
    width: "2em",
    paddingRight: "10px"
  },
  themeContainer: {
    width: "100%",
    overflow: "auto"
  },
  themeCardAndPreviewContainer: {
    paddingRight: "10px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.colors.popoverBackgroundColor,
    backgroundClip: "content-box"
  },
  previewContainer: {
    width: "65%",
    minWidth: "480px",
    margin: "5px",
    border: `5px solid ${theme.colors.secondaryMainColor}`
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  themeHeaderAndSelect: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  themeSelect: {
    marginBottom: "20px"
  },
  menuItem: {
    color: theme.colors.primaryTextColor,
    paddingRight: "20px",
    width: "100%"
  },
  msgBox: {
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px",
    color: theme.colors.primaryTextColor
  },
  msgBoxSelect: {
    marginBottom: "6px"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  themePickerContainer: {
    minWidth: "660px",
    width: "35%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 16px 10px 16px"
  }
});

class ThemeDetailsCard extends Component {
  state = {
    open: false,
    isSelectionInProgress: false,
    currentSelectedTheme: this.props.theme,
    systemDefaultThemeName: null
  };

  async componentDidMount() {
    if (
      this.props.session.portalUser.admin &&
      this.props.portal.theme.themeMap == null
    ) {
      await this.props.getThemes(this.props.session.portalSessionId);
    }

    const themes = this.props.portal.theme.themeMap;
    if (themes != null) {
      for (let [, theme] of Object.entries(themes)) {
        if (theme.isDefault) {
          this.setState({ systemDefaultThemeName: theme.themeName });
        }
      }
    }
  }

  getSystemDefaultThemeName = theme => {
    const systemDefaultThemeName = this.state.systemDefaultThemeName;
    const systemDefaultTranslation = this.props.intl.formatMessage(
      getIntl("systemDefault")
    );

    return theme.id === 0 && systemDefaultThemeName == null
      ? systemDefaultTranslation
      : theme.id === 0
      ? systemDefaultTranslation + " - " + systemDefaultThemeName
      : theme.themeName;
  };

  getCardHeaderControl = () => {
    const { classes, theme, intl, session } = this.props;

    return (
      <div className={classes.cardHeaderRow}>
        <CardHeader
          title={
            intl.formatMessage(getIntl("themeName")) +
            ": " +
            this.getSystemDefaultThemeName(theme)
          }
          titleTypographyProps={{ color: "inherit", variant: "h5" }}
          classes={{
            root: classes.cardHeader,
            content: classes.content,
            title: classes.title
          }}
        />
        {session.portalUser && session.portalUser.admin ? (
          <TextButton
            onClick={this.handleApplyingAnotherTheme}
            className={classes.buttonMargin}
          >
            <LocalizedText value="chooseADifferentTheme" />
          </TextButton>
        ) : undefined}
      </div>
    );
  };

  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        <div className={classes.hrDivPrimary} />
        {this.getThemeDetails()}
      </CardContent>
    );
  };

  getThemeDetails = () => {
    const { theme } = this.props;
    let themeArray = [];

    for (let [themeKey, themeValue] of Object.entries(theme)) {
      if (themeKey !== "isdefault" && themeKey !== "imagesLocation") {
        themeArray.push(this.getThemeColorRow(themeKey, themeValue));
      }
    }
    return themeArray;
  };

  getThemeColorRow = (themeKey, themeValue) => {
    const { classes, intl } = this.props;
    return themeKey !== "isDefault" &&
      themeKey !== "id" &&
      themeKey !== "themeName" ? (
      <div
        key={themeKey}
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.justifyContentSpaceBetween
        )}
      >
        <Typography variant="body1" color="inherit">
          {themeKey.startsWith("color")
            ? intl.formatMessage(getIntl(themeKey + "Title"))
            : intl.formatMessage(getIntl(themeKey))}
        </Typography>
        <div className={classes.valueTypography}>
          {themeKey.startsWith("color") ? (
            <Stop style={{ color: themeValue }} />
          ) : undefined}
          <Typography variant="body1" color="inherit">
            {themeValue}
          </Typography>
        </div>{" "}
      </div>
    ) : undefined;
  };

  handleApplyingAnotherTheme = async () => {
    if (this.props.portal.theme.themeMap == null) {
      await this.props.getThemes(this.props.session.portalSessionId);
    }
    this.setState({
      isSelectionInProgress: true
    });
  };

  handleSaveApplyingAnotherTheme = () => {
    const { portal, session } = this.props;
    const userID = portal.portalUserManagement.selectedUser
      ? portal.portalUserManagement.selectedUser.userID
      : session.portalUser.userIdDB;

    this.props.updatePersistentSpaceThemeID(
      session.portalSessionId,
      userID,
      this.state.currentSelectedTheme.id
    );

    this.setState({
      isSelectionInProgress: false
    });
  };

  handleCancelApplyingAnotherTheme = () => {
    this.setState({
      isSelectionInProgress: false,
      currentSelectedTheme: this.props.theme
    });
  };

  getThemeSelectionContainer = open => {
    let menuItems = [];
    let { classes } = this.props;
    let themeObject = null;

    const themes = this.props.portal.theme.themeMap;
    if (themes != null) {
      for (let [, theme] of Object.entries(themes)) {
        if (theme.id === this.state.currentSelectedTheme.id) {
          themeObject = theme;
        }
        menuItems.push(this.getMenuItem(theme, classes));
      }
    }

    return (
      <div className={classes.themePickerContainer}>
        <div className={classes.themeHeaderAndSelect}>
          <LocalizedText
            value="selectAThemeToViewThePreview"
            className={classes.themeSelect}
          />
          <CTXSelect
            open={open}
            value={themeObject}
            renderValue={this.handleRenderValue}
            onClose={this.handleSelectClose}
            onOpen={this.handleSelectOpen}
            onChange={this.handleSelectChange}
            input={<InputBase />}
          >
            {menuItems}
          </CTXSelect>
        </div>
        <div
          className={classNames(
            classes.displayFlexRow,
            classes.alignItemsCenter,
            classes.justifyContentSpaceBetween
          )}
        >
          <TextButton
            onClick={this.handleSaveApplyingAnotherTheme}
            className={classes.buttonMargin}
          >
            <LocalizedText value="save" />
          </TextButton>
          <TextButton
            color="error"
            onClick={this.handleCancelApplyingAnotherTheme}
            className={classes.buttonMargin}
          >
            <LocalizedText value="cancel" className={classes.errorTextColor} />
          </TextButton>
        </div>
      </div>
    );
  };

  getMenuItem = (theme, classes) => {
    let themeName = this.getSystemDefaultThemeName(theme);
    return (
      <MenuItem
        key={theme.id}
        value={theme}
        classes={{ root: classes.menuItem }}
      >
        <Typography className={classes.menuItem} variant="subtitle1" noWrap>
          {themeName}
        </Typography>
      </MenuItem>
    );
  };

  handleRenderValue = () => {
    const { classes } = this.props;
    let themeName = this.getSystemDefaultThemeName(
      this.state.currentSelectedTheme
    );
    return (
      <Typography className={classes.menuItem} variant="subtitle1" noWrap>
        {themeName}
      </Typography>
    );
  };

  handleSelectClose = () => {
    this.setState({ open: false });
  };

  handleSelectOpen = () => {
    this.setState({ open: true });
  };

  handleSelectChange = event => {
    this.setState({ currentSelectedTheme: event.target.value });
  };

  render() {
    const { classes, portal } = this.props;
    const { open, isSelectionInProgress, currentSelectedTheme } = this.state;

    let defaultTheme = currentSelectedTheme;
    if (portal.theme.themeMap !== null && currentSelectedTheme.id === 0) {
      defaultTheme = portal.theme.themeMap.filter(theme => theme.isDefault)[0];
    }

    return (
      <div className={classNames(classes.themeContainer, classes.scrollBar)}>
        <div className={classes.themeCardAndPreviewContainer}>
          {!isSelectionInProgress ? (
            <Card
              className={classNames(
                classes.root,
                this.props.fullHeight ? classes.rootFullHeight : undefined,
                this.props.backgroundColor
                  ? undefined
                  : classes.rootBackgroundColor
              )}
              style={{
                backgroundColor: this.props.backgroundColor
                  ? this.props.backgroundColor
                  : undefined
              }}
            >
              {this.getCardHeaderControl()}
              {this.getCardContentControl()}
            </Card>
          ) : (
            this.getThemeSelectionContainer(open)
          )}
          <div
            className={classNames(classes.previewContainer, classes.scrollBar)}
          >
            <MainPageDummy
              color1={defaultTheme.color1}
              color2={defaultTheme.color2}
              color3={defaultTheme.color3}
              color4={defaultTheme.color4}
              headerLogo={defaultTheme.headerLogo}
              bodyLogo={defaultTheme.bodyLogo}
              backgroundImage={defaultTheme.backgroundImage}
              imagesLocation={defaultTheme.imagesLocation}
              rightBarWithButtons
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ portal, session }) => ({ portal, session });

const mapDispatchToProps = dispatch => ({
  getThemes: sessionId => dispatch(getThemes(sessionId)),
  updatePersistentSpaceThemeID: (sessionID, userID, themeID) =>
    dispatch(updatePersistentSpaceThemeID(sessionID, userID, themeID))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(ThemeDetailsCard))
  )
);
