import React, { Component } from "react";
import { Chat } from "./";
import { makeResponsive } from "../reusable/ResponsiveContainer";

class ChatResponsive extends Component {
  render() {
    return <Chat {...this.props} />;
  }
}

export default makeResponsive(ChatResponsive);
