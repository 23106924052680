import {
  SHOW_INVITE_PARTICIPANT,
  HIDE_INVITE_PARTICIPANT,
  SHOW_INVITE_BY_PHONE,
  HIDE_INVITE_BY_PHONE,
  UPDATE_INVITE_BY_PHONE_PARTICIPANT
} from "./actions";
import {
  InviteParticipantWindowState,
  InviteByPhoneWindowState,
  InviteByPhoneParticipantState
} from "./";
import { USER_LOGOUT } from "../../actions/types";

export default function inviteParticipantReducer(
  state = {
    inviteParticipantWindowStatus: InviteParticipantWindowState.CLOSE,
    inviteByPhoneWindowStatus: InviteByPhoneWindowState.CLOSE,
    inviteByPhoneParticipant: {
      userId: undefined,
      partyid: undefined,
      partyName: "",
      partyIsModerator: false,
      partyUserDefined1: "",
      partyUserDefined2: "",
      partyUserDefined3: "",
      partyUserDefined4: "",
      state: InviteByPhoneParticipantState.INIT,
      partyNameReadOnly: false
    }
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case SHOW_INVITE_PARTICIPANT:
      newState = {
        ...state,
        inviteParticipantWindowStatus: InviteParticipantWindowState.OPEN
      };
      return newState;
    case HIDE_INVITE_PARTICIPANT:
      newState = {
        ...state,
        inviteParticipantWindowStatus: InviteParticipantWindowState.CLOSE
      };
      return newState;
    case SHOW_INVITE_BY_PHONE:
      newState = {
        ...state,
        inviteByPhoneWindowStatus: InviteByPhoneWindowState.OPEN
      };
      return newState;
    case HIDE_INVITE_BY_PHONE:
      newState = {
        ...state,
        inviteByPhoneWindowStatus: InviteByPhoneWindowState.CLOSE
      };
      return newState;
    case UPDATE_INVITE_BY_PHONE_PARTICIPANT:
      newState = {
        ...state,
        inviteByPhoneParticipant: {
          ...state.inviteByPhoneParticipant,
          ...action.payload
        }
      };
      return newState;
    case USER_LOGOUT:
      newState = {
        inviteParticipantWindowStatus: InviteParticipantWindowState.CLOSE,
        inviteByPhoneWindowStatus: InviteByPhoneWindowState.CLOSE,
        inviteByPhoneParticipant: {
          userId: undefined,
          partyid: undefined,
          partyName: "",
          partyIsModerator: false,
          partyUserDefined1: "",
          partyUserDefined2: "",
          partyUserDefined3: "",
          partyUserDefined4: "",
          state: InviteByPhoneParticipantState.INIT,
          partyNameReadOnly: false
        }
      };
      return newState;
    default:
      return state;
  }
}
