import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { getIntl } from "../../utils";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import InputBase from "@material-ui/core/InputBase";
import classNames from "classnames";
import Logger from "../../Logger";

const logger = new Logger("ChatFooter");

const styles = theme => ({
  root: {
    height: "100%"
  },
  chatFooter: {
    display: "flex",
    flex: "1 1",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0px 10px 0px"
  },
  msgBox: {
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px",
    color: theme.colors.primaryTextColor
  },
  msgBoxPadding: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  sendIcon: {
    width: "1.75em",
    padding: "3px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  }
});

class ChatFooter extends Component {
  constructor(props) {
    super(props);
    this.chatMsgBoxRef = React.createRef();
  }

  state = {
    chatMsg: ""
  };

  setChatMessage = event => {
    this.setState({
      chatMsg: event.target.value
    });
  };

  handleChatSend = chatMessage => {
    if (chatMessage !== "") {
      this.props.handleChatSend(this.state.chatMsg);
      this.setState({ chatMsg: "" });
    }

    //Redmine#6248: Focus chatMsgBox after sending message
    this.chatMsgBoxRef.current.focus();
  };

  handleChatMsgBoxKeyPress = event => {
    if (event.shiftKey && event.key === "Enter") {
      logger.debug("New line to be inserted");
    } else if (event.key === "Enter") {
      this.handleChatSend(this.state.chatMsg);
      event.preventDefault();
    }
  };

  render() {
    const { classes, intl } = this.props;
    const { chatMsg } = this.state;

    return (
      <div className={classes.chatFooter}>
        <InputBase
          id="chatMsgBox"
          inputRef={this.chatMsgBoxRef}
          autoFocus
          className={classNames(classes.msgBox, classes.msgBoxPadding)}
          fullWidth={true}
          multiline={true}
          maxRows={2}
          value={chatMsg}
          onChange={this.setChatMessage}
          onKeyPress={this.handleChatMsgBoxKeyPress}
        />
        <IconButton
          className={classes.closeHeaderButton}
          disabled={chatMsg === ""}
          onClick={() => this.handleChatSend(chatMsg)}
          title={intl.formatMessage(getIntl("clickToSendChat"))}
        >
          <SvgIcon
            iconName="chatSend"
            color="active"
            className={classes.sendIcon}
          />
        </IconButton>
      </div>
    );
  }
}

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(null, null, null, { forwardRef: true })(ChatFooter))
  )
);
