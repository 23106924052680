import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { withRouter } from "react-router-dom";
import { logoutPortalUser } from "../../actions";
import { IconButton } from "@material-ui/core";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import { withTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl } from "../../utils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  layout: {
    backgroundColor: theme.colors.popoverBackgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "300px",
    color: theme.colors.primaryTextColor,
    borderRadius: "10px",
    border: `1px solid ${theme.colors.primaryMainColor}`
  },
  closeHeader: {
    width: "100%",
    textAlign: "right"
  },
  header: {
    width: "100%",
    textAlign: "left",
    padding: "0px 0px 0px 37px"
  },
  content: {
    width: "100%",
    padding: "10px 14px 10px 14px",
    display: "flex",
    alignContent: "space-around",
    justifyContent: "center",
    flexWrap: "wrap",
    color: theme.colors.primaryTextColor
  },
  closeHeaderIcon: {
    width: ".7em"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },
  logoutButton: {
    fontSize: "1.5em",
    width: "64px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px"
  }
});

class PortalLogoutModal extends Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    const { session, history } = this.props;
    this.props.logoutPortalUser(history, session.portalSessionId);
  };

  render() {
    const { classes, disabled, intl } = this.props;

    return (
      <Fragment>
        <IconButton
          className={classNames("logoutModalButton", classes.logoutButton)}
          disabled={disabled}
          onClick={this.handleOpen}
          title={intl.formatMessage(getIntl("logout"))}
        >
          <SvgIcon iconName="logout" color="primaryMainText" />
        </IconButton>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className={classes.container}
          BackdropProps={{
            invisible: true
          }}
        >
          <div>
            <div className={classes.layout}>
              <div className={classes.closeHeader}>
                <IconButton
                  className={classes.closeHeaderButton}
                  disabled={false}
                  onClick={() => this.handleClose()}
                >
                  <SvgIcon
                    iconName="close"
                    className={classes.closeHeaderIcon}
                  />
                </IconButton>
              </div>
              <Fragment>
                <LocalizedText
                  value="logout"
                  variant="h6"
                  className={classes.header}
                />
                <div className={classes.content}>
                  <TextButton
                    className={"logoutConfirmButton"}
                    onClick={() => this.handleLogout()}
                    color="error"
                  >
                    <LocalizedText
                      value="yes"
                      className={classes.errorTextColor}
                    />
                  </TextButton>
                  <TextButton
                    className={"logoutCancelButton"}
                    onClick={() => this.handleClose()}
                    color="secondary"
                  >
                    <LocalizedText
                      value="no"
                      className={classes.primaryTextColor}
                    />
                  </TextButton>
                </div>
              </Fragment>
            </div>
            <div
              className="modalContainer"
              ref={r => (this.modalContainerRef = r)}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  logoutPortalUser: (history, username) =>
    dispatch(logoutPortalUser(username)).then(() => {
      history.replace("portal");
    })
});

export default withTheme(
  withStyles(styles)(
    withRouter(
      injectIntl(
        connect(mapStateToProps, mapDispatchToProps)(PortalLogoutModal)
      )
    )
  )
);
