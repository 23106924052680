import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { hideInternalDrawer } from "../../actions";
import { isMobileOrTablet, getIntl } from "../../utils";
import SvgIcon from "../Icons/SvgIcon";
import { useIntl } from "react-intl";

const headerHeight = "32px";

const useDrawerHeaderStyles = makeStyles(
  {
    drawerHeader: {
      textAlign: "right",
      height: headerHeight,
      padding: "3px 3px 3px 0px"
    },
    closeHeaderButton: {
      fontSize: "1.2em",
      padding: "8px"
    },
    closeHeaderIcon: {
      width: "0.7em"
    }
  },
  { name: "DrawerHeader" }
);

function DrawerHeader({ popout, onPopoutClick }) {
  const classes = useDrawerHeaderStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  function handlePopoutClick() {
    dispatch(hideInternalDrawer());
    onPopoutClick();
  }

  return (
    <div className={classes.drawerHeader}>
      {popout && (
        <IconButton
          className={classes.closeHeaderButton}
          onClick={handlePopoutClick}
          title={intl.formatMessage(getIntl("popoutIntoNewWindow"))}
        >
          <SvgIcon iconName="popout" className={classes.closeHeaderIcon} />
        </IconButton>
      )}
      <IconButton
        className={classes.closeHeaderButton}
        onClick={() => dispatch(hideInternalDrawer())}
        title={intl.formatMessage(getIntl("close"))}
      >
        <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
      </IconButton>
    </div>
  );
}

const useCTXDrawerStyles = makeStyles(
  {
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column"
    }
  },
  { name: "CTXDrawer" }
);

export default function CTXDrawer({ popout, onPopoutClick, children }) {
  const classes = useCTXDrawerStyles();

  return (
    <div className={classes.root}>
      {!isMobileOrTablet() && (
        <DrawerHeader popout={popout} onPopoutClick={onPopoutClick} />
      )}
      {children}
    </div>
  );
}
