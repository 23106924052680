import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import { setQAWindowStatus } from "./actions";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import WebQAView from "./WebQAView";
import { showQAWindowPopout } from "./PopoutWindow/actions";
import {
  QAWindowPopoutState,
  qaPopoutWindowName
} from "./PopoutWindow/component";
import QuestionCard from "./QuestionCard";
import QuestionCardOptionMenu from "./QuestionCardOptionMenu";
import { MenuItem, MenuList, Backdrop, TextField } from "@material-ui/core";
import { isSafari } from "react-device-detect";

const styles = theme => ({
  root: {
    height: "460px"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  widthExpand: {
    width: "800px"
  },
  widthCollapse: {
    width: "540px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  headerIcon: {
    width: "2em"
  },
  headerText: {
    marginLeft: "10px"
  },
  fullHeight: {
    height: "100%"
  },
  fullWidth: {
    width: "100%"
  }
});

class GuestQAWindow extends Component {
  //Window header controls
  getWindowHeaderControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.headerIconContainer}>
          <SvgIcon iconName="qaGeneral" className={classes.headerIcon} />

          <LocalizedText
            value="questionQueue"
            variant="h6"
            className={classes.headerText}
          />
        </div>
      </div>
    );
  };

  //functions
  overrideHandleClose = () => {
    this.props.setQAWindowStatus(false);
  };

  handlePopoutClick = () => {
    this.props.showQAWindowPopout();
    this.props.setQAWindowStatus(false);
  };

  // Force Material-UI to load styles needed in new window
  getClassesForPopout = () => {
    return (
      <div style={{ display: "none" }}>
        <WebQAView />
        <QuestionCard questionId={-1} />
        <QuestionCardOptionMenu open={false} />
        <MenuList />
        <MenuItem />
        <Backdrop open={false} />
        <TextField />
      </div>
    );
  };

  render() {
    const { classes, qa, qaPopoutWindow } = this.props;
    const { expand } = qa;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    const component = (
      <div
        className={classNames(
          classes.displayFlexColumn,
          qaPopoutVisible ? classes.fullHeight : classes.root,
          qaPopoutVisible
            ? classes.fullWidth
            : expand
            ? classes.widthExpand
            : classes.widthCollapse
        )}
      >
        {this.getWindowHeaderControl()}
        <WebQAView />
        {this.getClassesForPopout()}
      </div>
    );

    return (
      <CTXBaseWindow
        component={component}
        overrideHandleClose={this.overrideHandleClose}
        disableClickAwayClose
        hidePopoutButton={isSafari || qaPopoutVisible}
        hideCloseButton={qaPopoutVisible}
        fullWidth={qaPopoutVisible}
        fullHeight={qaPopoutVisible}
        handlePopoutClick={this.handlePopoutClick}
      />
    );
  }
}

const mapStateToProps = ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
}) => ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAWindowStatus: isVisible => dispatch(setQAWindowStatus(isVisible)),
  showQAWindowPopout: () => dispatch(showQAWindowPopout(qaPopoutWindowName))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(GuestQAWindow))
  )
);
