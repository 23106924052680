import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    color: theme.colors.disconnectIconColor,
    margin: "10px 0px 10px 0px"
  }
});

class ConferenceRecordingIndicator extends Component {
  getStatus = () => {
    const { session } = this.props;

    return (
      session.recordingActive && (
        <LocalizedText value="rec" variant="subtitle2" />
      )
    );
  };

  render() {
    const { classes } = this.props;
    return <div className={classes.root}>{this.getStatus()}</div>;
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps)(ConferenceRecordingIndicator))
);
