import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { hideQAWindowPopout, setQAWindowResizeInProgress } from "./actions";
import HostQAWindow from "../HostQAWindow";
import GuestQAWindow from "../GuestQAWindow";
import CtxNewWindow from "../../NewWindow/CtxNewWindow";
import { isHost } from "../../../utils";

/***
 * If QAWindowPopoutState.OPEN  ---> PopoutWindow is shown
 * If QAWindowPopoutState.CLOSE ----> PopoutWindow is closed
 *
 * Stateful component that sets QAWindowPopoutState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set QAWindowPopoutState.OPEN in the store.
 * This component reads from the redux store to decide if the popout window should be shown. *
 */

// QAWindowPopoutState is exported for the reducer to set OPEN and CLOSE states.
export const QAWindowPopoutState = {
  CLOSE: 1,
  OPEN: 2
};

export const qaPopoutWindowName = "QuestionAnswerWindow";

const defaultWindowWidth = 855;
const defaultWindowHeight = 585;
const minWindowWidth = 855;
const minWindowHeight = 585;
const minWidthCheckTimeout = 150;

let resizeTimer;
let resizeChildrenTimer;

class QAPopoutWindowComponent extends Component {
  state = {
    containerHeight: defaultWindowHeight,
    containerWidth: defaultWindowWidth,
    popoutRef: undefined
  };

  ensureMinSize = (width, height, ref) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (width < minWindowWidth || height < minWindowHeight) {
        ref.window.resizeTo(
          Math.max(minWindowWidth, width),
          Math.max(minWindowHeight, height)
        );
      }
    }, minWidthCheckTimeout);
  };

  resizeChildrenComponents = () => {
    clearTimeout(resizeChildrenTimer);
    resizeChildrenTimer = setTimeout(() => {
      this.props.setQAWindowResizeInProgress();
    }, minWidthCheckTimeout);
  };

  handleResize = () => {
    if (this.state.popoutRef) {
      this.ensureMinSize(
        this.state.popoutRef.window.outerWidth,
        this.state.popoutRef.window.outerHeight,
        this.state.popoutRef
      );
    }
    this.setState({
      ...this.state,
      containerHeight: this.selfRef.offsetHeight,
      containerWidth: this.selfRef.offsetWidth
    });
  };

  handleUnload = () => {
    this.props.hideQAWindowPopout();
    this.setState({
      ...this.state,
      containerHeight: defaultWindowHeight,
      containerWidth: defaultWindowWidth,
      popoutRef: undefined
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.popoutRef && this.popoutRef) {
      this.setState({ ...this.state, popoutRef: this.popoutRef });
    } else if (
      prevState.popoutRef &&
      this.state.popoutRef &&
      (prevState.popoutRef.window.outerWidth !==
        this.state.popoutRef.window.outerWidth ||
        prevState.popoutRef.window.outerHeight !==
          this.state.popoutRef.window.outerHeight)
    ) {
      this.setState({
        ...this.state,
        containerHeight: this.selfRef.offsetHeight,
        containerWidth: this.selfRef.offsetWidth
      });
    }
  }

  render() {
    const { session } = this.props;
    const host = isHost(session);

    if (this.props.qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN) {
      return (
        <CtxNewWindow
          ref={r => (this.popoutRef = r)}
          onUnload={this.handleUnload}
          resizeCallback={this.handleResize}
          features={{
            width: defaultWindowWidth,
            height: defaultWindowHeight,
            location: 0
          }}
          title="ConnectNow-QA"
          name={qaPopoutWindowName}
          backgroundColor={this.props.theme.popoverBackgroundColor}
        >
          <div
            className="QAWindow"
            ref={r => (this.selfRef = r)}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex"
            }}
          >
            {host ? (
              <HostQAWindow
                containerHeight={this.state.containerHeight}
                containerWidth={this.state.containerWidth}
              />
            ) : (
              <GuestQAWindow
                containerHeight={this.state.containerHeight}
                containerWidth={this.state.containerWidth}
              />
            )}
          </div>
        </CtxNewWindow>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ qaPopoutWindow, theme, session }) => ({
  qaPopoutWindow,
  theme,
  session
});

const mapDispatchToProps = dispatch => ({
  hideQAWindowPopout: () => dispatch(hideQAWindowPopout()),
  setQAWindowResizeInProgress: () => dispatch(setQAWindowResizeInProgress())
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(QAPopoutWindowComponent)
);
