import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { SharePopper, QAPopper, ConnectPopperDummy } from "../poppers";
import SvgIcon from "../Icons/SvgIcon";

const styles = theme => ({
  root: {
    gridColumn: "3",
    gridRow: "2",
    msGridColumn: "3",
    msGridRow: "2",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    display: "flex",
    flexDirection: "column",
    zIndex: 1
  },
  conferenceIndicatorContainer: {
    display: "block",
    height: "64px"
  },
  popperButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center"
  },
  svg: {
    width: "100%",
    padding: "12px"
  }
});

class RightBarDummy extends Component {
  render() {
    const { classes, displayContent, colors, rightBarWithButtons } = this.props;
    return (
      <div className={classes.root}>
        {displayContent && (
          <Fragment>
            <div className={classes.popperButtonsContainer}>
              <div>
                <SvgIcon
                  className={classes.svg}
                  iconName="videoMute"
                  color={"inactive"}
                />
                <SvgIcon
                  className={classes.svg}
                  iconName="audioMute"
                  color={"inactive"}
                />
                {rightBarWithButtons ? (
                  <ConnectPopperDummy colors={colors} />
                ) : (
                  <SvgIcon
                    className={classes.svg}
                    iconName="connectCall"
                    color={"connect"}
                  />
                )}

                <SharePopper disabled={true} />
                <QAPopper />
              </div>
            </div>
            <div className={classes.conferenceIndicatorContainer}></div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(RightBarDummy);
