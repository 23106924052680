import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { updateTheme, setPersistentSpaceAndConfigs } from "../../actions";
import contexWebRest from "../../api/contexWebRest";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LoginForm } from "../forms";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";
import { getUrlParam, redirectUnsupportedBrowsers } from "../../utils";

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  }
});

class PersistentSpacePage extends Component {
  constructor(props) {
    super(props);

    redirectUnsupportedBrowsers();
  }

  state = {
    guestCode: null
  };

  getPersistentSpace = spaceName => {
    const uri = `/persistentspaces/${spaceName}`;
    contexWebRest
      .get(uri)
      .then(response => this.handleResponse(response, spaceName))
      .catch(response => this.handleResponse(response, spaceName));
  };

  handleResponse = (response, name) => {
    const { history } = this.props;
    if (response && response.data) {
      if (response.data.persistentSpaceTheme) {
        window.CtxThemeConfigurations = response.data.persistentSpaceTheme;
        this.updateTheme();
      }
      this.props.setPersistentSpaceAndConfigs(
        name,
        response.data.webRTCCallConfig,
        response.data.videoConfig,
        response.data.pinRequired
      );
      this.setState({
        guestCode: response.data.guestPasscode
      });
    } else {
      history.replace(process.env.PUBLIC_URL + "/login");
    }
  };

  updateTheme = async () => {
    await this.props.updateTheme();
  };

  adjustUrl = userId => {
    const { history, session } = this.props;

    if (
      session.persistentSpaceName != null &&
      session.persistentSpaceName.length !== 0 &&
      userId === undefined
    ) {
      history.replace("/" + session.persistentSpaceName);
    } else if (!session.vetted) {
      history.push("/waiting?vi=" + userId);
    } else {
      history.push("/main?vi=" + userId);
    }
  };

  getLoginForm = guestCode =>
    (window.CtxAppConfigurations.customer === "TCAD" || guestCode) && (
      <LoginForm
        onSuccess={userId => this.adjustUrl(userId)}
        passcode={guestCode}
        username={getUrlParam("username")}
        userDefined={getUrlParam("udf")}
        userDefined2={getUrlParam("udf2")}
        userDefined3={getUrlParam("udf3")}
        userDefined4={getUrlParam("udf4")}
      />
    );

  componentDidMount() {
    let spaceName = this.props.location.pathname.substr(1);
    if (spaceName.substr(-1) === "/") {
      spaceName = spaceName.substr(0, spaceName.length - 1);
    }
    if (spaceName.indexOf("/") !== -1) {
      this.props.history.replace(process.env.PUBLIC_URL + "/login");
    } else {
      this.getPersistentSpace(spaceName);
    }
  }

  render() {
    const { classes } = this.props;
    const { guestCode } = this.state;
    const component = this.getLoginForm(guestCode);

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar />
          <MainContent component={component} />
          <RightBar />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  updateTheme: () => dispatch(updateTheme()),
  setPersistentSpaceAndConfigs: (
    name,
    webRTCCallConfig,
    videoConfig,
    pinRequired
  ) =>
    dispatch(
      setPersistentSpaceAndConfigs(
        name,
        webRTCCallConfig,
        videoConfig,
        pinRequired
      )
    )
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PersistentSpacePage))
);
