import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import CTXTable from "../reusable/CTXTable";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  getExternalConferencesList,
  setSelectedExternalConference,
  clearExternalConferenceList
} from "./actions";
import { getIntl } from "../../utils";

const styles = theme => ({
  flexGrow: {
    flexGrow: 1
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  }
});

class ExternalConferenceTable extends Component {
  componentDidMount() {
    const { session } = this.props;
    const { portalSessionId } = session;

    this.props.getExternalConferencesList(portalSessionId);
  }

  componentWillUnmount() {
    this.props.setSelectedExternalConference(undefined);
    this.props.clearExternalConferenceList();
  }
  //controls
  getTableData = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { externalConferences } = portalUserManagement;

    var getTableDataResult = [];

    if (externalConferences != null && externalConferences.length > 0) {
      externalConferences.forEach(conference => {
        let tableRowData = {};
        //Key
        tableRowData.conferenceID = conference.conferenceID;
        //conferenceName
        tableRowData.conferenceName = conference.conferenceName;
        //billingCode
        tableRowData.billingCode = conference.billingCode;
        //hostPasscode
        tableRowData.hostPasscode = conference.hostPasscode;
        //guestPasscode
        tableRowData.guestPasscode = conference.guestPasscode;

        getTableDataResult.push(tableRowData);
      });
    }

    return getTableDataResult;
  };

  //function
  handleOnRowClick = (event, rowData) => {
    const selectedID = rowData.conferenceID;

    this.props.setSelectedExternalConference(selectedID);
  };

  render() {
    const { intl, languages } = this.props;
    const { translations } = languages;
    const tableColumns = [
      { title: "Key", field: "conferenceID", hidden: true },
      {
        title: translations.conferenceName,
        field: "conferenceName",
        defaultSort: "asc"
      },
      {
        title: translations.billingCode,
        field: "billingCode"
      },
      { title: translations.hostPasscode, field: "hostPasscode" },
      { title: translations.guestPasscode, field: "guestPasscode" }
    ];
    const tableData = this.getTableData();

    return (
      <CTXTable
        title={intl.formatMessage(getIntl("chooseConference"))}
        columns={tableColumns}
        data={tableData}
        enableSearch={true}
        enableSelectedRowStyle
        onRowClick={this.handleOnRowClick}
        flexGrowNativeToolbarContainer
      />
    );
  }
}

const mapStateToProps = ({ languages, portal, session }) => ({
  languages,
  portal,
  session
});

const mapDispatchToProps = dispatch => ({
  getExternalConferencesList: portalSessionID =>
    dispatch(getExternalConferencesList(portalSessionID)),
  setSelectedExternalConference: conferenceID =>
    dispatch(setSelectedExternalConference(conferenceID)),
  clearExternalConferenceList: () => {
    dispatch(clearExternalConferenceList());
  }
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(ExternalConferenceTable)
    )
  )
);
