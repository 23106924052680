import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import classNames from "classnames";
import { getIntl } from "../../utils";
import UserProfileCard from "./UserProfileCard";
import PortalUserTable from "./PortalUserTable";
import NewUserModal from "./NewUserModal";
import ConfirmDeleteUserModal from "./ConfirmDeleteUserModal";
import LocalizedText from "../reusable/LocalizedText";
import {
  getPortalUserPersistentSpacesByUserID,
  UserPromptLevel,
  setProfileUserPrompt,
  updatePersistentSpaceNameByUserID,
  updatePortalUserAccount,
  setUserManagementSelectedUser,
  setShowDisabledUserCheckBox,
  setUserManagementSelectedUserList,
  setUserManagementProfileSettingsTabIndex
} from "./actions";
import ThemeDetailsCard from "./ThemeDetailsCard";
import ConferenceDetailsCard from "./ConferenceDetailsCard";
import PersistentSpaceSettingsCard from "./PersistentSpaceSettingsCard";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor,
    alignSelf: "center"
  },
  mainContentsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  upperActionContainer: {
    minWidth: "905px",
    minHeight: "25px",
    paddingRight: "10px"
  },
  middlePartyListContainer: {
    minWidth: "905px"
  },
  lowerUserProfileSettingsContainer: {
    marginBottom: "10px",
    minWidth: "905px",
    paddingRight: "10px"
  },
  userPromptInfo: {
    color: theme.colors.primaryTextColor
  },
  userPromptError: {
    color: theme.colors.errorMainColor
  },
  userPromptSuccess: {
    color: theme.colors.primaryMainColor
  },
  showDisabledUsersLabel: {
    color: theme.colors.primaryTextColor
  },
  showDisabledUsersCheckbox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  tabContainer: {
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  tabContentContainer: {
    height: "500px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  flexGrow: {
    flexGrow: 1
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  }
});

class UserManagementView extends Component {
  componentWillUnmount() {
    this.props.setProfileUserPrompt(UserPromptLevel.ERROR, "");
  }

  getUpperActionControls = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.upperActionContainer,
          classes.displayFlexRow,
          classes.justifyContentSpaceBetween
        )}
      >
        <div
          className={classNames(
            classes.displayFlexRow,
            classes.alignItemsCenter
          )}
        >
          {this.getUserPromptControls()}
        </div>
        <div>{this.getShowDisabledUsersControls()}</div>
      </div>
    );
  };

  getMiddlePartyListControls = () => {
    const { classes } = this.props;

    return (
      <div className={classes.middlePartyListContainer}>
        <PortalUserTable />
      </div>
    );
  };

  getUserProfileViewControls = () => {
    const { portal, session } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUser, selectedUserPersistentSpace } = portalUserManagement;

    return (
      <UserProfileCard
        firstName={selectedUser.firstName}
        lastName={selectedUser.lastName}
        username={selectedUser.username}
        email={selectedUser.email}
        persistentSpaceName={selectedUserPersistentSpace.persistentSpaceName}
        userID={selectedUser.userID}
        backgroundColor={this.props.theme.colors.popoverBackgroundColor}
        portalSessionId={session.portalSessionId}
        updatePersistentSpaceNameByUserID={
          this.props.updatePersistentSpaceNameByUserID
        }
        updateUserProfileByUserID={this.props.updatePortalUserAccount}
        setFeedbackPrompt={this.props.setProfileUserPrompt}
        setUserManagementSelectedUser={this.props.setUserManagementSelectedUser}
      />
    );
  };

  getLowerControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUser, showDisabledUser } = portalUserManagement;

    return (
      selectedUser &&
      !showDisabledUser && (
        <div className={classes.lowerUserProfileSettingsContainer}>
          {this.getTabControls()}
          <div className={classes.tabContentContainer}>
            {this.getTabContentControls()}
          </div>
        </div>
      )
    );
  };

  getUserPromptControls = () => {
    const { classes, portal } = this.props;
    const { profilePromptLevel, profilePromptContents } = portal;

    return (
      <Typography
        className={classNames(
          profilePromptLevel === UserPromptLevel.INFO
            ? classes.userPromptInfo
            : undefined,
          profilePromptLevel === UserPromptLevel.ERROR
            ? classes.userPromptError
            : undefined,
          profilePromptLevel === UserPromptLevel.SUCCESS
            ? classes.userPromptSuccess
            : undefined,
          classes.userPrompt,
          classes.alignSelfFlexStart
        )}
        variant="body1"
      >
        {profilePromptContents
          ? this.props.intl.formatMessage(getIntl(profilePromptContents))
          : ""}
      </Typography>
    );
  };

  getNewUserModalControls = () => {
    return (
      <NewUserModal
        open={this.props.portal.portalUserManagement.newUserModalStatus}
      />
    );
  };

  getConfirmDeleteUserModalControls = () => {
    const { portal, intl } = this.props;
    const { portalUserManagement } = portal;
    const { confirmDeleteUserModalStatus, selectedUserMap } =
      portalUserManagement;
    const displayMainText =
      selectedUserMap != null && selectedUserMap.size > 0
        ? intl.formatMessage(getIntl("confirmToDisable")) +
          " " +
          selectedUserMap.size +
          " " +
          intl.formatMessage(getIntl("selectedUsers"))
        : intl.formatMessage(getIntl("noUserChecked"));

    return (
      <ConfirmDeleteUserModal
        open={confirmDeleteUserModalStatus}
        mainText={displayMainText}
        actionOK={selectedUserMap == null || selectedUserMap.size === 0}
      />
    );
  };

  getShowDisabledUsersControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { showDisabledUser } = portalUserManagement;

    return (
      <FormControlLabel
        classes={{
          label: classes.showDisabledUsersLabel
        }}
        control={
          <Checkbox
            className={classes.showDisabledUsersCheckbox}
            checked={showDisabledUser}
            onChange={eventObj =>
              this.handleDisabledUsersCheckBoxChange(eventObj)
            }
            color="primary"
            icon={
              <CheckBoxOutlineBlankIcon
                viewBox="3 3 18 18"
                className={classes.checkboxOutlineBlankIcon}
              />
            }
            checkedIcon={
              <CheckBoxIcon
                viewBox="3 3 18 18"
                className={classes.checkboxIcon}
              />
            }
          />
        }
        label={<LocalizedText value="showDisabled" variant="body1" />}
      />
    );
  };

  getTabControls = () => {
    const { portal, classes } = this.props;
    const { portalUserManagement } = portal;
    const { userProfileSettingsTabIndex } = portalUserManagement;

    return (
      <div className={classes.tabContainer}>
        <Tabs
          value={userProfileSettingsTabIndex}
          onChange={this.handleTabOnChange}
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab label={<LocalizedText value="profile" variant="caption" />} />
          <Tab label={<LocalizedText value="conference" variant="caption" />} />
          <Tab label={<LocalizedText value="theme" variant="caption" />} />
          <Tab label={<LocalizedText value="settings" variant="caption" />} />
        </Tabs>
      </div>
    );
  };

  getTabContentControls = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { userProfileSettingsTabIndex } = portalUserManagement;
    let res = undefined;

    if (userProfileSettingsTabIndex === 0) {
      res = this.getUserProfileViewControls();
    } else if (userProfileSettingsTabIndex === 1) {
      res = this.getUserConferenceDetailsControls();
    } else if (userProfileSettingsTabIndex === 2) {
      res = this.getUserThemeViewControls();
    } else if (userProfileSettingsTabIndex === 3) {
      res = this.getUserPersistentSpaceSettingControls();
    }

    return res;
  };

  getUserConferenceDetailsControls = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUserAudioConference } = portalUserManagement;

    if (selectedUserAudioConference != null) {
      return (
        <ConferenceDetailsCard
          backgroundColor={this.props.theme.colors.popoverBackgroundColor}
          conferenceName={
            selectedUserAudioConference
              ? selectedUserAudioConference.ConferenceName
              : undefined
          }
          billingCode={
            selectedUserAudioConference
              ? selectedUserAudioConference.BillingCode
              : undefined
          }
          hostPasscode={
            selectedUserAudioConference
              ? selectedUserAudioConference.HostPasscode
              : undefined
          }
          guestPasscode={
            selectedUserAudioConference
              ? selectedUserAudioConference.GuestPasscode
              : undefined
          }
        />
      );
    }
  };

  getUserThemeViewControls = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUserPersistentSpace } = portalUserManagement;

    if (selectedUserPersistentSpace.persistentSpaceTheme != null) {
      return (
        <ThemeDetailsCard
          theme={selectedUserPersistentSpace.persistentSpaceTheme}
          backgroundColor={this.props.theme.colors.popoverBackgroundColor}
        />
      );
    }
  };

  getUserPersistentSpaceSettingControls = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUser, selectedUserPersistentSpace } = portalUserManagement;

    if (
      selectedUser != null &&
      selectedUserPersistentSpace.persistentSpaceTheme != null
    ) {
      return (
        <PersistentSpaceSettingsCard
          userID={selectedUser.userID}
          persistentSpace={selectedUserPersistentSpace}
          backgroundColor={this.props.theme.colors.popoverBackgroundColor}
        />
      );
    }
  };

  handleDisabledUsersCheckBoxChange = eventObj => {
    this.props.setShowDisabledUserCheckBox(eventObj.target.checked);
    this.props.setUserManagementSelectedUser(undefined);
    this.props.setUserManagementSelectedUserList([]);
  };

  handleTabOnChange = (event, tabIndex) => {
    this.props.setUserManagementProfileSettingsTabIndex(tabIndex);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.mainContentsContainer}>
          {this.getUpperActionControls()}
          {this.getMiddlePartyListControls()}
          {this.getLowerControls()}
          {this.getNewUserModalControls()}
          {this.getConfirmDeleteUserModalControls()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  getPortalUserPersistentSpacesByUserID: (sessionID, userID) =>
    dispatch(getPortalUserPersistentSpacesByUserID(sessionID, userID)),
  setProfileUserPrompt: (promptLevel, promptContents) =>
    dispatch(setProfileUserPrompt(promptLevel, promptContents)),
  updatePersistentSpaceNameByUserID: (sessionId, userId, persistentSpaceName) =>
    dispatch(
      updatePersistentSpaceNameByUserID(sessionId, userId, persistentSpaceName)
    ),
  updatePortalUserAccount: (sessionId, portalUserObj, userId, selfUserID) =>
    dispatch(
      updatePortalUserAccount(sessionId, portalUserObj, userId, selfUserID)
    ),
  setUserManagementSelectedUser: selectedUser =>
    dispatch(setUserManagementSelectedUser(selectedUser)),
  setShowDisabledUserCheckBox: isEnabled =>
    dispatch(setShowDisabledUserCheckBox(isEnabled)),
  setUserManagementSelectedUserList: selectedUserList =>
    dispatch(setUserManagementSelectedUserList(selectedUserList)),
  setUserManagementProfileSettingsTabIndex: selectedIndex =>
    dispatch(setUserManagementProfileSettingsTabIndex(selectedIndex))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserManagementView))
  )
);
