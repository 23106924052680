import React, { Component } from "react";
import { ParticipantList } from "./";
import { makeResponsive } from "../reusable/ResponsiveContainer";

class ParticipantListResponsive extends Component {
  render() {
    return <ParticipantList {...this.props} />;
  }
}

export default makeResponsive(ParticipantListResponsive);
