import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import CTXTable from "../reusable/CTXTable";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { resetPortalBillingUsers } from "../../actions";
import { getIntl } from "../../utils";

const styles = theme => ({});

const UserType = {
  HOST: 1,
  PARTICIPANT: 2
};

class PortalReportUserTable extends Component {
  componentWillUnmount() {
    this.props.resetPortalBillingUsers();
  }

  convertDuration = seconds => {
    //output hh:mm:ss
    let resHH = Math.floor(seconds / 60 / 60);
    let resMM = Math.floor((seconds - 3600 * resHH) / 60);
    let resSS = (seconds - 3600 * resHH - 60 * resMM).toFixed(0);

    return (
      resHH.toString().padStart(2, "0") +
      ":" +
      resMM.toString().padStart(2, "0") +
      ":" +
      resSS.toString().padStart(2, "0")
    );
  };

  getTableData = reportUsers => {
    const { intl } = this.props;

    var getTableDataResult = [];

    if (reportUsers == null) {
      return getTableDataResult;
    }

    for (var key in reportUsers) {
      let tableRowData = {};

      //undefined logOutTime means user is still active
      if (reportUsers[key].logOutTime == null) {
        continue;
      }

      //Key
      tableRowData.userKey = reportUsers[key].userKey;
      //name
      tableRowData.name = reportUsers[key].name;
      //User Type
      tableRowData.userType =
        reportUsers[key].userLevel === UserType.HOST
          ? intl.formatMessage(getIntl("host"))
          : intl.formatMessage(getIntl("participant"));
      //Email
      tableRowData.email = reportUsers[key].userDefined3;
      //Duration
      let loginDateTime = new Date(
        reportUsers[key].logInTime.replace(" ", "T")
      );
      let logoutDateTime = new Date(
        reportUsers[key].logOutTime.replace(" ", "T")
      );
      let durationDiff =
        (logoutDateTime.getTime() - loginDateTime.getTime()) / 1000;
      tableRowData.duration = this.convertDuration(durationDiff);
      //Audio
      tableRowData.audio = reportUsers[key].audioUsed;

      getTableDataResult.push(tableRowData);
    }

    return getTableDataResult;
  };

  render() {
    const { translations } = this.props.languages;
    const tableTitle = translations.participantActivity;
    const tableColumns = [
      { title: "Key", field: "userKey", hidden: true },
      { title: translations.userType, field: "userType" },
      { title: translations.name, field: "name", defaultSort: "asc" },
      { title: translations.duration, field: "duration" },
      { title: translations.email, field: "email" },
      {
        title: translations.audio,
        field: "audio",
        type: "boolean",
        width: "50px"
      }
    ];

    let tableData = this.getTableData(this.props.portal.reportUsers);

    return (
      <CTXTable
        title={tableTitle}
        columns={tableColumns}
        data={tableData}
        maxBodyHeight={this.props.maxBodyHeight}
      />
    );
  }
}

const mapStateToProps = ({ portal, session, languages }) => ({
  portal,
  session,
  languages
});

const mapDispatchToProps = dispatch => ({
  resetPortalBillingUsers: () => dispatch(resetPortalBillingUsers())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(PortalReportUserTable)
    )
  )
);
