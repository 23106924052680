import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine } from "../../utils";
import { IconButton, Badge } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { getTextColor } from "../../style";
import { withTheme } from "@material-ui/core/styles";
import { setQAWindowStatus } from "../QA/actions";
import { QAWindowPopoutState } from "../QA";

const styles = theme => ({
  item: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  iconButton: {
    width: "64px"
  },
  badgeRoot: {
    width: "100%",
    display: "inline",
    position: "relative",
    verticalAlign: "middle"
  },
  badgeColor: {
    backgroundColor: theme.colors.disconnectIconColor
  }
});

class QAIcon extends Component {
  handleQAButtonOnClick = () => {
    const { qaPopoutWindow } = this.props;
    const { windowStatus } = qaPopoutWindow;

    if (qaPopoutWindow.windowName) {
      window.open("", qaPopoutWindow.windowName);
    } else {
      if (windowStatus === QAWindowPopoutState.CLOSE) {
        this.props.setQAWindowStatus(true);
      }
    }

    this.handleClickAway();
  };

  handleClickAway = () => {
    this.props.onClick();
  };

  getConferenceQAControl = () => {
    const { classes, intl, disabled, theme, qa } = this.props;
    const { unreadDataQAEntries } = qa;

    const qaIconWithBadge =
      unreadDataQAEntries != null && unreadDataQAEntries.size > 0 ? (
        <Badge
          color="primary"
          max={99}
          badgeContent={unreadDataQAEntries.size}
          classes={{
            root: classes.badgeRoot,
            colorPrimary: classes.badgeColor
          }}
        >
          <SvgIcon iconName="qa" color={disabled ? "inactive" : "active"} />
        </Badge>
      ) : (
        <SvgIcon iconName="qa" color={disabled ? "inactive" : "active"} />
      );

    let controlElement = (
      <div className={classes.item}>
        <Fragment>
          <IconButton
            className={classes.iconButton}
            onClick={this.handleQAButtonOnClick}
            disabled={disabled}
            title={
              intl.formatMessage(getIntl("questionAndAnswer")) +
              TitleNewLine +
              (disabled
                ? intl.formatMessage(getIntl("controlDisabled")) +
                  TitleNewLine +
                  intl.formatMessage(getIntl("connectYourAudioToEnable"))
                : intl.formatMessage(getIntl("clickToOpenQA")))
            }
          >
            {qaIconWithBadge}
          </IconButton>
          <div>
            <LocalizedText
              value="qa"
              variant="subtitle2"
              style={{ color: getTextColor(theme, disabled) }}
            />
          </div>
        </Fragment>
      </div>
    );

    return controlElement;
  };

  render() {
    return <Fragment>{this.getConferenceQAControl()}</Fragment>;
  }
}

const mapStateToProps = ({ session, conference, qaPopoutWindow, qa }) => ({
  session,
  conference,
  qaPopoutWindow,
  qa
});

const mapDispatchToProps = dispatch => ({
  setQAWindowStatus: isVisible => dispatch(setQAWindowStatus(isVisible))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(QAIcon))
  )
);
