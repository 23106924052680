import React, { Component } from "react";
import { connect } from "react-redux";

class AppLogo extends Component {
  render() {
    const { classes, logoType } = this.props;
    let rootStyle;
    if (classes != null) {
      rootStyle = classes.root;
    }

    var logoName;
    if (logoType === "header") {
      logoName = window.CtxThemeConfigurations.headerLogo;
    } else if (logoType === "body") {
      logoName = window.CtxThemeConfigurations.bodyLogo;
    }

    return (
      <img
        src={
          process.env.PUBLIC_URL +
          "/" +
          this.props.theme.imagesLocation +
          "/" +
          logoName
        }
        className={rootStyle}
        alt="ConnectNow"
      />
    );
  }
}

const mapStateToProps = ({ theme }) => ({ theme });

export default connect(mapStateToProps)(AppLogo);
