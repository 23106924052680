import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import classNames from "classnames";

const useStyles = makeStyles(
  theme => ({
    selectContainer: {
      width: "100%",
      borderRadius: "10px",
      backgroundColor: theme.colors.primaryBackgroundColor,
      color: theme.colors.primaryTextColor,
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    selectIcon: {
      top: "calc(29% - 12px)",
      right: "-5px",
      color: theme.colors.primaryTextColor,
      fontSize: "2.25em"
    },
    menuItem: {
      color: theme.colors.primaryTextColor,
      paddingRight: "20px",
      width: "100%"
    },
    menuItemGroup: {
      maxHeight: "150px",
      overflowY: "auto",
      border: `3px solid ${theme.colors.secondaryMainColor}`,
      borderRadius: "5px",
      paddingLeft: "0px",
      paddingRight: "0px",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.secondaryMainColor,
        borderRadius: "4px",
        border: "none"
      },
      "&::-webkit-scrollbar-track-piece": {
        background: theme.colors.sideBarBackgroundColor,
        borderRadius: "4px"
      },
      "&::-webkit-scrollbar-corner": {
        background: theme.colors.sideBarBackgroundColor
      },
      scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
      scrollbarWidth: "thin"
    },
    largeHeight: {
      maxHeight: "300px"
    }
  }),
  { name: "CTXSelect" }
);

export default function CTXSelect({
  open,
  value,
  renderValue,
  onClose,
  onOpen,
  onChange,
  align,
  height,
  input,
  children
}) {
  const classes = useStyles();

  return (
    <Select
      open={open}
      value={value}
      renderValue={renderValue}
      onClose={onClose}
      onOpen={onOpen}
      onChange={onChange}
      classes={{
        root: classes.selectContainer,
        icon: classes.selectIcon,
        select: classes.menuItem
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: align || "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: align || "left"
        },
        getContentAnchorEl: null,
        classes: {
          paper: classNames(classes.menuItemGroup, {
            [classes.largeHeight]: height === "large"
          })
        }
      }}
      input={input}
    >
      {children}
    </Select>
  );
}
