import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { Modal, IconButton, Typography } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl } from "../../utils";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import {
  setConfirmDeleteUserModalStatus,
  setProfileUserPrompt,
  UserPromptLevel,
  disableUsers,
  setUserManagementSelectedUserList,
  setUserManagementSelectedUser
} from "./actions";

const styles = theme => ({
  root: {
    outline: "none",
    backgroundColor: theme.colors.popoverBackgroundColor,
    borderRadius: "10px",
    border: `1px solid ${theme.colors.primaryMainColor}`
  },
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px",
    float: "right"
  },
  closeHeaderIcon: {
    width: ".7em"
  },
  mainTextContainer: {
    color: theme.colors.primaryTextColor,
    margin: "10px 20px 20px 20px"
  },
  actionContainer: {
    margin: "0px 20px 0px 20px"
  },
  actionButton: {
    width: "140px"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  }
});

class ConfirmDeleteUserModal extends Component {
  //controls
  getHeaderActionsControls = () => {
    const { classes, intl } = this.props;

    const closeButton = (
      <IconButton
        className={classes.closeHeaderButton}
        onClick={this.handleCloseButtonOnClick}
        title={intl.formatMessage(getIntl("close"))}
      >
        <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
      </IconButton>
    );

    return <div>{closeButton}</div>;
  };

  getMainTextControls = () => {
    const { classes } = this.props;

    return (
      <div className={classes.mainTextContainer}>
        <Typography variant="subtitle1" color="inherit">
          {this.props.mainText}
        </Typography>
      </div>
    );
  };

  getActionControls = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexColumn,
          classes.actionContainer,
          classes.alignItemsCenter
        )}
      >
        {this.props.actionOK ? (
          <TextButton
            className={classes.actionButton}
            onClick={this.handleCloseButtonOnClick}
            color="error"
          >
            <LocalizedText value="ok" />
          </TextButton>
        ) : (
          <Fragment>
            <TextButton
              className={classes.actionButton}
              onClick={this.handleYesButtonOnClick}
              color="error"
            >
              <LocalizedText value="yes" className={classes.errorTextColor} />
            </TextButton>
            <TextButton
              className={classes.actionButton}
              onClick={this.handleCloseButtonOnClick}
              color="secondary"
            >
              <LocalizedText value="no" className={classes.primaryTextColor} />
            </TextButton>
          </Fragment>
        )}
      </div>
    );
  };

  //functions
  handleCloseButtonOnClick = () => {
    this.props.setConfirmDeleteUserModalStatus(false);
  };

  handleYesButtonOnClick = () => {
    const { session, portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUserMap } = portalUserManagement;
    const { portalSessionId } = session;

    this.props.setProfileUserPrompt(UserPromptLevel.INFO, "updatingPleaseWait");
    this.props.setConfirmDeleteUserModalStatus(false);

    let disabledUsersArray = [];

    selectedUserMap.forEach((value, key, map) => {
      disabledUsersArray.push(value.userID);
    });

    this.props.disableUsers(portalSessionId, disabledUsersArray);

    this.props.setUserManagementSelectedUserList([]);
    this.props.setUserManagementSelectedUser(undefined);
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal open={this.props.open} onClose={this.handleCloseButtonOnClick}>
        <div
          className={classNames(
            classes.root,
            classes.center,
            classes.displayFlexColumn
          )}
        >
          {this.getHeaderActionsControls()}
          {this.getMainTextControls()}
          {this.getActionControls()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  setConfirmDeleteUserModalStatus: isVisible =>
    dispatch(setConfirmDeleteUserModalStatus(isVisible)),
  setProfileUserPrompt: (promptLevel, promptContents) =>
    dispatch(setProfileUserPrompt(promptLevel, promptContents)),
  disableUsers: (sessionID, disabledUsersArray) =>
    dispatch(disableUsers(sessionID, disabledUsersArray)),
  setUserManagementSelectedUserList: selectedUserList =>
    dispatch(setUserManagementSelectedUserList(selectedUserList)),
  setUserManagementSelectedUser: selectedUser =>
    dispatch(setUserManagementSelectedUser(selectedUser))
});

export default withTheme(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(injectIntl(ConfirmDeleteUserModal))
  )
);
