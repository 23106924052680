import React, { Component } from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core";
import { createConnectNowStyle, createConnectNowTheme } from "../../style";

class CTXThemeComponent extends Component {
  // The popout windows lose their CSS styles when createMuiTheme is called.
  // Therefore, only update the component when the theme has actually changed.
  shouldComponentUpdate(nextProps) {
    return (
      this.props.children !== nextProps.children ||
      (this.props.theme !== nextProps.theme &&
        !this.shallowEqual(this.props.theme, nextProps.theme))
    );
  }

  shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  };

  createMuiTheme = theme => {
    let mTheme = createConnectNowTheme(theme);
    mTheme.colors = theme;
    mTheme.imagesLocation = theme.imagesLocation;
    mTheme.style = createConnectNowStyle(theme);
    return mTheme;
  };

  render() {
    const muiTheme = this.createMuiTheme(this.props.theme);
    return (
      <MuiThemeProvider theme={muiTheme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ theme }) => ({ theme });

export default connect(mapStateToProps)(CTXThemeComponent);
