//-----------Action types----------//

export const QA_WINDOW_POPOUT_SHOW = "QA_WINDOW_POPOUT_SHOW";
export const QA_WINDOW_POPOUT_HIDE = "QA_WINDOW_POPOUT_HIDE";
export const QA_WINDOW_POPOUT_RESIZE_IN_PROGRESS =
  "QA_WINDOW_POPOUT_RESIZE_IN_PROGRESS";

//---------Action creators-------------//

export const showQAWindowPopout = windowName => {
  return {
    type: QA_WINDOW_POPOUT_SHOW,
    payload: windowName
  };
};

export const hideQAWindowPopout = () => {
  return {
    type: QA_WINDOW_POPOUT_HIDE
  };
};

export const setQAWindowResizeInProgress = () => {
  return {
    type: QA_WINDOW_POPOUT_RESIZE_IN_PROGRESS
  };
};
