import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideTranscriptPopout } from "../../actions/popoutWindowsActions";
import Transcript from "./Transcript";
import CtxNewWindow from "../NewWindow/CtxNewWindow";
import { useIntl } from "react-intl";
import { getIntl } from "../../utils";

/*
 * If TranscriptPopoutState.OPEN  ----> PopoutWindow is shown
 * If TranscriptPopoutState.CLOSE ----> PopoutWindow is closed
 *
 * Stateful component that sets TranscriptPopoutState.CLOSE in the redux store.
 * When using this, it's up to the implementer to set TranscriptPopoutState.OPEN in the store.
 * This component reads from the redux store to decide if the popout window should be shown.
 */

// TranscriptPopoutState is exported for the reducer to set OPEN and CLOSE states.
export const TranscriptPopoutState = {
  CLOSE: 1,
  OPEN: 2
};

export const transcriptPopoutWindowName = "TranscriptWindow";

const defaultWindowWidth = 391;
const defaultWindowHeight = 600;
const minWindowWidth = 391;
const minWidthCheckTimeout = 150;

export default function TranscriptPopoutWindow() {
  const popoutRef = useRef(null);
  const resizeTimerRef = useRef(null);
  const [stylesCopied, setStylesCopied] = useState(false);

  const intl = useIntl();

  const windowState = useSelector(
    state => state.popoutWindows.transcriptWindowState
  );
  const popoverBackgroundColor = useSelector(
    state => state.theme.popoverBackgroundColor
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (windowState === TranscriptPopoutState.CLOSE) {
      setStylesCopied(false);
    }
  }, [windowState]);

  function ensureMinWidth() {
    const width = popoutRef.current.window.outerWidth;
    clearTimeout(resizeTimerRef.current);
    resizeTimerRef.current = setTimeout(() => {
      if (width < minWindowWidth) {
        popoutRef.current.window.resizeTo(
          minWindowWidth,
          popoutRef.current.window.outerHeight
        );
      }
    }, minWidthCheckTimeout);
  }

  return (
    windowState === TranscriptPopoutState.OPEN && (
      <CtxNewWindow
        ref={popoutRef}
        onUnload={() => dispatch(hideTranscriptPopout())}
        resizeCallback={ensureMinWidth}
        onStylesCopied={() => setStylesCopied(true)}
        features={{
          width: defaultWindowWidth,
          height: defaultWindowHeight,
          location: 0,
          scrollbars: 0
        }}
        title={intl.formatMessage(getIntl("connectNowTranscript"))}
        name={transcriptPopoutWindowName}
        backgroundColor={popoverBackgroundColor}
      >
        <Transcript stylesCopied={stylesCopied} />
      </CtxNewWindow>
    )
  );
}
