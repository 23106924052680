import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl, TitleNewLine } from "../../utils";
import { injectIntl } from "react-intl";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  layout: {
    ...theme.style.rightBarPopper.layout,
    width: "330px",
    minHeight: "120px"
  }
});

class ConnectPopperDummy extends Component {
  state = {
    anchorEl: null,
    arrowRef: null,
    open: false
  };

  closePopper = () => {
    this.setState({
      open: false
    });
  };

  handleClick = () => {
    this.setState({
      open: true
    });
  };

  handleClickAway = () => {
    this.closePopper();
  };

  render() {
    const { classes, intl, colors } = this.props;
    const open = this.state.open;
    const id = open ? "connectPopperDummy" : null;

    return (
      <Fragment>
        <IconButton
          id="connectPopperDummyButton"
          className={classes.popperIconButton}
          disabled={false}
          onClick={this.handleClick}
          title={
            intl.formatMessage(getIntl("audioConnectionControl")) +
            TitleNewLine +
            intl.formatMessage(getIntl("currentlyDisconnected")) +
            TitleNewLine +
            intl.formatMessage(getIntl("clickToConnectYourAudio"))
          }
        >
          <SvgIcon iconName="connectCall" color="connect" />
        </IconButton>

        <CTXBasePopper
          id={id}
          open={open}
          anchorEl={document.getElementById("connectPopperDummyButton")}
          className={classes.layout}
          closePopper={this.handleClickAway}
        >
          <React.Fragment>
            <LocalizedText
              className={classes.root}
              value="chooseYourAudioConnection"
              variant="h6"
              style={{ color: colors[1] }}
            />

            <TextButton style={{ backgroundColor: colors[0] }}>
              <LocalizedText value="callMe" style={{ color: colors[2] }} />
            </TextButton>

            <TextButton style={{ backgroundColor: colors[0] }}>
              <LocalizedText value="dialIn" style={{ color: colors[2] }} />
            </TextButton>

            <TextButton style={{ backgroundColor: colors[0] }}>
              <LocalizedText
                value="connectWithDevice"
                style={{ color: colors[2] }}
              />
            </TextButton>
          </React.Fragment>
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ popper }) => ({ popper });

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps)(ConnectPopperDummy))
);
