import { isCM } from "../../utils";

export function getProtooUrl({ roomId, peerId }) {
  let hostname = window.location.hostname;

  if (isCM()) {
    hostname = window.gCNConnect.GetVideoHost();
  }
  return `wss://${hostname}/media-server?roomId=${roomId}&peerId=${peerId}`;
}
