import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { Modal, IconButton, Button, Typography } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl } from "../../utils";
import LocalizedText from "../reusable/LocalizedText";
import {
  setNewUserModalStatus,
  setNewUserModalCreateProgress,
  setNewUserModalUserPrompt,
  UserPromptLevel,
  setNewUserModalUserInformation,
  isUsernamePersistentSpaceExist,
  setButtonLoader,
  isPersistentSpaceExist
} from "./actions";
import UserProfileCard from "./UserProfileCard";
import LinkAudioConference from "./LinkAudioConference";
import NewUserResult from "./NewUserResult";

const styles = theme => ({
  root: {
    width: "910px",
    height: "700px",
    outline: "none",
    backgroundColor: theme.colors.popoverBackgroundColor,
    borderRadius: "10px",
    border: `1px solid ${theme.colors.primaryMainColor}`
  },
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px",
    float: "right"
  },
  closeHeaderIcon: {
    width: ".7em"
  },
  headerNewUser: {
    margin: "0px 30px 0px 20px",
    borderBottom: `3px solid ${theme.colors.primaryTextColor}`,
    paddingBottom: "10px",
    color: theme.colors.primaryTextColor
  },
  headerUserIcon: {
    width: "2em",
    marginRight: "15px"
  },
  headerText: {
    margin: "auto",
    color: theme.colors.primaryTextColor
  },
  paginationContainer: {
    alignItems: "center",
    marginRight: "135px"
  },
  paginationButton: {
    marginRight: "10px",
    marginLeft: "10px"
  },
  paginationButtonOutlined: {
    height: "30px",
    padding: "0 1px",
    minWidth: "30px",
    borderRadius: "25px"
  },
  paginationButtonDisabled: {
    color: `${theme.colors.secondaryTextColor} !important`,
    borderColor: `${theme.colors.secondaryTextColor} !important`
  },
  paginationLocalText: {
    color: theme.colors.secondaryTextColor,
    marginTop: "5px"
  },
  userPromptContainer: {
    margin: "0px 0px 0px 25px",
    paddingTop: "5px",
    height: "35px"
  },
  mainContentContainer: {
    height: "100%",
    color: theme.colors.primaryTextColor
  },
  linkAudioConfContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 40px 0px 40px"
  },
  conferenceTypeFormLabel: {
    marginBottom: "20px"
  },
  userPromptInfo: {
    color: theme.colors.secondaryTextColor
  },
  userPromptError: {
    color: theme.colors.errorMainColor
  },
  userPromptSuccess: {
    color: theme.colors.primaryMainColor
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },

  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  flexGrow: {
    flexGrow: 1
  }
});

class NewUserModal extends Component {
  //controls
  getHeaderActionsControl = () => {
    const { classes, intl } = this.props;

    const closeButton = (
      <IconButton
        className={classes.closeHeaderButton}
        onClick={this.handleModalClose}
        title={intl.formatMessage(getIntl("close"))}
      >
        <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
      </IconButton>
    );

    return <div>{closeButton}</div>;
  };

  getHeaderNewUserControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const {
      newUserCreationProgress,
      userTableTabIndex,
      showDisabledUser,
      newUserProfileInformation
    } = portalUserManagement;

    return (
      <div
        className={classNames(classes.headerNewUser, classes.displayFlexRow)}
      >
        <SvgIcon
          iconName={userTableTabIndex === 0 ? "admin" : "user"}
          className={classes.headerUserIcon}
        />
        {showDisabledUser ? (
          <Typography variant="h6" className={classes.headerText}>
            {newUserProfileInformation != null
              ? newUserProfileInformation.lastName +
                ", " +
                newUserProfileInformation.firstName
              : ""}
          </Typography>
        ) : (
          <LocalizedText
            value={userTableTabIndex === 0 ? "newAdmin" : "newHost"}
            variant="h6"
            className={classes.headerText}
          />
        )}

        {this.getPaginationContols(newUserCreationProgress)}
      </div>
    );
  };

  getPaginationContols = selectedNumber => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { showDisabledUser } = portalUserManagement;

    return (
      <div
        className={classNames(
          classes.paginationContainer,
          classes.flexGrow,
          classes.displayFlexColumn
        )}
      >
        <div>
          <Button
            className={classNames(
              classes.paginationButton,
              selectedNumber < 1 ? classes.paginationButtonDisabled : undefined
            )}
            classes={{
              outlined: classes.paginationButtonOutlined
            }}
            variant="outlined"
            color={selectedNumber === 1 ? "primary" : "secondary"}
            disabled={selectedNumber < 1}
            onClick={() => this.props.setNewUserModalCreateProgress(1)}
          >
            1
          </Button>
          <Button
            className={classNames(
              classes.paginationButton,
              selectedNumber < 2 ? classes.paginationButtonDisabled : undefined
            )}
            classes={{
              outlined: classes.paginationButtonOutlined
            }}
            variant="outlined"
            color={selectedNumber === 2 ? "primary" : "secondary"}
            disabled={selectedNumber < 2}
            onClick={() => this.props.setNewUserModalCreateProgress(2)}
          >
            2
          </Button>
          <Button
            className={classNames(
              classes.paginationButton,
              selectedNumber < 3 ? classes.paginationButtonDisabled : undefined
            )}
            classes={{
              outlined: classes.paginationButtonOutlined
            }}
            variant="outlined"
            color={selectedNumber === 3 ? "primary" : "secondary"}
            disabled={selectedNumber < 3}
            onClick={() => this.props.setNewUserModalCreateProgress(3)}
          >
            3
          </Button>
        </div>
        <div>
          {selectedNumber === 1 &&
            (showDisabledUser ? (
              <LocalizedText
                className={classes.paginationLocalText}
                value="activateDisabledUser"
                variant="body1"
              />
            ) : (
              <LocalizedText
                className={classes.paginationLocalText}
                value="newUserInformation"
                variant="body1"
              />
            ))}
          {selectedNumber === 2 && (
            <LocalizedText
              className={classes.paginationLocalText}
              value="linkAudioConference"
              variant="body1"
            />
          )}
          {selectedNumber === 3 && (
            <LocalizedText
              className={classes.paginationLocalText}
              value="confirmation"
              variant="body1"
            />
          )}
        </div>
      </div>
    );
  };

  getUserPromptControls = () => {
    const { classes, intl, portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserModalPromptLevel, newUserModalPromptContents } =
      portalUserManagement;

    return (
      <div className={classes.userPromptContainer}>
        <Typography
          className={classNames(
            newUserModalPromptLevel === UserPromptLevel.INFO
              ? classes.userPromptInfo
              : undefined,
            newUserModalPromptLevel === UserPromptLevel.ERROR
              ? classes.userPromptError
              : undefined,
            newUserModalPromptLevel === UserPromptLevel.SUCCESS
              ? classes.userPromptSuccess
              : undefined,
            classes.userPrompt,
            classes.alignSelfFlexStart
          )}
          variant="body1"
        >
          {newUserModalPromptContents
            ? intl.formatMessage(getIntl(newUserModalPromptContents))
            : ""}
        </Typography>
      </div>
    );
  };

  getMainContentContols = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    let { newUserCreationProgress, showDisabledUser } = portalUserManagement;

    return (
      <div className={classes.mainContentContainer}>
        {newUserCreationProgress === 1 && this.getNewUserInformationControl()}
        {newUserCreationProgress === 2 && (
          <LinkAudioConference enableUserMode={showDisabledUser} />
        )}
        {newUserCreationProgress === 3 && (
          <NewUserResult enableUserMode={showDisabledUser} />
        )}
      </div>
    );
  };

  getNewUserInformationControl = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserProfileInformation, buttonLoader, showDisabledUser } =
      portalUserManagement;

    return (
      <UserProfileCard
        hideHeader
        hidePersistentSpace
        enableGeneralPersistentSpaceEntry
        hidePicture
        fullHeight
        disableClearPasswordOnSave
        saveButtonText="next"
        buttonLoader={buttonLoader}
        backgroundColor={this.props.theme.colors.popoverBackgroundColor}
        newUserNextPage={this.handleNewUserInfoNextPageButtonOnClick}
        setFeedbackPrompt={this.setNewUserModalUserPrompt}
        username={
          newUserProfileInformation ? newUserProfileInformation.username : ""
        }
        firstName={
          newUserProfileInformation ? newUserProfileInformation.firstName : ""
        }
        lastName={
          newUserProfileInformation ? newUserProfileInformation.lastName : ""
        }
        email={newUserProfileInformation ? newUserProfileInformation.email : ""}
        password={
          newUserProfileInformation ? newUserProfileInformation.password : ""
        }
        confirmPassword={
          newUserProfileInformation ? newUserProfileInformation.password : ""
        }
        persistentSpaceName={
          newUserProfileInformation
            ? newUserProfileInformation.persistentSpaceName
            : ""
        }
        enableUserMode={showDisabledUser}
        hidePassword={showDisabledUser}
      />
    );
  };

  //functions
  handleModalClose = () => {
    this.props.setNewUserModalStatus(false);
    this.props.setNewUserModalUserPrompt(UserPromptLevel.INFO, "");
    this.props.setNewUserModalUserInformation(undefined);
    this.props.setNewUserModalCreateProgress(1);
    this.props.setButtonLoader(false);
  };

  handleNewUserInfoNextPageButtonOnClick = portalUserObj => {
    const { session, portal } = this.props;
    const { portalSessionId } = session;
    const { portalUserManagement } = portal;
    const { showDisabledUser } = portalUserManagement;

    this.props.setButtonLoader(true);

    if (showDisabledUser) {
      this.props.isPersistentSpaceExist(
        portalSessionId,
        portalUserObj.persistentSpaceName
      );
    } else {
      this.props.isUsernamePersistentSpaceExist(
        portalSessionId,
        portalUserObj.username,
        portalUserObj.persistentSpaceName
      );
    }

    this.props.setNewUserModalUserInformation(portalUserObj);
  };

  setNewUserModalUserPrompt = (promptLevel, promptContents) => {
    this.props.setNewUserModalUserPrompt(promptLevel, promptContents);
  };

  render() {
    const { classes } = this.props;

    return (
      <Modal open={this.props.open}>
        <div
          className={classNames(
            classes.root,
            classes.center,
            classes.displayFlexColumn
          )}
        >
          {this.getHeaderActionsControl()}
          {this.getHeaderNewUserControls()}
          {this.getUserPromptControls()}
          {this.getMainContentContols()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  setNewUserModalStatus: isOpen => dispatch(setNewUserModalStatus(isOpen)),
  setNewUserModalCreateProgress: number =>
    dispatch(setNewUserModalCreateProgress(number)),
  setNewUserModalUserPrompt: (promptLevel, promptContents) =>
    dispatch(setNewUserModalUserPrompt(promptLevel, promptContents)),
  setNewUserModalUserInformation: userInfoObj =>
    dispatch(setNewUserModalUserInformation(userInfoObj)),
  isUsernamePersistentSpaceExist: (
    portalSessionID,
    username,
    persistentSpaceName
  ) =>
    dispatch(
      isUsernamePersistentSpaceExist(
        portalSessionID,
        username,
        persistentSpaceName
      )
    ),
  setButtonLoader: enabled => dispatch(setButtonLoader(enabled)),
  isPersistentSpaceExist: (portalSessionID, persistentSpaceName) =>
    dispatch(isPersistentSpaceExist(portalSessionID, persistentSpaceName))
});

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewUserModal))
  )
);
