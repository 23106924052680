import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine, isConnectedToOperator } from "../../utils";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import {
  handleConferenceApiRequest,
  setChangingWebcam,
  setVideoInput,
  setWebCamOn,
  setWebCamOff,
  setUpdatingVideoInput,
  setWebcamButtonEnabled
} from "../../actions";
import * as cookiesManager from "../mediasoup/cookiesManager";
import { withRoomContext } from "../mediasoup/RoomContext";
import Logger from "../../Logger";

const logger = new Logger("VideoPopper");

const styles = theme => ({
  ...theme.style.rightBarPopper
});

class VideoPopper extends Component {
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      window.addEventListener("beforeunload", this.unpublishWebCam);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.unpublishWebCam);
  }

  handleWebCamOn = () => {
    cookiesManager.setDevices({ webcamEnabled: true });
    this.props.setChangingWebcam(true);
    this.props.setWebCamOn();
    //this.publishWebCam();
  };

  handleWebCamOff = () => {
    cookiesManager.setDevices({ webcamEnabled: false });
    this.props.setChangingWebcam(true);
    this.props.setWebCamOff();
    //this.unpublishWebCam();
  };

  publishWebCam = () => {
    this.props.handleConferenceApiRequest(
      this.props.session.userId,
      "set_webCamsPublishedState_on"
    );
  };

  unpublishWebCam = () => {
    this.props.handleConferenceApiRequest(
      this.props.session.userId,
      "set_webCamsPublishedState_off"
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newWebCamState = this.props.session.webcamEnabled;
    let newVideoDeviceId = this.props.session.videoInput;
    let isChangingWebcam = this.props.session.changingWebcam;
    let isUpdatingVideoInput = this.props.session.updatingVideoInput;

    if (isUpdatingVideoInput || isChangingWebcam) {
      if (
        prevProps.session.videoInput !== newVideoDeviceId ||
        prevProps.session.webcamEnabled !== newWebCamState
      ) {
        this.props.setChangingWebcam(false);
        this.props.setUpdatingVideoInput(false);
        this.props.setWebcamButtonEnabled(false);
        if (newWebCamState) {
          this.switchWebCam(true, newVideoDeviceId);
        } else {
          this.switchWebCam(false, null);
        }
      }
    }

    if (
      this.props.session.callState !== prevProps.session.callState &&
      isConnectedToOperator(this.props.session.callState) &&
      !isConnectedToOperator(prevProps.session.callState)
    ) {
      this.handleWebCamOff();
    }
  }

  switchWebCam = async (enable, deviceId) => {
    const { roomClientProvider } = this.props;
    if (enable) {
      logger.debug(
        "switchWebCam with enable: %s & deviceId: %s",
        enable,
        deviceId
      );
      await roomClientProvider.disableWebcam();
      if (deviceId !== "" && deviceId !== "default") {
        await roomClientProvider.enableWebcam(deviceId);
      }
    } else {
      await roomClientProvider.disableWebcam();
    }
    this.props.setWebcamButtonEnabled(true);
  };

  render() {
    const { classes, intl, session, holdState } = this.props;

    const webcamButtonEnabled =
      !holdState &&
      (!isConnectedToOperator(session.callState) ||
        // Enable the button in this case if the webcam is still enabled
        session.webcamEnabled) &&
      session.webcamButtonEnabled;
    const webcamEnabled = session.webcamEnabled;

    return (
      <Fragment>
        {webcamEnabled ? (
          <IconButton
            id="videoPopperButton"
            className={classes.popperIconButton}
            disabled={!webcamButtonEnabled}
            onClick={this.handleWebCamOff}
            title={
              intl.formatMessage(getIntl("videoControl")) +
              TitleNewLine +
              (webcamButtonEnabled
                ? intl.formatMessage(getIntl("clickToUnpublish"))
                : intl.formatMessage(getIntl("controlDisabled")))
            }
            style={!webcamButtonEnabled ? { pointerEvents: "auto" } : undefined}
          >
            <SvgIcon
              iconName="video"
              color={webcamButtonEnabled ? "active" : "inactive"}
            />
          </IconButton>
        ) : (
          <IconButton
            id="videoPopperButton"
            className={classes.popperIconButton}
            disabled={!webcamButtonEnabled}
            onClick={this.handleWebCamOn}
            title={
              intl.formatMessage(getIntl("videoControl")) +
              TitleNewLine +
              (webcamButtonEnabled
                ? intl.formatMessage(getIntl("clickToPublish"))
                : intl.formatMessage(getIntl("controlDisabled")))
            }
            style={!webcamButtonEnabled ? { pointerEvents: "auto" } : undefined}
          >
            <SvgIcon
              iconName="videoMute"
              color={webcamButtonEnabled ? "active" : "inactive"}
            />
          </IconButton>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  handleConferenceApiRequest: (userId, apiEndpoint) =>
    dispatch(handleConferenceApiRequest(userId, apiEndpoint)),
  setVideoInput: videoInput => dispatch(setVideoInput(videoInput)),
  setChangingWebcam: value => dispatch(setChangingWebcam(value)),
  setUpdatingVideoInput: value => dispatch(setUpdatingVideoInput(value)),
  setWebcamButtonEnabled: value => dispatch(setWebcamButtonEnabled(value)),
  setWebCamOn: () => dispatch(setWebCamOn()),
  setWebCamOff: () => dispatch(setWebCamOff())
});

export default withTheme(
  withStyles(styles)(
    withRouter(
      injectIntl(
        withRoomContext(
          connect(mapStateToProps, mapDispatchToProps)(VideoPopper)
        )
      )
    )
  )
);
