export const LoginErrors = {
  UNKNOWN: -1,
  INVALID_PIN: 1,
  SESSION_INVALID: 2,
  CONFERENCE_INACTIVE: 4,
  NO_PIN: 12,
  DUPLICATE_PIN: 13
};

export const handleLoginError = errorCode => {
  switch (errorCode) {
    case 13:
      return LoginErrors.DUPLICATE_PIN;
    case 12:
      return LoginErrors.NO_PIN;
    case 4:
      return LoginErrors.CONFERENCE_INACTIVE;
    case 2:
      return LoginErrors.SESSION_INVALID;
    case 1:
      return LoginErrors.INVALID_PIN;
    default:
      return LoginErrors.UNKNOWN;
  }
};
