import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

/**
 * Only one instance of this class should be created so that there is only one
 * instance of the SelfieSegmentation object. The SelfieSegmentation object's
 * close method is not called since it causes a memory leak. See
 * https://github.com/google/mediapipe/issues/2819.
 */
export default class MediaPipe {
  constructor() {
    this._requests = [];
    this._processing = false;

    this._selfieSegmentation = new SelfieSegmentation({
      locateFile: file => `js/selfie_segmentation/${file}`
    });
    this._selfieSegmentation.setOptions({
      modelSelection: 1
    });
    this._selfieSegmentation.onResults(results => {
      this._request.resolve(results);
      if (this._requests.length > 0) {
        this._processRequests();
      } else {
        this._processing = false;
      }
    });
  }

  send(data) {
    return new Promise(resolve => {
      this._requests.push({ data, resolve });
      if (!this._processing) {
        this._processing = true;
        this._processRequests();
      }
    });
  }

  _processRequests() {
    this._request = this._requests.shift();
    this._selfieSegmentation.send(this._request.data);
  }
}
