import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { languageUpdate } from "../../actions";

class CTXLanguageComponent extends Component {
  componentDidMount() {
    this.props.languageUpdate(this.props.browserLanguage);
  }

  render() {
    const { languages } = this.props;
    return (
      <IntlProvider
        locale={languages.languageCode}
        messages={languages.translations}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ languages }) => ({ languages });

const mapDispatchToProps = dispatch => ({
  languageUpdate: browserLanguage => dispatch(languageUpdate(browserLanguage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CTXLanguageComponent);
