import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { Loading } from "../greetings";
import { loginUser } from "../../actions";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";
import { loginFormFields } from "../forms/LoginForm";
import { validate } from "../forms/utils";
import { getUrlParam, redirectUnsupportedBrowsers } from "../../utils";

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  }
});

class DirectLoginPage extends Component {
  constructor(props) {
    super(props);

    redirectUnsupportedBrowsers();
  }

  handleResponse = response => {
    const { history } = this.props;

    if (response) {
      history.replace("/main?vi=" + response);
    } else {
      history.replace("/login");
    }
  };

  validate = formValues => {
    const { history } = this.props;
    let errors = validate(loginFormFields, formValues);

    if (Object.getOwnPropertyNames(errors).length !== 0) {
      let query = "/login?";
      Object.keys(formValues).forEach(function (key) {
        if (formValues[key]) {
          query += key + "=" + formValues[key] + "&";
        }
      });
      history.replace(query);
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    const { login } = this.props;
    const formValues = {
      passcode: getUrlParam("passcode"),
      username: getUrlParam("username"),
      userDefined: getUrlParam("udf"),
      userDefined2: getUrlParam("udf2"),
      userDefined3: getUrlParam("udf3"),
      userDefined4: getUrlParam("udf4")
    };
    if (this.validate(formValues)) {
      login(formValues, response => this.handleResponse(response));
    }
  }

  render() {
    const { classes } = this.props;
    const isLoggedIn = false;
    const component = <Loading />;

    return (
      <Fragment>
        <CssBaseline />
        <Header displayLogout={isLoggedIn} />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar displayContent={isLoggedIn} />
          <MainContent component={component} />
          <RightBar displayContent={isLoggedIn} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  login: (formValues, callback) =>
    dispatch(loginUser(formValues)).then(response => callback(response))
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectLoginPage))
);
