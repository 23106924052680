import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { hideConferencePreferencesMenu } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import ConferencePreferences from "../menus/ConferencePreferences";

const useStyles = makeStyles(
  {
    container: {
      width: "calc(100% - 20px)",
      maxWidth: "440px"
    },
    padding: {
      padding: 0
    }
  },
  { name: "ConferencePreferencesMenu" }
);

export default function ConferencePreferencesMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <CTXBaseWindow
      classes={{ container: classes.container, mainContent: classes.padding }}
      component={<ConferencePreferences menu />}
      overrideHandleClose={() => dispatch(hideConferencePreferencesMenu())}
      disableClickAwayClose={true}
    />
  );
}
