import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import AppLogo from "../Icons/AppLogo";
import { FormattedMessage, injectIntl } from "react-intl";
import { getIntl } from "../../utils";
import { Link } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import classNames from "classnames";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.primaryBackgroundImageTextColor
  },
  iframeContainer: {
    width: "1024px",
    height: "576px",
    marginBottom: "50px"
  },
  iframe: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    margin: "10px 0"
  },
  logoContainer: {
    width: "330px",
    marginBottom: "20px"
  },
  logo: {
    maxHeight: "200px",
    width: "100%",
    objectFit: "contain",
    margin: "10px 0"
  },
  progress: {
    width: "100%"
  },
  greetingContainer: {
    padding: "5px",
    width: "max-content",
    backgroundColor: `${theme.colors.sideBarBackgroundColor}`,
    color: `${theme.colors.primaryTextColor}`,
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px"
  },
  largePadding: {
    padding: "10px"
  },
  largeText: {
    fontSize: "1.125rem"
  },
  instructionSection: {
    marginBottom: "10px"
  },
  instructionTitle: {
    color: alpha(theme.colors.primaryTextColor, 0.7)
  },
  instructionContents: {
    marginLeft: "1.1em"
  }
});

class WaitingRoomGreeting extends Component {
  render() {
    const { classes, session, intl } = this.props;

    const phoneNumber = intl.formatMessage(getIntl("phoneNumber"));
    const isIframeEnabled =
      session.iframeSource !== null &&
      session.iframeSource !== undefined &&
      session.iframeSource.trim().length !== 0;
    const isWaitingRoomMessageConfigured =
      session.waitingRoomMessage !== null &&
      session.waitingRoomMessage !== undefined &&
      session.waitingRoomMessage.trim().length !== 0;

    return (
      <div className={classes.root}>
        {!isIframeEnabled && (
          <div className={classes.logoContainer}>
            <LinearProgress className={classes.progress} color="secondary" />
            <AppLogo classes={{ root: classes.logo }} logoType="body" />
            <LinearProgress
              className={classes.progress}
              color="secondary"
              variant="query"
            />
          </div>
        )}
        {isIframeEnabled && (
          <div className={classes.iframeContainer}>
            <iframe
              title="waitingRoomIframe"
              className={classes.iframe}
              src={session.iframeSource}
              allow="autoplay"
            />
          </div>
        )}
        <div
          className={classNames(classes.greetingContainer, {
            [classes.largePadding]:
              window.CtxAppConfigurations.customer === "TCAD"
          })}
        >
          {window.CtxAppConfigurations.customer === "TCAD" && (
            <>
              <Typography
                component="div"
                className={classNames(
                  classes.instructionSection,
                  classes.largeText
                )}
              >
                <div className={classes.instructionTitle}>
                  <FormattedMessage id="dialInStep1Call" defaultMessage="???" />
                </div>
                <div className={classes.instructionContents}>
                  <Link
                    href={"tel:" + phoneNumber}
                    color="inherit"
                    underline="always"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {phoneNumber}
                  </Link>
                </div>
              </Typography>
              <Typography
                component="div"
                className={classNames(
                  classes.instructionSection,
                  classes.largeText
                )}
              >
                <div className={classes.instructionTitle}>
                  {session.propertyID ? (
                    <FormattedMessage
                      id="dialInStep2PropertyID"
                      defaultMessage="???"
                    />
                  ) : (
                    <FormattedMessage
                      id="dialInStep2AgentID"
                      defaultMessage="???"
                    />
                  )}
                </div>
                <div className={classes.instructionContents}>
                  {session.propertyID ? session.propertyID : session.agentID}#
                </div>
              </Typography>
            </>
          )}
          {!isWaitingRoomMessageConfigured && (
            <LocalizedText
              value="waitingRoomGreeting"
              variant="body1"
              className={classes.largeText}
            />
          )}
          {isWaitingRoomMessageConfigured && (
            <Typography
              className={classes.largeText}
              variant="body1"
              color="inherit"
            >
              {session.waitingRoomMessage}
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps)(WaitingRoomGreeting))
);
