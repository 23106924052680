import React from "react";
import SvgIcon from "../../Icons/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classnames from "classnames";
import hark from "hark";
import Logger from "../../../Logger";
import { withRoomContext } from "../RoomContext";

const logger = new Logger("PeerView");

const styles = theme => ({
  root: {
    position: "relative",
    flex: "100 100 auto",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.colors.sideBarBackgroundColor
  },
  transparentBackground: {
    backgroundColor: "transparent"
  },
  cameraMuted: {
    padding: "10% 0",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundOrigin: "content-box",
    backgroundImage:
      "url(" + process.env.PUBLIC_URL + "/assets/videoMuteGray.svg)"
  },
  info: {
    position: "absolute",
    pointerEvents: "none",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    "& $peer": {
      paddingBottom: "1%",
      opacity: "0.7",
      textAlign: "center",
      "& $displayName": {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: theme.colors.talkingColor,
        backgroundColor: theme.colors.sideBarBackgroundColor,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "0.875em",
        fontWeight: 500,
        letterSpacing: "0.00714em"
      },
      "& $onHold": {
        color: theme.colors.holdColor
      },
      "& $inDrawerNameWidth": {
        width: "40%"
      },

      "& $row": {
        marginTop: "4px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        "& $deviceVersion": {
          userSelect: "none",
          pointerEvents: "none",
          fontSize: "13px",
          color: theme.colors.primaryTextColor
        }
      }
    }
  },

  stats: {},
  peer: {},
  displayName: {},
  inDrawerNameWidth: {},
  editable: {},
  loading: {},
  row: {},
  deviceVersion: {},
  onHold: {},
  video: {
    flex: "100 100 auto",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    userSelect: "none",
    transitionProperty: "opacity",
    transitionDuration: "0.5s",

    "&.hidden": {
      opacity: 0,
      transitionDuration: "0s"
    },

    "&.networkError": {
      filter: "grayscale(100%) brightness(135%) blur(5px)"
    }
  },
  screenShare: {
    objectFit: "contain"
  },
  screenSharePreview: {
    objectPosition: "top right"
  },
  audio: {
    display: "none",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1
  },

  canvasFaceDetection: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    pointerEvents: "none"
  },

  volumeContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "2px",
    width: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none"
  },

  volumeContainerBar: {
    width: "6px",
    borderRadius: "6px",
    background: theme.colors.errorMainColor,
    transitionDuration: "0.25s",

    "&.level0": { height: "0", backgroundColor: "rgba(yellow, 0.65)" },
    "&.level1": { height: "10%", backgroundColor: "rgba(yellow, 0.65)" },
    "&.level2": { height: "20%", backgroundColor: "rgba(yellow, 0.65)" },
    "&.level3": { height: "30%", backgroundColor: "rgba(yellow, 0.65)" },
    "&.level4": { height: "40%", backgroundColor: "rgba(orange, 0.65)" },
    "&.level5": { height: "50%", backgroundColor: "rgba(orange, 0.65)" },
    "&.level6": { height: "60%", backgroundColor: "rgba(red,    0.65)" },
    "&.level7": { height: "70%", backgroundColor: "rgba(red,    0.65)" },
    "&.level8": { height: "80%", backgroundColor: "rgba(#000,   0.65)" },
    "&.level9": { height: "90%", backgroundColor: "rgba(#000,   0.65)" },
    "&.level10": { height: "100%", backgroundColor: "rgba(#000,   0.65)" }
  },

  spinnerContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",

    "&.react-spinner": {
      position: "relative",
      width: "48px",
      height: "48px",
      top: "50%",
      left: "50%",

      "&.react-spinner_bar": {
        position: "absolute",
        width: "20%",
        height: "7.8%",
        top: "-3.9%",
        left: "-10%;",
        animation: "PeerView-spinner 1.2s linear infinite",
        borderRadius: "5px",
        backgroundColor: theme.colors.secondaryMainColor
      }
    }
  },

  videoElemPaused: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
    backgroundPosition: "center",
    backgroundSize: "35%",
    backgroundRepeat: "no-repeat"
  },
  svgIcon: {
    width: "1em"
  }
});

class PeerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audioVolume: 0, // Integer from 0 to 10.,
      showInfo: window.SHOW_INFO || false,
      videoResolutionWidth: null,
      videoResolutionHeight: null,
      videoCanPlay: false,
      videoElemPaused: false,
      maxSpatialLayer: null
    };

    // Latest received video track.
    // @type {MediaStreamTrack}
    this._audioTrack = null;

    // Latest received video track.
    // @type {MediaStreamTrack}
    this._videoTrack = null;

    // Hark instance.
    // @type {Object}
    this._hark = null;

    // Periodic timer for reading video resolution.
    this._videoResolutionPeriodicTimer = null;

    // requestAnimationFrame for face detection.
    this._faceDetectionRequestAnimationFrame = null;

    this._remoteControl = null;
  }

  render() {
    const {
      classes,
      isMe,
      peer,
      audioMuted,
      videoVisible,
      /*audioProducerId,
      videoProducerId,
      audioConsumerId,
      videoConsumerId,
      videoRtpParameters,
      consumerSpatialLayers,
      consumerTemporalLayers,
      consumerCurrentSpatialLayer,
      consumerCurrentTemporalLayer,
      consumerPreferredSpatialLayer,
      consumerPreferredTemporalLayer,
      consumerPriority,
      audioMuted,
      videoVisible,
      videoMultiLayer,
      audioCodec,
      videoCodec,
      audioScore,
      videoScore,
      onChangeMaxSendingSpatialLayer,
      onChangeVideoPreferredLayers,
      onChangeVideoPriority,
      onRequestKeyFrame,
      onStatsClick,*/
      isDrawer,
      screenShare
    } = this.props;

    const {
      audioVolume,
      //showInfo,
      //videoResolutionWidth,
      //videoResolutionHeight,
      videoCanPlay,
      videoElemPaused
      //maxSpatialLayer
    } = this.state;

    let user = this.props.participants.merged.find(
      item => item.id === parseInt(peer.id, 10)
    );
    let onHold = false;
    if (user) {
      onHold = this.isOnHold(user.connectState);
    }

    return (
      <div
        data-component="PeerView"
        className={classnames(classes.root, {
          [classes.cameraMuted]:
            (!videoVisible || !videoCanPlay) && !screenShare,
          [classes.transparentBackground]: screenShare
        })}
      >
        <video
          ref="videoElem"
          className={classnames(classes.video, {
            hidden: !videoVisible || !videoCanPlay,
            [classes.screenShare]: screenShare,
            [classes.screenSharePreview]: screenShare && isMe
          })}
          autoPlay
          playsInline
          muted
          controls={false}
          onContextMenu={!isMe && screenShare ? this._disableContextMenu : null}
        />

        {!screenShare && (
          <div className={classes.info}>
            <div className={classes.peer}>
              <span
                className={classnames(
                  classes.displayName,
                  isDrawer ? classes.inDrawerNameWidth : null,
                  { [classes.onHold]: onHold }
                )}
              >
                {user && user.name}
              </span>
            </div>
          </div>
        )}

        <audio
          className={classes.audio}
          ref="audioElem"
          autoPlay
          muted={isMe || audioMuted}
          controls={false}
        />

        <canvas ref="canvas" className={classes.canvasFaceDetection} />

        <div className={classes.volumeContainer}>
          <div
            className={classnames(
              classes.volumeContainer,
              `level${audioVolume}`
            )}
          />
        </div>

        {/*videoVisible && videoScore < 5 && (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )*/}

        {videoElemPaused && (
          <div className={classes.videoElemPaused}>
            <SvgIcon
              iconName="iconVideoElemPaused"
              className={classes.svgIcon}
            />
          </div>
        )}
      </div>
    );
  }

  isOnHold = callState => {
    return (
      callState === "MuteOperator" ||
      callState === "TalkOperator" ||
      callState === "MusicHold" ||
      callState === "OnHold"
    );
  };

  componentDidMount() {
    const { audioTrack, videoTrack } = this.props;

    this._setTracks(audioTrack, videoTrack);
  }

  componentWillUnmount() {
    if (this._hark) this._hark.stop();

    clearInterval(this._videoResolutionPeriodicTimer);
    cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

    const { videoElem } = this.refs;

    if (videoElem) {
      videoElem.oncanplay = null;
      videoElem.onplay = null;
      videoElem.onpause = null;
      videoElem.srcObject = null;
    }

    if (this._remoteControl) {
      this._remoteControl.Stop();
    }
  }

  componentDidUpdate() {
    const { audioTrack, videoTrack } = this.props;

    const { remoteControllerMe, roomClientProvider } = this.props;
    if (remoteControllerMe && !this._remoteControl) {
      this._remoteControl = new window.RemoteControl(
        this.refs.videoElem,
        null,
        {
          AsRemoteControlMouseEvent: (uMsg, wParam, x, y) => {
            roomClientProvider.remoteControlMouse(uMsg, wParam, x, y);
          },
          AsRemoteControlKeyboardEvent: (uMsg, wParam, lParam) => {
            roomClientProvider.remoteControlKeyboard(uMsg, wParam, lParam);
          }
        }
      );
    } else if (!remoteControllerMe && this._remoteControl) {
      this._remoteControl.Stop();
      this._remoteControl = null;
    }

    this._setTracks(audioTrack, videoTrack);
  }

  _setTracks(audioTrack, videoTrack) {
    if (this._audioTrack === audioTrack && this._videoTrack === videoTrack)
      return;

    this._audioTrack = audioTrack;
    this._videoTrack = videoTrack;

    if (this._hark) this._hark.stop();

    this._stopVideoResolution();

    const { audioElem, videoElem } = this.refs;

    if (audioTrack) {
      const stream = new MediaStream();

      stream.addTrack(audioTrack);
      audioElem.srcObject = stream;

      audioElem
        .play()
        .catch(error => logger.warn("audioElem.play() failed:%o", error));

      this._runHark(stream);
    } else {
      audioElem.srcObject = null;
    }

    if (videoTrack) {
      const stream = new MediaStream();

      stream.addTrack(videoTrack);
      videoElem.srcObject = stream;

      videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

      videoElem.onplay = () => {
        this.setState({ videoElemPaused: false });
      };

      videoElem.onpause = () => this.setState({ videoElemPaused: true });

      videoElem
        .play()
        .catch(error => logger.warn("videoElem.play() failed:%o", error));

      this._startVideoResolution();
    } else {
      videoElem.srcObject = null;
    }
  }

  _runHark(stream) {
    if (!stream.getAudioTracks()[0])
      throw new Error("_runHark() | given stream has no audio track");

    this._hark = hark(stream, { play: false });

    // eslint-disable-next-line no-unused-vars
    this._hark.on("volume_change", (dBs, threshold) => {
      // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
      //   Math.pow(10, dBs / 20)
      // However it does not produce a visually useful output, so let exagerate
      // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
      // minimize component renderings.
      let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

      if (audioVolume === 1) audioVolume = 0;

      if (audioVolume !== this.state.audioVolume)
        this.setState({ audioVolume });
    });
  }

  _startVideoResolution() {
    this._videoResolutionPeriodicTimer = setInterval(() => {
      const { videoResolutionWidth, videoResolutionHeight } = this.state;
      const { videoElem } = this.refs;

      if (
        videoElem.videoWidth !== videoResolutionWidth ||
        videoElem.videoHeight !== videoResolutionHeight
      ) {
        this.setState({
          videoResolutionWidth: videoElem.videoWidth,
          videoResolutionHeight: videoElem.videoHeight
        });
      }
    }, 500);
  }

  _stopVideoResolution() {
    clearInterval(this._videoResolutionPeriodicTimer);

    this.setState({
      videoResolutionWidth: null,
      videoResolutionHeight: null
    });
  }

  _printProducerScore(id, score) {
    const scores = Array.isArray(score) ? score : [score];

    return (
      <React.Fragment key={id}>
        <p>streams:</p>

        {scores
          .sort((a, b) => {
            if (a.rid) return a.rid > b.rid ? 1 : -1;
            else return a.ssrc > b.ssrc ? 1 : -1;
          })
          .map(
            (
              { ssrc, rid, score },
              idx // eslint-disable-line no-shadow
            ) => (
              <p key={idx} className="indent">
                {rid !== undefined
                  ? `rid:${rid}, ssrc:${ssrc}, score:${score}`
                  : `ssrc:${ssrc}, score:${score}`}
              </p>
            )
          )}
      </React.Fragment>
    );
  }

  _printConsumerScore(id, score) {
    return (
      <p key={id}>
        {`score:${score.score}, producerScore:${score.producerScore}, producerScores:[${score.producerScores}]`}
      </p>
    );
  }

  _disableContextMenu = event => {
    event.preventDefault();
  };
}

const mapStateToProps = ({ participants, session }) => ({
  participants: participants,
  remoteControllerMe: session.remoteControllerMe
});

export default withStyles(styles)(
  withRoomContext(connect(mapStateToProps)(PeerView))
);
