import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core";
import { getBuildVersion } from "../../utils";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { selectPortalView } from "../../actions";
import { Badge, Typography } from "@material-ui/core";
import classNames from "classnames";
import { getConnectNowLicenses } from "../portalComponents/actions";

export const SelectedViewType = {
  DEFAULT_VIEW: 0,
  VIEW_RECORDINGS: 1,
  VIEW_REPORTS: 2,
  SETTINGS: 3,
  USER_MANAGEMENT: 4,
  THEME_MANAGER: 5,
  LICENSE: 6
};

const styles = theme => ({
  root: {
    gridColumn: "1",
    msGridColumn: "1",
    backgroundColor: theme.colors.secondaryMainColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  svgIcon: {
    fontSize: "2.3em",
    width: "1.5em",
    padding: "0px 20px 0px 10px"
  },
  iconWithText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "30px 20px 30px 10px",
    color: theme.colors.primaryTextColor
  },
  mouseHover: {
    "&:hover": {
      backgroundColor: theme.colors.portalLeftBarMouseHoverColor,
      cursor: "pointer"
    }
  },
  iconWithTextSelected: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "30px 20px 30px 10px",
    color: theme.colors.primaryTextColor,
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  copyRightContainer: {
    color: theme.colors.primaryTextColor,
    margin: "5px 10px"
  },
  badgeMargin: {
    marginRight: "10px"
  },
  disabledText: {
    color: theme.colors.primaryBackgroundColor
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.primaryTextColor}`,
    marginTop: "2px"
  }
});

class PortalLeftBar extends Component {
  componentDidMount() {
    const { session } = this.props;
    const { portalSessionId } = session;
    this.props.getConnectNowLicenses(portalSessionId);
  }

  handleRecordingsOnClick = () => {
    this.props.selectPortalView(SelectedViewType.VIEW_RECORDINGS);
  };

  handleReportsOnClick = () => {
    this.props.selectPortalView(SelectedViewType.VIEW_REPORTS);
  };

  handleSettingsOnClick = () => {
    this.props.selectPortalView(SelectedViewType.SETTINGS);
  };

  handleUserManagementOnClick = () => {
    this.props.selectPortalView(SelectedViewType.USER_MANAGEMENT);
  };

  handleThemeManagerOnClick = () => {
    this.props.selectPortalView(SelectedViewType.THEME_MANAGER);
  };

  handleLicenseOnClick = () => {
    this.props.selectPortalView(SelectedViewType.LICENSE);
  };

  hasInvalidLicense = () => {
    const { licenseArray } = this.props.portal.license;

    if (licenseArray && licenseArray.length > 0) {
      for (let i = 0; i < licenseArray.length; i++) {
        let element = licenseArray[i];
        if (
          element.encryption &&
          element.encryption.length > 0 &&
          element.valid === false
        ) {
          return true;
        }
      }
    }

    return false;
  };

  isSystemAccessLicenseValid = () => {
    const { portal } = this.props;
    const { license } = portal;
    const { licenseArray } = license;

    if (
      licenseArray &&
      licenseArray.length > 0 &&
      licenseArray[0].key === "SYSTEM_ACCESS"
    ) {
      return licenseArray[0].valid;
    }

    return false;
  };

  render() {
    const { classes, session } = this.props;
    const isSystemAccessValid = this.isSystemAccessLicenseValid();
    return (
      <div className={classes.root}>
        <div>
          <div
            className={
              this.props.portal.selectedView ===
              SelectedViewType.VIEW_RECORDINGS
                ? classes.iconWithTextSelected
                : classNames(
                    classes.iconWithText,
                    isSystemAccessValid && classes.mouseHover
                  )
            }
            onClick={() => {
              if (!isSystemAccessValid) return;
              this.handleRecordingsOnClick();
            }}
          >
            <SvgIcon
              iconName="recordings"
              color="active"
              className={classes.svgIcon}
            />
            <LocalizedText
              value="recordings"
              variant="subtitle2"
              className={classNames(
                classes.iconText,
                !isSystemAccessValid && classes.disabledText
              )}
            />
          </div>
          <div
            className={
              this.props.portal.selectedView === SelectedViewType.VIEW_REPORTS
                ? classes.iconWithTextSelected
                : classNames(
                    classes.iconWithText,
                    isSystemAccessValid && classes.mouseHover
                  )
            }
            onClick={() => {
              if (!isSystemAccessValid) return;
              this.handleReportsOnClick();
            }}
          >
            <SvgIcon
              iconName="reports"
              color="active"
              className={classes.svgIcon}
            />
            <LocalizedText
              value="reports"
              variant="subtitle2"
              className={classNames(
                classes.iconText,
                !isSystemAccessValid && classes.disabledText
              )}
            />
          </div>
          <div
            className={
              this.props.portal.selectedView === SelectedViewType.SETTINGS
                ? classes.iconWithTextSelected
                : classNames(
                    classes.iconWithText,
                    isSystemAccessValid && classes.mouseHover
                  )
            }
            onClick={() => {
              if (!isSystemAccessValid) return;
              this.handleSettingsOnClick();
            }}
          >
            <SvgIcon
              iconName="settingsPortal"
              color="active"
              className={classes.svgIcon}
            />
            <LocalizedText
              value="profileAndSettings"
              variant="subtitle2"
              className={classNames(
                classes.iconText,
                !isSystemAccessValid && classes.disabledText
              )}
            />
          </div>
          {session.portalUser && session.portalUser.admin && (
            <Fragment>
              <div
                className={
                  this.props.portal.selectedView ===
                  SelectedViewType.USER_MANAGEMENT
                    ? classes.iconWithTextSelected
                    : classNames(
                        classes.iconWithText,
                        isSystemAccessValid && classes.mouseHover
                      )
                }
                onClick={() => {
                  if (!isSystemAccessValid) return;
                  this.handleUserManagementOnClick();
                }}
              >
                <SvgIcon
                  iconName="userManagement"
                  color="active"
                  className={classes.svgIcon}
                />
                <LocalizedText
                  value="userManagement"
                  variant="subtitle2"
                  className={classNames(
                    classes.iconText,
                    !isSystemAccessValid && classes.disabledText
                  )}
                />
              </div>
              <div
                className={
                  this.props.portal.selectedView ===
                  SelectedViewType.THEME_MANAGER
                    ? classes.iconWithTextSelected
                    : classNames(
                        classes.iconWithText,
                        isSystemAccessValid && classes.mouseHover
                      )
                }
                onClick={() => {
                  if (!isSystemAccessValid) return;
                  this.handleThemeManagerOnClick();
                }}
              >
                <SvgIcon
                  iconName="themeManager"
                  color="active"
                  className={classes.svgIcon}
                />
                <LocalizedText
                  value="themeManager"
                  variant="subtitle2"
                  className={classNames(
                    classes.iconText,
                    !isSystemAccessValid && classes.disabledText
                  )}
                />
              </div>
              <div
                className={
                  this.props.portal.selectedView === SelectedViewType.LICENSE
                    ? classes.iconWithTextSelected
                    : classNames(classes.iconWithText, classes.mouseHover)
                }
                onClick={() => this.handleLicenseOnClick()}
              >
                <SvgIcon
                  iconName="license"
                  color="active"
                  className={classes.svgIcon}
                />
                <Badge
                  variant="standard"
                  color="error"
                  invisible={!this.hasInvalidLicense()}
                  badgeContent={"!"}
                >
                  <LocalizedText
                    value="license"
                    variant="subtitle2"
                    className={classNames(
                      classes.iconText,
                      classes.badgeMargin
                    )}
                  />
                </Badge>
              </div>
            </Fragment>
          )}
        </div>
        <div className={classes.copyRightContainer}>
          <LocalizedText value="copyRight" variant="caption" />
          <div className={classes.hrDiv} />
          <Typography variant="caption">{getBuildVersion(true)}</Typography>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ portal, session }) => ({ portal, session });

const mapDispatchToProps = dispatch => ({
  selectPortalView: selectedView => dispatch(selectPortalView(selectedView)),
  getConnectNowLicenses: sessionId => dispatch(getConnectNowLicenses(sessionId))
});

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PortalLeftBar)
  )
);
