import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import { setQAWindowExpand, handleQuestionSend } from "./actions";
import VirtualizedList from "../reusable/VirtualizedList";
import QuestionCard from "./QuestionCard";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import LocalizedText from "../reusable/LocalizedText";
import { QAWindowPopoutState } from "./PopoutWindow/component";
import { InputBase } from "@material-ui/core";
import { getIntl } from "../../utils";

const styles = theme => ({
  widthExpand: {
    width: "800px"
  },
  widthCollapse: {
    width: "540px"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  footer: {
    marginTop: "1px"
  },
  mainWebQAContainer: {
    height: "351px"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  msgBoxContainerCollapse: {
    width: "500px"
  },
  msgBoxContainerExpand: {
    width: "750px"
  },
  msgBox: {
    color: theme.colors.primaryTextColor
  },
  msgBoxPadding: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  qaListContainer: {
    width: "100%",
    height: "100%"
  },
  questionCardControl: {
    textAlign: "left",
    marginTop: "5px",
    marginBottom: "5px"
  },
  noRecordsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  fullHeight: {
    height: "100%"
  },
  inputBaseContainerInApp: {
    height: "36px"
  },
  inputBaseContainerPopout: {
    height: "32px"
  },
  flexGrow: {
    flexGrow: 1
  },
  hrDivider: {
    marginLeft: "18px"
  }
});

class WebQALightView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionMessage: ""
    };

    this.webQAMessageBoxRef = React.createRef();
    this.webQAListRef = React.createRef();

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 50
    });
  }

  componentDidUpdate = prevProps => {
    this.refreshDataVirtualList();
  };

  getHorizontalDividerControl = () => {
    const { classes, qa } = this.props;
    const { expand } = qa;

    return (
      <div
        className={classNames(
          classes.hrDivPrimary,
          classes.hrDivider,
          expand ? classes.widthExpand : classes.widthCollapse
        )}
      />
    );
  };

  //Header Control
  getHeaderControl = () => {
    const { classes, qaPopoutWindow } = this.props;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    return (
      <div className={classes.displayFlexColumn}>
        {this.getHorizontalDividerControl()}
        <div
          className={classNames(
            classes.displayFlexRow,
            classes.justifyContentSpaceBetween,
            classes.alignItemsCenter,
            qaPopoutVisible
              ? classes.inputBaseContainerPopout
              : classes.inputBaseContainerInApp
          )}
        >
          {this.getQuestionInputBaseControl()}
        </div>
        {this.getHorizontalDividerControl()}
      </div>
    );
  };

  getQuestionInputBaseControl = () => {
    const { classes, qa, intl, qaPopoutWindow } = this.props;
    const { expand, dataQAState } = qa;
    const { questionMessage } = this.state;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    return (
      <div
        className={classNames(
          qaPopoutVisible
            ? classes.flexGrow
            : expand
            ? classes.msgBoxContainerExpand
            : classes.msgBoxContainerCollapse,
          classes.displayFlexRow
        )}
      >
        <InputBase
          inputRef={this.webQAMessageBoxRef}
          autoFocus
          className={classNames(classes.msgBox, classes.msgBoxPadding)}
          fullWidth={true}
          value={questionMessage}
          onChange={this.setQuestionMessage}
          onKeyPress={this.handleQuestionMessageBoxKeyPress}
          placeholder={
            dataQAState
              ? intl.formatMessage(getIntl("webQAQuestionTextFieldPlaceholder"))
              : intl.formatMessage(getIntl("webQAInactive"))
          }
        />
      </div>
    );
  };

  //WebQA Control
  getMainWebQAControl = () => {
    const { classes, qa, qaPopoutWindow } = this.props;
    const { dataQAEntries } = qa;
    const count = dataQAEntries.size;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    const mainComponent =
      count === 0 ? (
        <div className={classes.noRecordsContainer}>
          <LocalizedText value="noRecords" />
        </div>
      ) : (
        this.getQAListControl()
      );

    return (
      <div
        className={
          qaPopoutVisible ? classes.fullHeight : classes.mainWebQAContainer
        }
      >
        {mainComponent}
      </div>
    );
  };

  getQAListControl = () => {
    const { classes, qa } = this.props;
    const { dataQAEntries } = qa;

    return (
      <div className={classes.qaListContainer}>
        <VirtualizedList
          innerRef={r => (this.webQAListRef = r)}
          rowCount={dataQAEntries.size}
          rowHeight={this.getRowHeight}
          rowRenderer={this.rowRenderer}
          deferredMeasurementCache={this.cache}
          noFocusOutline
        />
      </div>
    );
  };

  rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
    const { qa } = this.props;
    const { dataQAEntries } = qa;
    const dataQAEntriesArray = [...dataQAEntries];
    const entry = dataQAEntriesArray[index][1];

    return this.getQuestionCardControl(
      index,
      isScrolling,
      isVisible,
      key,
      parent,
      style,
      entry
    );
  };

  getQuestionCardControl = (
    index,
    isScrolling,
    isVisible,
    key,
    parent,
    style,
    qaEntry
  ) => {
    const { classes } = this.props;
    const { question, answerMap, id } = qaEntry;
    const { owner, questionCreateTime, contents } = question;
    const { username } = owner;

    return (
      <CellMeasurer
        cache={this.cache}
        key={key}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        {({ measure, registerChild }) => (
          <div
            onLoad={measure}
            className={classes.questionCardControl}
            ref={registerChild}
            style={style}
          >
            <QuestionCard
              lightViewEnabled={true}
              resizeParentContainer={this.refreshDataVirtualList}
              id={"MyQuestionCard" + id}
              questionId={id}
              questionerName={username}
              questionCreateTime={this.convertUTCtoLocalTime(
                questionCreateTime
              )}
              questionContents={contents}
              answerObjArray={answerMap != null ? Object.values(answerMap) : []}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  //Footer Control
  getFooterControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.footer}>{this.getHorizontalDividerControl()}</div>
    );
  };

  //function
  setQuestionMessage = event => {
    this.setState({
      questionMessage: event.target.value
    });
  };

  handleQuestionMessageBoxKeyPress = event => {
    if (event.key === "Enter") {
      this.handleQuestionSend(this.state.questionMessage);
      event.preventDefault();
    }
  };

  handleQuestionSend = question => {
    const { session } = this.props;
    const { userId } = session;

    if (question != null && question !== "") {
      this.props.handleQuestionSend(userId, this.state.questionMessage);
      this.setState({ questionMessage: "" });
    }
    this.webQAMessageBoxRef.current.focus();
  };

  getRowHeight = index => {
    return this.cache.rowHeight(index);
  };

  convertUTCtoLocalTime = UTCDateTimeString => {
    //Convert UTC datetime to local datetime
    let utcDateObj = new Date(
      UTCDateTimeString.replace(" ", "T") + ".000+00:00"
    );

    return utcDateObj.toLocaleString();
  };

  refreshDataVirtualList = () => {
    this.cache.clearAll();
    if (this.webQAListRef && this.webQAListRef.forceUpdateGrid) {
      this.webQAListRef.forceUpdateGrid();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classNames(classes.displayFlexColumn, classes.fullHeight)}
      >
        {this.getHeaderControl()}
        {this.getMainWebQAControl()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, qa, qaPopoutWindow }) => ({
  session,
  qa,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAWindowExpand: isExpanded => dispatch(setQAWindowExpand(isExpanded)),
  handleQuestionSend: (userId, question) =>
    dispatch(handleQuestionSend(userId, question))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(WebQALightView))
  )
);
