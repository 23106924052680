import React from "react";
import { CSSTransition } from "react-transition-group";

const Appear = ({ duration, children }) => (
  <CSSTransition in classNames="Appear" timeout={duration || 1000} appear>
    {children}
  </CSSTransition>
);

export { Appear };
