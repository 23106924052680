import React, { Component } from "react";
import CallMe from "./CallMe";
import DialIn from "./DialIn";
import ButtonsPage from "./ButtonsPage";
import DisconnectCall from "./DisconnectCall";
import ConnectWithDevice from "./ConnectWithDevice";
import { setShakeConferenceSecureIcon } from "./../../actions";
import { connect } from "react-redux";
import { isWebRTCAudioEnabled } from "../../utils";

export const Pages = {
  base: 0,
  callMe: 1,
  dialIn: 2,
  connectWithDevice: 3
};

class AudioComponent extends Component {
  state = {
    page: Pages.base
  };

  componentDidMount() {
    const { session } = this.props;

    if (
      isWebRTCAudioEnabled(session.webRTCCallConfig) &&
      session.audioEnabled
    ) {
      this.setState({
        page: Pages.connectWithDevice
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevSession = prevProps.session;
    const { session } = this.props;

    if (
      !(
        isWebRTCAudioEnabled(prevSession.webRTCCallConfig) &&
        prevSession.audioEnabled
      ) &&
      isWebRTCAudioEnabled(session.webRTCCallConfig) &&
      session.audioEnabled
    ) {
      this.setState({
        page: Pages.connectWithDevice
      });
    }
  }

  handleClick = value => {
    //stop "Dail In" if conference is secured
    const { conference } = this.props;

    if (conference.secure && value === Pages.dialIn) {
      this.props.setShakeConferenceSecureIcon(true);
      setTimeout(this.restoreShakeConferenceIcon, 600);
      return;
    }

    this.setState({ page: value });
  };

  restoreShakeConferenceIcon = () => {
    this.props.setShakeConferenceSecureIcon(false);
  };

  handleClickAway = value => {
    this.props.onClick(value);
  };

  render() {
    if (this.props.mergedCallState === "connected") {
      return (
        <DisconnectCall
          handleClickAway={value => this.handleClickAway(value)}
        />
      );
    } else if (this.state.page === Pages.base) {
      return <ButtonsPage onClick={value => this.handleClick(value)} />;
    } else if (this.state.page === Pages.callMe) {
      return <CallMe />;
    } else if (this.state.page === Pages.dialIn) {
      return <DialIn />;
    } else if (this.state.page === Pages.connectWithDevice) {
      return (
        <ConnectWithDevice
          handleClickAway={value => this.handleClickAway(value)}
        />
      );
    }
  }
}

const mapStateToProps = ({ session, conference }) => ({
  session,
  conference
});

const mapDispatchToProps = dispatch => ({
  setShakeConferenceSecureIcon: isShaking =>
    dispatch(setShakeConferenceSecureIcon(isShaking))
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioComponent);
