import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine } from "../../utils";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { withTheme } from "@material-ui/core/styles";
import {
  getFullscreenElement,
  requestFullscreen,
  exitFullscreen
} from "../../utils";

const styles = theme => ({
  iconButton: {
    width: "45px",
    marginLeft: "10px"
  }
});

class FullScreenIcon extends Component {
  toggleFullScreen = () => {
    if (!getFullscreenElement()) {
      requestFullscreen();
    } else {
      exitFullscreen();
    }
  };

  render() {
    const { classes, intl, session } = this.props;
    const { fullScreenMode } = session;

    return (
      <IconButton
        className={classes.iconButton}
        onClick={this.toggleFullScreen}
        title={
          intl.formatMessage(getIntl("fullScreenControl")) +
          TitleNewLine +
          (fullScreenMode
            ? intl.formatMessage(getIntl("clickToExitFullScreen"))
            : intl.formatMessage(getIntl("clickToEnterFullScreen")))
        }
      >
        {fullScreenMode ? (
          <SvgIcon iconName="fullScreenExit" />
        ) : (
          <SvgIcon iconName="fullScreen" />
        )}
      </IconButton>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(FullScreenIcon))
  )
);
