import React, { Component } from "react";
import poweredByCpx from "./poweredByCpx.svg";
import { isCM } from "../../utils";

class PoweredByCpx extends Component {
  render() {
    if (isCM()) {
      return null;
    }

    const { classes } = this.props;
    let rootStyle;
    if (classes != null) {
      rootStyle = classes.root;
    }
    return (
      <img
        src={process.env.PUBLIC_URL + poweredByCpx}
        className={rootStyle}
        alt="Powered by Compunetix"
      />
    );
  }
}

export default PoweredByCpx;
