import React, { Component } from "react";

export function makeResponsive(WrappedComponent) {
  return class ResponsiveContainer extends Component {
    state = {
      containerHeight: 0,
      containerWidth: 0
    };

    componentDidMount() {
      window.addEventListener("resize", this.handleResize);

      this.setState({
        ...this.state,
        containerHeight: this.selfRef.offsetHeight,
        containerWidth: this.selfRef.offsetWidth
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
      this.setState({
        ...this.state,
        containerHeight: this.selfRef.offsetHeight,
        containerWidth: this.selfRef.offsetWidth
      });
    };

    render() {
      return (
        <div
          className="responsiveContainer"
          ref={r => (this.selfRef = r)}
          style={{ width: "100%", height: "100%" }}
        >
          <WrappedComponent
            containerHeight={this.state.containerHeight}
            containerWidth={this.state.containerWidth}
            {...this.props}
          />
        </div>
      );
    }
  };
}
