import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import CTXTable from "../reusable/CTXTable";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { resetPortalBillingFeatureActions } from "../../actions";
import { getIntl } from "../../utils";

const styles = theme => ({});

const ActionType = {
  DATA_SHARE: "DATA_SHARE",
  DATA_RECORD: "DATA_RECORD"
};

class PortalReportFeatureActionTable extends Component {
  constructor(props) {
    super(props);
    this.moment = require("moment-timezone");
    this.timezone = this.moment.tz.guess();
  }

  componentWillUnmount() {
    this.props.resetPortalBillingFeatureActions();
  }

  convertDuration = seconds => {
    //output hh:mm:ss
    let resHH = Math.floor(seconds / 60 / 60);
    let resMM = Math.floor((seconds - 3600 * resHH) / 60);
    let resSS = (seconds - 3600 * resHH - 60 * resMM).toFixed(0);

    return (
      resHH.toString().padStart(2, "0") +
      ":" +
      resMM.toString().padStart(2, "0") +
      ":" +
      resSS.toString().padStart(2, "0")
    );
  };

  getTableData = reportFeatureActions => {
    const { intl } = this.props;

    var getTableDataResult = [];

    if (reportFeatureActions === undefined) {
      return getTableDataResult;
    }

    for (var key in reportFeatureActions) {
      let tableRowData = {};

      //Key
      tableRowData.featureActionKey =
        reportFeatureActions[key].featureActionKey;
      //Action
      switch (reportFeatureActions[key].actionType) {
        case ActionType.DATA_RECORD:
          tableRowData.actionType = intl.formatMessage(getIntl("recording"));
          break;
        case ActionType.DATA_SHARE:
          tableRowData.actionType = intl.formatMessage(getIntl("sharing"));
          break;
        default:
          tableRowData.actionType = intl.formatMessage(getIntl("unknown"));
      }
      //startTime
      let utcStartDateTime = new Date(
        reportFeatureActions[key].actionDateTimeStart.replace(" ", "T") +
          ".000+00:00"
      );
      tableRowData.startTime = utcStartDateTime.toLocaleString();
      //Duration
      let startDateTime = new Date(
        reportFeatureActions[key].actionDateTimeStart.replace(" ", "T")
      );
      let endDateTime = new Date(
        reportFeatureActions[key].actionDateTimeEnd.replace(" ", "T")
      );
      let durationDiff =
        (endDateTime.getTime() - startDateTime.getTime()) / 1000;
      tableRowData.duration = this.convertDuration(durationDiff);

      getTableDataResult.push(tableRowData);
    }

    return getTableDataResult;
  };

  sortStartTime = (object1, object2) => {
    let startTime1 = object1.startTime;
    let startTime2 = object2.startTime;
    let date1 = new Date(startTime1);
    let date2 = new Date(startTime2);

    if (date1 <= date2) {
      return -1;
    } else {
      return 1;
    }
  };

  render() {
    const { translations } = this.props.languages;
    const tableTitle = translations.conferenceActivity;
    const tableColumns = [
      { title: "Key", field: "featureActionKey", hidden: true },
      {
        title: translations.action,
        field: "actionType"
      },
      {
        title:
          translations.startTime +
          " ( " +
          this.moment().tz(this.timezone).format("z") +
          " )",
        field: "startTime",
        type: "time",
        customSort: this.sortStartTime,
        defaultSort: "asc"
      },
      {
        title: translations.duration,
        field: "duration"
      }
    ];

    let tableData = this.getTableData(this.props.portal.reportFeatureActions);

    return (
      <CTXTable
        title={tableTitle}
        columns={tableColumns}
        data={tableData}
        maxBodyHeight={this.props.maxBodyHeight}
      />
    );
  }
}

const mapStateToProps = ({ portal, session, languages }) => ({
  portal,
  session,
  languages
});

const mapDispatchToProps = dispatch => ({
  resetPortalBillingFeatureActions: () =>
    dispatch(resetPortalBillingFeatureActions())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(PortalReportFeatureActionTable)
    )
  )
);
