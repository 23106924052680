import React, { Component } from "react";
import { makeResponsive } from "../../reusable/ResponsiveContainer";
import { Room } from "../components";

class RoomResponsive extends Component {
  render() {
    return <Room {...this.props} />;
  }
}

export default makeResponsive(RoomResponsive);
