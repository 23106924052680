import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import classNames from "classnames";

const useStyles = makeStyles(
  theme => ({
    flexItemSmall: {
      flexGrow: 1
    },
    flexItemLarge: {
      flexGrow: 4,
      width: "95%"
    },
    flexMiniRowBox: {
      width: "auto",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.primaryBackgroundColor,
      borderRadius: "10px",
      padding: "0px 5px 0px 5px"
    },
    button: {
      cursor: "pointer"
    },
    sliderDiv: {
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    sliderRoot: {
      height: 0,
      "@media (pointer: coarse)": {
        padding: "13px 0"
      }
    },
    sliderThumb: {
      width: "6px",
      marginLeft: "-3px",
      height: "12px",
      backgroundColor: theme.colors.activeIconColor,
      borderRadius: "30%"
    },
    sliderColor: {
      backgroundColor: theme.colors.secondaryMainColor
    }
  }),
  { name: "CTXSlider" }
);

export default function CTXSlider({
  value,
  onChange,
  onIncrease,
  onDecrease,
  step,
  max
}) {
  const classes = useStyles();

  return (
    <div className={classes.flexMiniRowBox}>
      <Remove
        className={classNames(classes.flexItemSmall, classes.button)}
        onClick={onDecrease}
      />
      <div className={classNames(classes.flexItemLarge, classes.sliderDiv)}>
        <Slider
          value={value}
          onChange={onChange}
          step={step}
          max={max}
          classes={{
            root: classes.sliderRoot,
            thumb: classes.sliderThumb,
            rail: classes.sliderColor,
            track: classes.sliderColor
          }}
        />
      </div>
      <Add
        className={classNames(classes.flexItemSmall, classes.button)}
        onClick={onIncrease}
      />
    </div>
  );
}
