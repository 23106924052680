import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl, FormattedDate } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import { setPortalDownloadReportDialogWindowVisible } from "../../actions";
import { withTheme } from "@material-ui/core/styles";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "5px",
    width: "250px"
  },
  headerText: {
    float: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "5px",
    alignItems: "center"
  },
  dateRange: {
    marginBottom: "15px",
    alignSelf: "center"
  },
  textButton: {
    width: "180px"
  }
});

class DownloadReportDialogWindow extends Component {
  overrideHandleClose = () => {
    this.props.setDownloadReportDialogWindowVisible(false);
  };

  getMySqlUTCDateTimeFormat = date => {
    return (
      date.getUTCFullYear().toString() +
      "-" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getUTCDate().toString().padStart(2, "0") +
      " " +
      date.getUTCHours().toString().padStart(2, "0") +
      ":" +
      date.getUTCMinutes().toString().padStart(2, "0") +
      ":" +
      date.getUTCSeconds().toString().padStart(2, "0")
    );
  };

  getParticipantReportLink = () => {
    let startDateTime = this.props.portal.reportDateFrom;
    let endDateTime = this.props.portal.reportDateTo;
    if (endDateTime) {
      let endDate = new Date(
        endDateTime.split(" ")[0] +
          "T" +
          endDateTime.split(" ")[1] +
          ".000+00:00"
      );
      endDateTime = this.getMySqlUTCDateTimeFormat(
        new Date(endDate.getTime() + 1000 * 60 * 60 * 24)
      );
    }

    return (
      window.CtxAppConfigurations.restUri +
      "billing/" +
      this.props.session.portalSessionId +
      "/participantReport?startDateTime=" +
      startDateTime +
      "&endDateTime=" +
      endDateTime
    );
  };

  getConferenceReportLink = () => {
    let startDateTime = this.props.portal.reportDateFrom;
    let endDateTime = this.props.portal.reportDateTo;
    if (endDateTime) {
      let endDate = new Date(
        endDateTime.split(" ")[0] +
          "T" +
          endDateTime.split(" ")[1] +
          ".000+00:00"
      );
      endDateTime = this.getMySqlUTCDateTimeFormat(
        new Date(endDate.getTime() + 1000 * 60 * 60 * 24)
      );
    }

    return (
      window.CtxAppConfigurations.restUri +
      "billing/" +
      this.props.session.portalSessionId +
      "/conferenceReport?startDateTime=" +
      startDateTime +
      "&endDateTime=" +
      endDateTime
    );
  };

  render() {
    const { classes, portal } = this.props;

    const component = (
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <LocalizedText
              value="downloadReport"
              variant="h6"
              className={classes.headerText}
            />
          </div>
          <div className={classes.hrDiv} />
        </div>
        <div className={classes.mainContent}>
          <div className={classes.dateRange}>
            <Typography color="inherit" variant="subtitle2">
              <FormattedDate
                value={
                  new Date(
                    portal.reportDateFrom.replace(" ", "T") + ".000+00:00"
                  )
                }
                year="numeric"
                month="numeric"
                day="numeric"
              />
              {" - "}
              <FormattedDate
                value={
                  new Date(portal.reportDateTo.replace(" ", "T") + ".000+00:00")
                }
                year="numeric"
                month="numeric"
                day="numeric"
              />
            </Typography>
          </div>
          <Link
            href={this.getConferenceReportLink()}
            target="_blank"
            rel="noopener"
          >
            <TextButton
              className={classes.textButton}
              onClick={() =>
                this.props.setDownloadReportDialogWindowVisible(false)
              }
            >
              <LocalizedText value="conferenceReport" />
            </TextButton>
          </Link>
          <Link
            href={this.getParticipantReportLink()}
            target="_blank"
            rel="noopener"
          >
            <TextButton
              className={classes.textButton}
              onClick={() =>
                this.props.setDownloadReportDialogWindowVisible(false)
              }
            >
              <LocalizedText value="participantReport" />
            </TextButton>
          </Link>
        </div>
      </div>
    );

    return (
      <Fragment>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={this.overrideHandleClose}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ portal, session }) => ({
  portal,
  session
});

const mapDispatchToProps = dispatch => ({
  setDownloadReportDialogWindowVisible: isVisible =>
    dispatch(setPortalDownloadReportDialogWindowVisible(isVisible))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(DownloadReportDialogWindow)
    )
  )
);
