import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

/**
 * Generic text button with any children inside it.
 * @param {props} props
 */

const styles = theme => ({
  root: {
    marginBottom: "10px",
    minHeight: "40px",
    minWidth: "180px",
    borderRadius: "5px",
    color: theme.colors.primaryMainTextColor
  },
  label: {
    textTransform: "none"
  },
  contained: {
    "&$disabled": {
      color: theme.colors.inactiveButtonTextColor,
      backgroundColor: theme.colors.inactiveIconColor
    }
  },
  containedError: {
    color: theme.colors.errorTextColor,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.6)
    }
  },
  disabled: {}
});

class TextButton extends Component {
  render() {
    const { props } = this;
    let color;
    let contained;
    if (props.color === "error") {
      contained = props.classes.containedError;
      color = "default";
    } else {
      contained = props.classes.contained;
      color = props.color || "primary";
    }
    return (
      <Button
        classes={{
          root: props.classes.root,
          label: props.classes.label,
          contained: contained,
          disabled: props.classes.disabled
        }}
        variant={props.variant || "contained"}
        color={color}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        className={props.className}
        title={props.title}
        style={props.style}
      >
        {props.children}
      </Button>
    );
  }
}

export default withStyles(styles)(TextButton);
