export default class CMSocket {
  on(eventName, callback) {
    switch (eventName) {
      case "party":
        window.gCnHandler.handleParticpantData = callback;
        break;
      case "conference":
        window.gCnHandler.handleCMUpdateConference = callback;
        break;
      case "conferenceState":
        window.gCnHandler.handleCMUpdateConferenceState = callback;
        break;
      case "user":
        window.gCnHandler.handleCMUserData = callback;
        break;
      case "chat":
        window.gCnHandler.handleCMChat = callback;
        break;
      case "notification":
        window.gCnHandler.handleCMNotification = callback;
        break;
      case "transcript":
        window.gCnHandler.handleCMTranscript = callback;
        break;
      case "partialTranscript":
        window.gCnHandler.handleCMPartialTranscript = callback;
        break;
      default:
        break;
    }
  }
}
