import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import NotificationWindow from "../portalComponents/NotificationWindow";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.primaryBackgroundColor,
    backgroundImage:
      "url('/" +
      theme.imagesLocation +
      "/" +
      window.CtxThemeConfigurations.backgroundImage +
      "')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  }
});

class PortalContent extends Component {
  render() {
    const { classes, component } = this.props;

    return (
      <div className={classes.root}>
        {component}
        <NotificationWindow />
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(connect(mapStateToProps)(PortalContent));
