import { LANGUAGE_UPDATE } from "../actions/types";

export default function languagesReducer(
  state = { languageCode: "en", translations: {} },
  action
) {
  let newState = {};
  switch (action.type) {
    case LANGUAGE_UPDATE:
      newState = { ...state };
      newState.languageCode = action.payload.languageCode;
      newState.translations = action.payload.translations;
      return newState;
    default:
      return state;
  }
}
