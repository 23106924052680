import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { withTheme } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getIntl } from "../../utils";
import { injectIntl } from "react-intl";
import classNames from "classnames";

const styles = theme => ({
  container: {
    display: "flex",
    zIndex: 11,
    position: "fixed",
    flexDirection: "column",
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    borderRadius: "10px",
    border: `1px solid ${theme.colors.primaryMainColor}`,
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  header: {
    width: "100%",
    minHeight: "35.43px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px",
    float: "right"
  },
  closeHeaderIcon: {
    width: ".7em"
  },
  mainContent: {
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  fullWidth: {
    width: "100%"
  },
  fullHeight: {
    height: "100%"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  }
});

/* Attribute "overrideHandleClose" can be used to override default close window behavior ( set state.open to false ).
 * Attribute "disableClickAwayClose" can be used to override default close window behavior by clicking away.
 * Attribute "hideCloseButton" can be used to hide close button "X". Header white space will not go away.
 * Attribute "hidePopoutButton". default true.
 * Attribute "fullWidth". default false. When true, parent container width is set to 100%
 */

class CTXBaseWindow extends Component {
  state = {
    open: true
  };

  handleClose = () => {
    const overrideHandleClose = this.props.overrideHandleClose;

    if (overrideHandleClose === undefined) {
      this.setState({ ...this.state, open: false });
    } else {
      overrideHandleClose();
    }
  };

  handleClickAway = () => {
    if (
      !(
        this.props.disableClickAwayClose !== undefined &&
        this.props.disableClickAwayClose === true
      )
    ) {
      this.handleClose();
    }
  };

  render() {
    const { classes, component, hideCloseButton, hidePopoutButton, intl } =
      this.props;

    const closeButton = hideCloseButton ? null : (
      <IconButton
        className={classes.closeHeaderButton}
        onClick={() => this.handleClose()}
        title={intl.formatMessage(getIntl("close"))}
      >
        <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
      </IconButton>
    );

    const popoutButton =
      hidePopoutButton != null && !hidePopoutButton ? (
        <IconButton
          className={classes.closeHeaderButton}
          onClick={() => this.props.handlePopoutClick()}
          title={intl.formatMessage(getIntl("popoutIntoNewWindow"))}
        >
          <SvgIcon iconName="popout" className={classes.closeHeaderIcon} />
        </IconButton>
      ) : undefined;

    const headerComponent = (
      <div className={classes.displayFlexColumn}>
        <div className={classes.header}>
          {closeButton}
          {popoutButton}
        </div>
        {this.props.enableBanner && this.props.banner}
      </div>
    );

    return this.state.open ? (
      <Fragment>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div
            className={classNames(classes.container, {
              [classes.fullWidth]: this.props.fullWidth,
              [classes.fullHeight]: this.props.fullHeight
            })}
          >
            {headerComponent}
            <div
              className={classNames(classes.mainContent, {
                [classes.fullHeight]: this.props.fullHeight
              })}
            >
              {component}
            </div>
          </div>
        </ClickAwayListener>
      </Fragment>
    ) : (
      <Fragment />
    );
  }
}

export default withTheme(withStyles(styles)(injectIntl(CTXBaseWindow)));
