import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { getTextColor } from "../../style";
import { IconButton } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import PoweredByCpx from "../Icons/PoweredByCpx";
import {
  getIntl,
  isWebRTCAudioEnabled,
  getBuildVersion,
  isVideoEnabled,
  isHost,
  isCM
} from "../../utils";
import { injectIntl } from "react-intl";
import {
  showSettingsMenu,
  showConferencePreferencesMenu,
  hideSettingsMenu,
  hideConferencePreferencesMenu,
  setHamburgMenuState
} from "../../actions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import classNames from "classnames";

const headerHeight = 64;
const styles = theme => ({
  menuIconButton: {
    fontSize: "1.5em",
    width: headerHeight,
    padding: "20px"
  },
  menuContainer: {
    width: "300px",
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    position: "fixed",
    left: 0,
    top: headerHeight,
    opacity: "0.95",
    padding: "0px 20px 0px 20px"
  },
  menuHeader: {
    width: "100%",
    textAlign: "left",
    height: "30px",
    lineHeight: "25px",
    fontSize: "1em",
    verticalAlign: "middle",
    marginBottom: "2px"
  },
  menuContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1
  },
  menuItemEnabled: {
    cursor: "pointer"
  },
  hrDiv: {
    width: "100%",
    borderTop: "3px solid #686868",
    marginTop: "2px"
  },
  menuFooter: {
    display: "flex",
    justifyContent: "space-between"
  },
  versionContainer: {
    paddingTop: "5px"
  },
  menuFooterPoweredByCtx: {
    paddingTop: "10px"
  },
  compunetixDimension: {
    height: "20px"
  },
  menuItemText: {
    alignSelf: "center"
  },
  copyRight: {
    alignSelf: "center"
  }
});

class AppDrawerMenu extends Component {
  toggleMenu = () => {
    const { isHamburgMenuOpen } = this.props.session;

    if (isHamburgMenuOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  };

  closeMenu = () => {
    this.props.setHamburgMenuState(false);
  };

  openMenu = () => {
    this.props.setHamburgMenuState(true);
  };

  handleSettingsOnClick = () => {
    this.props.showSettingsMenu();
    this.props.hideConferencePreferencesMenu();
    this.closeMenu();
  };

  handleConferencePreferencesOnClick = () => {
    this.props.showConferencePreferencesMenu();
    this.props.hideSettingsMenu();
    this.closeMenu();
  };

  getSettingsComponent = () => {
    const { classes, theme } = this.props;

    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={this.handleSettingsOnClick}
      >
        <IconButton className={classes.menuIconButton}>
          <SvgIcon iconName="controls" color="active" />
        </IconButton>
        <LocalizedText
          value="userControls"
          variant="subtitle2"
          className={classNames(classes.menuItemText, classes.menuItemEnabled)}
          style={{
            color: theme.colors.primaryTextColor
          }}
        />
      </div>
    );
  };

  getConferencePreferencesComponent = () => {
    const { classes, theme } = this.props;

    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={this.handleConferencePreferencesOnClick}
      >
        <IconButton className={classes.menuIconButton}>
          <SvgIcon iconName="preferences" color="active" />
        </IconButton>
        <LocalizedText
          value="conferencePreferences"
          variant="subtitle2"
          className={classNames(classes.menuItemText, classes.menuItemEnabled)}
          style={{
            color: theme.colors.primaryTextColor
          }}
        />
      </div>
    );
  };

  getPortalLoginComponent = () => {
    const { classes, theme } = this.props;

    return (
      <Link
        href={
          window.location.protocol + "//" + window.location.host + "/portal"
        }
        target="_blank"
        rel="noopener"
        underline="none"
      >
        <div style={{ display: "inline-flex" }}>
          <IconButton className={classes.menuIconButton}>
            <SvgIcon iconName="portal" color="active" />
          </IconButton>
          <LocalizedText
            value="portalLogin"
            variant="subtitle2"
            className={classes.menuItemText}
            style={{ color: getTextColor(theme, false) }}
          />
        </div>
      </Link>
    );
  };

  getMenuComponents = () => {
    const { session } = this.props;
    const settingsEnabled =
      isWebRTCAudioEnabled(session.webRTCCallConfig) ||
      isVideoEnabled(session.videoConfig);

    return (
      <Fragment>
        {settingsEnabled && this.getSettingsComponent()}
        {isHost(session) &&
          isVideoEnabled(session.videoConfig) &&
          this.getConferencePreferencesComponent()}
        {isHost(session) &&
          window.CtxAppConfigurations.customer !== "TCAD" &&
          !isCM() &&
          this.getPortalLoginComponent()}
      </Fragment>
    );
  };

  render() {
    const { classes, disabled, intl, session } = this.props;
    const { isHamburgMenuOpen } = session;

    const height =
      this.props.window.height === 0
        ? window.innerHeight - headerHeight
        : this.props.window.height - headerHeight;

    const menuComponents = this.getMenuComponents();

    return (
      <Fragment>
        <IconButton
          className={classes.menuIconButton}
          disabled={disabled}
          onClick={this.toggleMenu}
          title={intl.formatMessage(getIntl("menu"))}
        >
          <SvgIcon iconName="menu" color="primaryMainText" />
        </IconButton>
        <Drawer
          anchor="left"
          open={isHamburgMenuOpen}
          classes={{
            paper: classes.menuContainer
          }}
          PaperProps={{
            style: { height: height }
          }}
          ModalProps={{
            onClose: this.closeMenu,
            BackdropProps: {
              invisible: true
            }
          }}
        >
          <div className={classes.menuHeader}>
            <LocalizedText value="menu" variant="h6" />
          </div>
          <div className={classes.hrDiv} />
          <div className={classes.menuContent}>{menuComponents}</div>
          <div className={classes.copyRight}>
            <LocalizedText
              value="copyRight"
              variant="caption"
              color="secondary"
            />
          </div>
          <div className={classes.hrDiv} />
          <div className={classes.menuFooter}>
            <div className={classes.versionContainer}>
              <Typography color="secondary" variant="caption">
                {getBuildVersion(true)}
              </Typography>
            </div>
            <div className={classes.menuFooterPoweredByCtx}>
              <PoweredByCpx classes={{ root: classes.compunetixDimension }} />
            </div>
          </div>
        </Drawer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ window, session }) => ({
  window,
  session
});

const mapDispatchToProps = dispatch => ({
  showSettingsMenu: () => dispatch(showSettingsMenu()),
  showConferencePreferencesMenu: () =>
    dispatch(showConferencePreferencesMenu()),
  hideSettingsMenu: () => dispatch(hideSettingsMenu()),
  hideConferencePreferencesMenu: () =>
    dispatch(hideConferencePreferencesMenu()),
  setHamburgMenuState: isOpen => dispatch(setHamburgMenuState(isOpen))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppDrawerMenu))
  )
);
