import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  TextField,
  CircularProgress
} from "@material-ui/core";
import classNames from "classnames";
import { getIntl } from "../../utils";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import {
  setNewUserModalConferenceType,
  setNewUserModalUserPrompt,
  UserPromptLevel,
  setButtonLoader,
  createNewUserAccount,
  activateDisabledUserAccount
} from "./actions";
import ExternalConferenceTable from "./ExternalConferenceTable";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 40px 0px 40px",
    height: "100%"
  },
  conferenceTypeContainer: {
    padding: "0px 30px 0px 30px"
  },
  conferenceInfoContainer: {
    padding: "0px 30px 0px 30px",
    width: "80%",
    flexGrow: 1
  },
  textfieldGeneral: {
    width: "50%",
    marginTop: "8px"
  },
  buttonProgress: {
    position: "absolute",
    marginLeft: -75,
    marginTop: 5
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  displayFlexReverseRow: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  }
});

export const AudioConferenceType = {
  Internal: "0",
  External: "1"
};

class LinkAudioConference extends Component {
  state = {
    textFieldConferenceName: "",
    textFieldBillingCode: "",
    textFieldHostPasscode: "",
    textFieldGuestPasscode: ""
  };
  //controls
  getAudioConferenceTypeRadioControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserAudioConferenceType } = portalUserManagement;

    return (
      <FormControl className={classes.conferenceTypeContainer}>
        <FormLabel>
          <LocalizedText
            value="audioConferenceType"
            variant="body1"
            className={classes.primaryTextColor}
          />
        </FormLabel>
        <RadioGroup
          name="Audio Conference Type"
          value={newUserAudioConferenceType}
          row
          onChange={this.handleAudioConfTypeRadioOnChange}
        >
          <FormControlLabel
            value={AudioConferenceType.Internal}
            control={
              <Radio
                classes={{ root: classes.primaryTextColor }}
                color="primary"
              />
            }
            label={
              <LocalizedText
                value="internal"
                variant="body2"
                className={classes.primaryTextColor}
              />
            }
          />
          <FormControlLabel
            value={AudioConferenceType.External}
            control={
              <Radio
                classes={{ root: classes.primaryTextColor }}
                color="primary"
              />
            }
            label={
              <LocalizedText
                value="external"
                variant="body2"
                className={classes.primaryTextColor}
              />
            }
          />
        </RadioGroup>
      </FormControl>
    );
  };

  getAudioConferenceInfoControls = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserAudioConferenceType } = portalUserManagement;

    if (newUserAudioConferenceType === AudioConferenceType.Internal) {
      return this.getInternalConferenceInfoControls();
    } else {
      return this.getExternalConferenceInfoControls();
    }
  };

  getInternalConferenceInfoControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.conferenceInfoContainer}>
        {this.getGeneralEntryControl(
          intl.formatMessage(getIntl("conferenceName")),
          this.state.textFieldConferenceName,
          "LinkAudioConferenceTextFieldConferenceName",
          this.handleTextFieldConferenceNameOnChange,
          "text",
          60
        )}
        {this.getGeneralEntryControl(
          intl.formatMessage(getIntl("billingCode")),
          this.state.textFieldBillingCode,
          "LinkAudioConferenceTextFieldBillingCode",
          this.handleTextFieldBillingCodeOnChange,
          "text",
          8
        )}
        {this.getGeneralEntryControl(
          intl.formatMessage(getIntl("conferenceHostPasscode")),
          this.state.textFieldHostPasscode,
          "LinkAudioConferenceTextFieldHostPasscode",
          this.handleTextFieldHostPasscodeOnChange,
          "text",
          18
        )}
        {this.getGeneralEntryControl(
          intl.formatMessage(getIntl("conferenceGuestPasscode")),
          this.state.textFieldGuestPasscode,
          "LinkAudioConferenceTextFieldGuestPasscode",
          this.handleTextFieldGuestPasscodeOnChange,
          "text",
          18
        )}
      </div>
    );
  };

  getExternalConferenceInfoControls = () => {
    return <ExternalConferenceTable />;
  };

  getLowerNavigationControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { buttonLoader } = portalUserManagement;
    return (
      <div className={classes.displayFlexReverseRow}>
        <TextButton
          disabled={buttonLoader}
          onClick={this.handleNextButtonOnClick}
        >
          <LocalizedText value="next" />
        </TextButton>
        {buttonLoader && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </div>
    );
  };

  //function
  handleAudioConfTypeRadioOnChange = event => {
    this.props.setNewUserModalConferenceType(event.target.value);
    this.props.setNewUserModalUserPrompt(UserPromptLevel.INFO, "");
  };

  getGeneralEntryControl = (
    labelName,
    textFieldValue,
    textFieldId,
    textFieldOnChangeFunc,
    textFieldType,
    textFieldLength
  ) => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.justifyContentSpaceBetween
        )}
      >
        <Typography variant="body1" color="inherit">
          {labelName}
        </Typography>
        <TextField
          id={textFieldId}
          value={textFieldValue}
          onChange={textFieldOnChangeFunc}
          className={classes.textfieldGeneral}
          margin="normal"
          type={textFieldType}
          inputProps={{
            maxLength: textFieldLength
          }}
        />
      </div>
    );
  };

  handleTextFieldConferenceNameOnChange = event => {
    this.setState({ textFieldConferenceName: event.target.value });
  };

  handleTextFieldBillingCodeOnChange = event => {
    this.setState({ textFieldBillingCode: event.target.value });
  };

  handleTextFieldHostPasscodeOnChange = event => {
    this.setState({ textFieldHostPasscode: event.target.value });
  };

  handleTextFieldGuestPasscodeOnChange = event => {
    this.setState({ textFieldGuestPasscode: event.target.value });
  };

  handleNextButtonOnClick = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserAudioConferenceType, selectedExternalConferenceID } =
      portalUserManagement;

    if (newUserAudioConferenceType === "0") {
      //internal conference
      this.linkInternalAudioConference();
    } else if (newUserAudioConferenceType === "1") {
      //external conference
      if (selectedExternalConferenceID != null) {
        this.linkExternalAudioConference();
      } else {
        this.props.setNewUserModalUserPrompt(
          UserPromptLevel.ERROR,
          "selectConferenceToLink"
        );
      }
    }
  };

  linkInternalAudioConference = () => {
    if (!this.validateInternalAudioConferenceUserInput()) {
      return;
    } else {
      const { session, portal } = this.props;
      const { portalSessionId } = session;
      const { portalUserManagement } = portal;
      const { newUserProfileInformation, userTableTabIndex, selectedUser } =
        portalUserManagement;

      this.props.setButtonLoader(true);
      this.props.setNewUserModalUserPrompt(
        UserPromptLevel.INFO,
        "updatingPleaseWait"
      );

      const userObject = {
        ...newUserProfileInformation,
        isAdmin: userTableTabIndex === 0,
        enabled: true
      };
      const persistentSpaceObject = { isInternalConference: true };
      const internalConferenceObject = {
        internalConferenceName: this.state.textFieldConferenceName,
        internalHostPasscode: this.state.textFieldHostPasscode,
        internalGuestPasscode: this.state.textFieldGuestPasscode,
        internalBillingCode: this.state.textFieldBillingCode
      };

      if (this.props.enableUserMode) {
        this.props.activateDisabledUserAccount(
          portalSessionId,
          selectedUser.userID,
          userObject.persistentSpaceName,
          persistentSpaceObject,
          internalConferenceObject
        );
      } else {
        this.props.createNewUserAccount(
          portalSessionId,
          userObject,
          persistentSpaceObject,
          internalConferenceObject
        );
      }
    }
  };

  linkExternalAudioConference = () => {
    const { session, portal } = this.props;
    const { portalSessionId } = session;
    const { portalUserManagement } = portal;
    const {
      newUserProfileInformation,
      userTableTabIndex,
      selectedExternalConferenceID,
      selectedUser
    } = portalUserManagement;

    this.props.setButtonLoader(true);
    this.props.setNewUserModalUserPrompt(
      UserPromptLevel.INFO,
      "updatingPleaseWait"
    );

    const userObject = {
      ...newUserProfileInformation,
      isAdmin: userTableTabIndex === 0,
      enabled: true
    };
    const persistentSpaceObject = {
      isInternalConference: false,
      externalConferenceID: selectedExternalConferenceID
    };

    if (this.props.enableUserMode) {
      this.props.activateDisabledUserAccount(
        portalSessionId,
        selectedUser.userID,
        userObject.persistentSpaceName,
        persistentSpaceObject
      );
    } else {
      this.props.createNewUserAccount(
        portalSessionId,
        userObject,
        persistentSpaceObject
      );
    }
  };

  validateInternalAudioConferenceUserInput = () => {
    if (this.state.textFieldConferenceName.trim() === "") {
      this.props.setNewUserModalUserPrompt(
        UserPromptLevel.ERROR,
        "conferenceNameNotEmpty"
      );
      return false;
    } else if (this.state.textFieldBillingCode.trim() === "") {
      this.props.setNewUserModalUserPrompt(
        UserPromptLevel.ERROR,
        "billingCodeNotEmpty"
      );
      return false;
    } else if (this.state.textFieldHostPasscode.trim() === "") {
      this.props.setNewUserModalUserPrompt(
        UserPromptLevel.ERROR,
        "hostPasscodeNotEmpty"
      );
      return false;
    } else if (this.state.textFieldGuestPasscode.trim() === "") {
      this.props.setNewUserModalUserPrompt(
        UserPromptLevel.ERROR,
        "guestPasscodeNotEmpty"
      );
      return false;
    }

    return true;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.getAudioConferenceTypeRadioControls()}
        {this.getAudioConferenceInfoControls()}
        {this.getLowerNavigationControls()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  setNewUserModalConferenceType: confType =>
    dispatch(setNewUserModalConferenceType(confType)),
  setNewUserModalUserPrompt: (promptLevel, promptContents) =>
    dispatch(setNewUserModalUserPrompt(promptLevel, promptContents)),
  setButtonLoader: enabled => dispatch(setButtonLoader(enabled)),
  createNewUserAccount: (
    sessionID,
    userObject,
    persistentSpaceObject,
    internalConferenceObject
  ) =>
    dispatch(
      createNewUserAccount(
        sessionID,
        userObject,
        persistentSpaceObject,
        internalConferenceObject
      )
    ),
  activateDisabledUserAccount: (
    sessionID,
    userID,
    persistentSpaceName,
    persistentSpaceObject,
    internalConferenceObject
  ) =>
    dispatch(
      activateDisabledUserAccount(
        sessionID,
        userID,
        persistentSpaceName,
        persistentSpaceObject,
        internalConferenceObject
      )
    )
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(LinkAudioConference)
    )
  )
);
