import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Typography, Fade, SvgIcon } from "@material-ui/core";
import classNames from "classnames";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import { CheckCircleOutline } from "@material-ui/icons";
import {
  setNewUserModalStatus,
  setNewUserModalCreateProgress,
  setNewUserModalUserPrompt,
  UserPromptLevel,
  setNewUserModalUserInformation
} from "./actions";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 40px 0px 40px",
    height: "100%",
    flexGrow: 1
  },
  checkIcon: {
    width: "125px",
    height: "125px",
    marginRight: "20px"
  },
  confirmContentContainer: {
    width: "80%",
    paddingLeft: "180px",
    paddingTop: "30px",
    flexGrow: 1
  },
  confirmContentItem: {
    margin: "5px 0px 5px 0px"
  },
  confirmContentItemLabel: {
    width: "50%"
  },
  LowerNavigationContainer: {
    width: "100%"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  displayFlexReverseRow: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  flexGrow: {
    flexGrow: 1
  }
});

class NewUserResult extends Component {
  //controls
  getConfirmHeaderControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.displayFlexRow}>
        <SvgIcon className={classes.checkIcon} color="primary">
          <CheckCircleOutline />
        </SvgIcon>
        <div
          className={classNames(
            classes.displayFlexColumn,
            classes.justifyContentCenter
          )}
        >
          <LocalizedText
            value={
              this.props.enableUserMode
                ? "successfullyActivateUserAccount"
                : "newUserHasBeenCreated"
            }
            variant="h6"
          />
        </div>
      </div>
    );
  };

  getConfirmContentConrol = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { newUserProfileInformation } = portalUserManagement;

    return (
      <div
        className={classNames(
          classes.confirmContentContainer,
          classes.displayFlexColumn
        )}
      >
        <div
          className={classNames(
            classes.confirmContentItem,
            classes.displayFlexRow
          )}
        >
          <LocalizedText
            className={classes.confirmContentItemLabel}
            value="username"
            variant="subtitle1"
          />
          <Typography variant="body1" color="inherit">
            {newUserProfileInformation.username}
          </Typography>
        </div>
        <div
          className={classNames(
            classes.confirmContentItem,
            classes.displayFlexRow
          )}
        >
          <LocalizedText
            className={classes.confirmContentItemLabel}
            value="firstName"
            variant="subtitle1"
          />
          <Typography variant="body1" color="inherit">
            {newUserProfileInformation.firstName}
          </Typography>
        </div>
        <div
          className={classNames(
            classes.confirmContentItem,
            classes.displayFlexRow
          )}
        >
          <LocalizedText
            className={classes.confirmContentItemLabel}
            value="lastName"
            variant="subtitle1"
          />
          <Typography variant="body1" color="inherit">
            {newUserProfileInformation.lastName}
          </Typography>
        </div>
        <div
          className={classNames(
            classes.confirmContentItem,
            classes.displayFlexRow
          )}
        >
          <LocalizedText
            className={classes.confirmContentItemLabel}
            value="email"
            variant="subtitle1"
          />
          <Typography variant="body1" color="inherit">
            {newUserProfileInformation.email}
          </Typography>
        </div>
        <div
          className={classNames(
            classes.confirmContentItem,
            classes.displayFlexRow
          )}
        >
          <LocalizedText
            className={classes.confirmContentItemLabel}
            value="persistentSpace"
            variant="subtitle1"
          />
          <Typography variant="body1" color="inherit">
            {newUserProfileInformation.persistentSpaceName}
          </Typography>
        </div>
      </div>
    );
  };

  getLowerNavigationControls = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexReverseRow,
          classes.LowerNavigationContainer
        )}
      >
        <TextButton onClick={this.handleOKButtonOnClick}>
          <LocalizedText value="ok" />
        </TextButton>
      </div>
    );
  };
  //function
  handleOKButtonOnClick = () => {
    this.props.setNewUserModalStatus(false);
    this.props.setNewUserModalUserPrompt(UserPromptLevel.INFO, "");
    this.props.setNewUserModalUserInformation(undefined);
    this.props.setNewUserModalCreateProgress(1);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Fade in timeout={2000}>
          <div
            className={classNames(
              classes.displayFlexColumn,
              classes.justifyContentCenter,
              classes.alignItemsCenter,
              classes.flexGrow
            )}
          >
            {this.getConfirmHeaderControl()}
            {this.getConfirmContentConrol()}
            {this.getLowerNavigationControls()}
          </div>
        </Fade>
      </div>
    );
  }
}

const mapStateToProps = ({ portal }) => ({ portal });

const mapDispatchToProps = dispatch => ({
  setNewUserModalStatus: isOpen => dispatch(setNewUserModalStatus(isOpen)),
  setNewUserModalUserPrompt: (promptLevel, promptContents) =>
    dispatch(setNewUserModalUserPrompt(promptLevel, promptContents)),
  setNewUserModalUserInformation: userInfoObj =>
    dispatch(setNewUserModalUserInformation(userInfoObj)),
  setNewUserModalCreateProgress: number =>
    dispatch(setNewUserModalCreateProgress(number))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewUserResult))
  )
);
