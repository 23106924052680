import {
  USER_PRIORITY_UPDATE,
  UPDATE_CURRENT_SELECTED_USER,
  USERS_UPDATE,
  USER_LOGIN,
  USER_LOGOUT
} from "../actions/types";

const initialState = {
  groupUserName: "Everyone",
  priorityList: [0],
  currentSelectedUserId: 0,
  currentSelectedUserName: "Everyone"
};

export default function userPriorityReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case USER_PRIORITY_UPDATE:
      newState = { ...state };
      newState.priorityList = newState.priorityList.slice();
      newState.priorityList = changeUsersPriority(
        newState.priorityList,
        action.payload.userId
      );
      return newState;
    case USERS_UPDATE:
      newState = { ...state };
      newState.priorityList = newState.priorityList.slice();
      if (!action.payload.connected) {
        removeUserFromPriorityList(newState.priorityList, action.payload.id);
        //current selected user
        if (newState.currentSelectedUserId === action.payload.id) {
          newState.currentSelectedUserId = initialState.currentSelectedUserId;
          newState.currentSelectedUserName =
            initialState.currentSelectedUserName;
        }
      }
      return newState;
    case UPDATE_CURRENT_SELECTED_USER:
      newState = { ...state };
      newState.currentSelectedUserId = action.payload.userId;
      newState.currentSelectedUserName = action.payload.userName;
      return newState;
    case USER_LOGIN:
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

function changeUsersPriority(userPriorityList, userId) {
  let senderIndex;
  if (userId !== 0 && userPriorityList[1] !== userId) {
    //Find the index for userId if it exists already
    senderIndex = userPriorityList.lastIndexOf(userId);

    //add userId to the 2nd position after id:0 (Everyone to be the 1st element always)
    userPriorityList.splice(1, 0, userId);

    //if userId existed already, remove it from its old index which has been shifted by 1 now
    if (senderIndex !== -1) {
      userPriorityList.splice(senderIndex + 1, 1);
    }
  }
  return userPriorityList;
}

function removeUserFromPriorityList(userPriorityList, userId) {
  var index = userPriorityList.indexOf(userId);
  if (index > -1) {
    userPriorityList.splice(index, 1);
  }
  return userPriorityList;
}
