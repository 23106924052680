import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateMaxDisplayedUsers } from "../../actions";
import LocalizedText from "../reusable/LocalizedText";
import CTXSelect from "../reusable/CTXSelect";
import { InputBase, MenuItem } from "@material-ui/core";
import { getIntl } from "../../utils";
import { useIntl } from "react-intl";
import classNames from "classnames";

const useMaxDisplayedUsersSettingStyles = makeStyles(
  {
    flexContainer: {
      display: "flex",
      flexDirection: "column"
    },
    flexRowBox: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "15px",
      alignItems: "center"
    },
    flexItemLarge: {
      flexGrow: 4,
      width: "95%"
    }
  },
  { name: "MaxDisplayedUsersSetting" }
);

function MaxDisplayedUsersSetting() {
  const classes = useMaxDisplayedUsersSettingStyles();
  const userId = useSelector(state => state.session.userId);
  const maxDisplayedUsers = useSelector(
    state => state.session.maxDisplayedUsers
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  function handleMaxDisplayedUsersChange(event) {
    const updatedMaxDisplayedUsers =
      event.target.value === "all" ? null : event.target.value;
    dispatch(updateMaxDisplayedUsers(userId, updatedMaxDisplayedUsers));
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemLarge}>
          <LocalizedText value="maxDisplayedUsers" variant="subtitle1" />
        </div>
        <div className={classes.flexItemLarge}>
          <CTXSelect
            value={maxDisplayedUsers}
            onChange={handleMaxDisplayedUsersChange}
            input={<InputBase />}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={"all"}>
              {intl.formatMessage(getIntl("all"))}
            </MenuItem>
          </CTXSelect>
        </div>
      </div>
    </div>
  );
}

const useConferencePreferencesStyles = makeStyles(
  theme => ({
    root: {
      padding: "0 20px",
      color: theme.colors.primaryTextColor
    },
    header: {
      marginBottom: "10px"
    },
    headerText: {
      marginBottom: "10px"
    },
    hrDiv: {
      borderTop: `3px solid ${theme.colors.secondaryMainColor}`
    },
    bottomDiv: {
      height: "35px"
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "10px"
    }
  }),
  { name: "ConferencePreferences" }
);

export default function ConferencePreferences({ menu }) {
  const classes = useConferencePreferencesStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <LocalizedText
          value="conferencePreferences"
          variant="h6"
          className={classNames({ [classes.headerText]: menu })}
        />
        <div className={classes.hrDiv} />
      </div>
      <div className={classes.mainContent}>
        <MaxDisplayedUsersSetting />
      </div>
      <div className={classes.bottomDiv} />
    </div>
  );
}
