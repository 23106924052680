import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { IconButton, Paper } from "@material-ui/core";
import SvgIcon from "../../Icons/SvgIcon";
import classnames from "classnames";
import * as requestActions from "../redux/requestActions";
import LocalizedText from "../../reusable/LocalizedText";
import { Appear } from "./transitions";
import Peers from "./Peers";
import { showVideoPopout, hideVideoPopout } from "./";
import {
  hideInternalDrawer,
  setMainRoom,
  resetMainRoom
} from "../../../actions";
import { videoPopoutWindowName } from "./PopoutWindow/component";
import { getIntl, isMobileOrTablet } from "../../../utils";

const headerHeight = "32px";

const styles = theme => ({
  paper: {
    backgroundColor: theme.colors.videoBackgroundColor,
    color: theme.colors.videoBackgroundColor, // Used for the peer container border color
    display: "flex",
    flexDirection: "column",
    borderRadius: "0px"
  },
  paperDrawer: {
    backgroundColor: "transparent",
    color: "transparent", // Used for the peer container border color
    maxWidth: "365px"
  },
  room: {
    height: "100%",
    width: "100%"
  },
  peersContainer: {
    height: "100%"
  },
  header: {
    width: "100%",
    textAlign: "left",
    padding: "16px 8px 16px 16px",
    height: headerHeight,
    lineHeight: "25px",
    fontSize: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerLabel: {
    order: 1,
    color: theme.colors.primaryTextColor
  },
  headerIcons: {
    order: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",

    "& $button": {
      pointerEvents: "auto",
      cursor: "pointer",

      "&.on": {
        backgroundColor: theme.colors.sideBarBackgroundColor
      },

      "&.disabled": {
        pointerEvents: "none",
        opacity: "0.5",
        transitionDuration: "0s"
      }
    }
  },
  button: {},
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`,
    marginBottom: "1px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  }
});

class Room extends React.Component {
  componentDidMount() {
    const { session } = this.props;

    if (session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.props.session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  handlePopoutClick = () => {
    this.props.showVideoPopout();
    this.props.hideInternalDrawer();
  };

  handleCloseWindow = () => {
    const { isDrawer, session } = this.props;
    if (!session.sharingActive) {
      this.props.setMainRoom();
    }
    if (isDrawer) {
      this.props.hideInternalDrawer();
    } else {
      this.props.hideVideoPopout();
    }
  };

  getVideoHeader = () => {
    const { classes, isDrawer, intl } = this.props;
    return (
      <Fragment>
        <div className={classes.header}>
          <LocalizedText
            value="video"
            variant="h6"
            className={classes.headerLabel}
          />
          <div className={classes.headerIcons}>
            {isDrawer && !isMobileOrTablet() && (
              <Fragment>
                <IconButton
                  className={classes.closeHeaderButton}
                  onClick={() => this.handlePopoutClick()}
                  title={intl.formatMessage(getIntl("popoutIntoNewWindow"))}
                >
                  <SvgIcon
                    iconName="popout"
                    color={"active"}
                    className={classes.closeHeaderIcon}
                  />
                </IconButton>
                <IconButton
                  className={classes.closeHeaderButton}
                  onClick={() => this.handleCloseWindow()}
                  title={intl.formatMessage(getIntl("close"))}
                >
                  <SvgIcon
                    iconName="close"
                    color={"active"}
                    className={classes.closeHeaderIcon}
                  />
                </IconButton>
              </Fragment>
            )}
          </div>
        </div>
        <div className={classes.hrDiv} />{" "}
      </Fragment>
    );
  };

  getVideoContainer = () => {
    const { classes, amActiveSpeaker, isDrawer, windowWidth, windowHeight } =
      this.props;
    return (
      <div className={classes.peersContainer}>
        <Peers
          isDrawer={isDrawer}
          amActiveSpeaker={amActiveSpeaker}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        />
      </div>
    );
  };

  render() {
    const { classes, isDrawer, session } = this.props;

    return (
      <Paper
        className={classnames(
          classes.paper,
          isDrawer ? classes.paperDrawer : null
        )}
        style={{ height: "100%", width: "100%" }}
      >
        <Appear duration={300}>
          <div data-component="Room" className={classes.room}>
            {isDrawer && !session.recorder && this.getVideoHeader()}
            {this.getVideoContainer()}
          </div>
        </Appear>
      </Paper>
    );
  }
}

/*Room.propTypes = {
  //roomClient: PropTypes.any.isRequired,
  room: appPropTypes.Room.isRequired,
  me: appPropTypes.Me.isRequired,
  amActiveSpeaker: PropTypes.bool.isRequired,
  onRoomLinkCopy: PropTypes.func.isRequired
};*/

const mapStateToProps = ({ session, room, me }) => {
  return {
    session: session,
    me: me,
    amActiveSpeaker: me.id === room.activeSpeakerId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showVideoPopout: () => dispatch(showVideoPopout(videoPopoutWindowName)),
    hideVideoPopout: () => dispatch(hideVideoPopout()),
    hideInternalDrawer: () => dispatch(hideInternalDrawer()),
    setMainRoom: () => dispatch(setMainRoom()),
    resetMainRoom: () => dispatch(resetMainRoom()),
    onRoomLinkCopy: () => {
      dispatch(
        requestActions.notify({
          text: "Room link copied to the clipboard"
        })
      );
    }
  };
};

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Room))
);
