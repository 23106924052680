import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import PersistentSpaceSettingsCard from "./PersistentSpaceSettingsCard";

const styles = theme => ({
  root: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  }
});

class PersistentSpaceSettingsView extends Component {
  getPersistentSpaceSettings = () => {
    const { session } = this.props;
    const { portalUser } = session;
    const { persistentSpaces } = portalUser;

    if (persistentSpaces != null) {
      return (
        <PersistentSpaceSettingsCard
          userID={portalUser.userIdDB}
          persistentSpace={persistentSpaces}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.root, classes.scrollBar)}>
        {this.getPersistentSpaceSettings()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps)(PersistentSpaceSettingsView))
  )
);
