import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  svg: {
    width: "100%"
  },
  active: {
    fill: theme.colors.activeIconColor
  },
  inactive: {
    fill: theme.colors.inactiveIconColor
  },
  locked: {
    fill: theme.colors.lockedIconColor
  },
  primaryTextColor: {
    fill: theme.colors.primaryTextColor
  },
  primaryMainText: {
    fill: theme.colors.primaryMainTextColor
  },
  primaryBackgroundImageText: {
    fill: theme.colors.primaryBackgroundImageTextColor
  },
  connect: {
    fill: theme.colors.connectIconColor
  },
  disconnect: {
    fill: theme.colors.disconnectIconColor
  },
  hold: {
    fill: theme.colors.holdColor
  },
  sideBar: {
    fill: theme.colors.sideBarBackgroundColor
  }
});

class SvgIcon extends Component {
  render() {
    let color;
    if (this.props.color === "inactive") {
      color = this.props.classes.inactive;
    } else if (this.props.color === "primaryMainText") {
      color = this.props.classes.primaryMainText;
    } else if (this.props.color === "primaryBackgroundImageText") {
      color = this.props.classes.primaryBackgroundImageText;
    } else if (this.props.color === "connect") {
      color = this.props.classes.connect;
    } else if (this.props.color === "disconnect") {
      color = this.props.classes.disconnect;
    } else if (this.props.color === "hold") {
      color = this.props.classes.hold;
    } else if (this.props.color === "sideBar") {
      color = this.props.classes.sideBar;
    } else if (this.props.color === "primaryTextColor") {
      color = this.props.classes.primaryTextColor;
    } else if (this.props.color === "locked") {
      color = this.props.classes.locked;
    } else {
      color = this.props.classes.active;
    }

    let path =
      process.env.PUBLIC_URL +
      "/assets/" +
      this.props.iconName +
      ".svg#" +
      this.props.iconName;

    return (
      <svg
        className={classNames(
          this.props.classes.svg,
          color,
          this.props.className
        )}
        viewBox={
          this.props.viewBox !== "none"
            ? this.props.viewBox || "0 0 1 1"
            : undefined
        }
      >
        <use xlinkHref={path} />
      </svg>
    );
  }
}

export default withStyles(styles)(SvgIcon);
