import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LocalizedText from "../reusable/LocalizedText";
import { IconButton } from "@material-ui/core";
import TextButton from "../reusable/TextButton";
import CTXBasePopper from "../reusable/CTXBasePopper";
import SvgIcon from "../Icons/SvgIcon";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine, isHost } from "../../utils";
import {
  setSharePopperExpansion,
  handleConferenceApiRequest
} from "../../actions";
import { withRoomContext } from "../mediasoup/RoomContext";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  headerText: {
    alignSelf: "flex-start",
    paddingBottom: "10px"
  }
});

class SharePopper extends Component {
  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  state = {
    anchorEl: null
  };

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  componentDidUpdate(prevProps) {
    const { session, users } = this.props;

    if (
      (this.isWaitingForAccess(prevProps.session, prevProps.users) &&
        !this.isWaitingForAccess(session, users)) ||
      (prevProps.session.isSharer && !session.isSharer)
    ) {
      this.props.setSharePopperExpansion(false);
    }
  }

  isWaitingForAccess = (session, users) => {
    return (
      !session.sharingActive &&
      !isHost(session) &&
      !(users[session.id] && users[session.id].presenterMode)
    );
  };

  handleClickPopper = () => {
    const { session, users } = this.props;

    if (
      !session.sharingActive &&
      (window.CtxAppConfigurations.customer === "TCAD" ||
        isHost(session) ||
        (users[session.id] && users[session.id].presenterMode))
    ) {
      this.startScreenShare();
    } else {
      this.props.setSharePopperExpansion(true);
    }
  };

  startScreenShare = () => {
    this.props.roomClientProvider.enableShare();
  };

  stopScreenShare = () => {
    this.props.setSharePopperExpansion(false);

    this.props.roomClientProvider.disableShare();
  };

  getPopperContents = (classes, session) => {
    return session.isSharer
      ? this.displayStopSharing()
      : this.getPopperContentsForEnvironmentReady(classes);
  };

  getPopperContentsForEnvironmentReady = classes => {
    return (
      <div className={classes.content}>
        <LocalizedText
          value="sharingOptions"
          variant="h6"
          className={classes.headerText}
        />
        <LocalizedText value="waitForPermission" inline="true" />
      </div>
    );
  };

  displayStopSharing = () => {
    const { classes } = this.props;

    return (
      <Fragment>
        <LocalizedText
          value="sharingOptions"
          variant="h6"
          className={classes.headerText}
        />
        <TextButton onClick={this.stopScreenShare}>
          <LocalizedText value="stopSharing" />
        </TextButton>
      </Fragment>
    );
  };

  closePopper = () => {
    this.props.setSharePopperExpansion(false);
  };

  render() {
    const { classes, disabled, intl, session, users } = this.props;
    const { anchorEl } = this.state;
    const open = anchorEl != null && this.props.popper.share.expandSharePopper;
    const id = open ? "sharePopper" : null;

    return (
      <Fragment>
        <IconButton
          id="sharePopperButton"
          className={classes.popperIconButton}
          onClick={this.handleClickPopper}
          ref={this.iconRef}
          disabled={disabled}
          title={
            intl.formatMessage(getIntl("screenSharingControl")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled"))
              : session.isSharer && session.sharingActive
              ? intl.formatMessage(getIntl("clickToStopScreenShare"))
              : intl.formatMessage(getIntl("clickToShareYourScreen")))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName={
              !disabled && session.isSharer && session.sharingActive
                ? "stopShare"
                : "share"
            }
            color={
              !disabled
                ? session.isSharer && session.sharingActive
                  ? "disconnect"
                  : window.CtxAppConfigurations.customer === "TCAD" ||
                    isHost(session) ||
                    (users[session.id] && users[session.id].presenterMode)
                  ? "connect"
                  : "active"
                : "inactive"
            }
          />
        </IconButton>

        <CTXBasePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          closePopper={this.closePopper}
        >
          {this.getPopperContents(classes, session)}
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, popper, users }) => ({
  session,
  popper,
  users
});

const mapDispatchToProps = dispatch => ({
  handleConferenceApiRequest: (userId, apiEndpoint) =>
    dispatch(handleConferenceApiRequest(userId, apiEndpoint)),
  setSharePopperExpansion: payload => dispatch(setSharePopperExpansion(payload))
});

export default withStyles(styles)(
  withRouter(
    injectIntl(
      withRoomContext(connect(mapStateToProps, mapDispatchToProps)(SharePopper))
    )
  )
);
