import {
  INTERNAL_DRAWER_HIDE,
  INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST,
  INTERNAL_DRAWER_SHOW_CHAT,
  INTERNAL_DRAWER_SHOW_VIDEO,
  INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES,
  INTERNAL_DRAWER_SHOW_TRANSCRIPT,
  USER_LOGIN,
  USER_LOGOUT
} from "../actions/types";
import { InternalDrawerState } from "../components/layouts/internalDrawerStates";

export default function internalDrawerReducer(
  state = InternalDrawerState.HIDDEN,
  action
) {
  let newState = {};
  switch (action.type) {
    case INTERNAL_DRAWER_HIDE:
      newState = InternalDrawerState.HIDDEN;
      return newState;
    case INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST:
      newState = InternalDrawerState.PARTICIPANT_LIST;
      return newState;
    case INTERNAL_DRAWER_SHOW_CHAT:
      newState = InternalDrawerState.CHAT;
      return newState;
    case INTERNAL_DRAWER_SHOW_VIDEO:
      newState = InternalDrawerState.VIDEO;
      return newState;
    case INTERNAL_DRAWER_SHOW_CONFERENCE_PREFERENCES:
      newState = InternalDrawerState.CONFERENCE_PREFERENCES;
      return newState;
    case INTERNAL_DRAWER_SHOW_TRANSCRIPT:
      newState = InternalDrawerState.TRANSCRIPT;
      return newState;
    case USER_LOGIN:
      switch (action.payload.expandList) {
        case "participant":
          newState = InternalDrawerState.PARTICIPANT_LIST;
          return newState;
        case "chat":
          newState = InternalDrawerState.CHAT;
          return newState;
        case "video":
          newState = InternalDrawerState.VIDEO;
          return newState;
        default:
          newState = InternalDrawerState.HIDDEN;
          return newState;
      }
    case USER_LOGOUT:
      newState = { ...state };
      newState = InternalDrawerState.HIDDEN;
      return newState;
    default:
      return state;
  }
}
