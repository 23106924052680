import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as stateActions from "../redux/stateActions";
import PeerView from "./PeerView";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../../Icons/SvgIcon";
import CTXIconButtonConfirmModal from "../../reusable/CTXIconButtonConfirmModal";
import { deleteUser } from "../../../actions";
import { injectIntl } from "react-intl";
import { getIntl, isHost } from "../../../utils";

const styles = theme => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%"
  },
  indicators: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    right: 0,
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    "& $icon": {
      flex: "0 0 auto",
      margin: "4px",
      marginLeft: 0,
      width: "32px",
      height: "32px",
      backgroundPosition: "center",
      backgroundSize: "75%",
      backgroundRepeat: "no-repeat",
      transitionProperty: "opacity",
      transitionDuration: "0.15s",
      "& +desktop()": {
        opacity: 0.85
      }
    }
  },
  icon: {},
  controls: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  button: {
    pointerEvents: "auto",
    cursor: "pointer",
    "&.on": {
      backgroundColor: theme.colors.sideBarBackgroundColor
    }
  },
  svgIcon: {
    width: "1em"
  }
});

const Peer = props => {
  const {
    classes,
    roomClient,
    peer,
    audioConsumer,
    videoConsumer,
    audioMuted,
    faceDetection,
    onSetStatsPeerId,
    isDrawer,
    merged,
    screenShare,
    host,
    intl,
    sessionID
  } = props;

  if (!peer) {
    return null;
  }

  /*const audioEnabled =
    Boolean(audioConsumer) &&
    !audioConsumer.locallyPaused &&
    !audioConsumer.remotelyPaused;*/

  const videoVisible =
    Boolean(videoConsumer) &&
    !videoConsumer.locallyPaused &&
    !videoConsumer.remotelyPaused;

  let user = merged.find(item => item.id === parseInt(peer.id, 10));
  let connectedToOperator = false;
  let videoPinned = false;
  if (user) {
    if (
      user.connectState === "MuteOperator" ||
      user.connectState === "TalkOperator"
    ) {
      connectedToOperator = true;
    }
    if (user.videoPinned) {
      videoPinned = true;
    }
  }

  return (
    <div data-component="Peer" className={classes.root}>
      <PeerView
        isDrawer={isDrawer}
        peer={peer}
        audioConsumerId={audioConsumer ? audioConsumer.id : null}
        videoConsumerId={videoConsumer ? videoConsumer.id : null}
        audioRtpParameters={audioConsumer ? audioConsumer.rtpParameters : null}
        videoRtpParameters={videoConsumer ? videoConsumer.rtpParameters : null}
        consumerSpatialLayers={
          videoConsumer ? videoConsumer.spatialLayers : null
        }
        consumerTemporalLayers={
          videoConsumer ? videoConsumer.temporalLayers : null
        }
        consumerCurrentSpatialLayer={
          videoConsumer ? videoConsumer.currentSpatialLayer : null
        }
        consumerCurrentTemporalLayer={
          videoConsumer ? videoConsumer.currentTemporalLayer : null
        }
        consumerPreferredSpatialLayer={
          videoConsumer ? videoConsumer.preferredSpatialLayer : null
        }
        consumerPreferredTemporalLayer={
          videoConsumer ? videoConsumer.preferredTemporalLayer : null
        }
        consumerPriority={videoConsumer ? videoConsumer.priority : null}
        audioTrack={audioConsumer ? audioConsumer.track : null}
        videoTrack={videoConsumer ? videoConsumer.track : null}
        audioMuted={audioMuted}
        videoVisible={videoVisible}
        videoMultiLayer={videoConsumer && videoConsumer.type !== "simple"}
        audioCodec={audioConsumer ? audioConsumer.codec : null}
        videoCodec={videoConsumer ? videoConsumer.codec : null}
        audioScore={audioConsumer ? audioConsumer.score : null}
        videoScore={videoConsumer ? videoConsumer.score : null}
        faceDetection={faceDetection}
        onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) => {
          roomClient.setConsumerPreferredLayers(
            videoConsumer.id,
            spatialLayer,
            temporalLayer
          );
        }}
        onChangeVideoPriority={priority => {
          roomClient.setConsumerPriority(videoConsumer.id, priority);
        }}
        onRequestKeyFrame={() => {
          roomClient.requestConsumerKeyFrame(videoConsumer.id);
        }}
        onStatsClick={onSetStatsPeerId}
        screenShare={screenShare}
      />

      {!screenShare && (
        <div className={classes.controls}>
          {window.CtxAppConfigurations.customer === "TCAD" && host && (
            <CTXIconButtonConfirmModal
              svgName="logout"
              svgClass={classes.svgIcon}
              title={intl.formatMessage(getIntl("clickToRemoveUsersWebcam"))}
              label="removeUsersWebcam"
              data={user}
              onConfirm={() => {
                props.deleteUser(sessionID, user.id);
              }}
            />
          )}
          {connectedToOperator && (
            <IconButton className={classes.button} disabled={true}>
              <SvgIcon
                iconName="signalOperator"
                className={classes.svgIcon}
                color={"inactive"}
              />
            </IconButton>
          )}
          {videoPinned && (
            <IconButton className={classes.button} disabled={true}>
              <SvgIcon
                iconName="videoPin"
                color="inactive"
                className={classes.svgIcon}
              />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

Peer.propTypes = {
  /*roomClient: PropTypes.any.isRequired,
  peer: appPropTypes.Peer.isRequired,
  audioConsumer: appPropTypes.Consumer,
  videoConsumer: appPropTypes.Consumer,
  audioMuted: PropTypes.bool,
  faceDetection: PropTypes.bool.isRequired,
  onSetStatsPeerId: PropTypes.func.isRequired*/
};

const mapStateToProps = (state, { id, screenShare }) => {
  if (!id) {
    return {};
  }

  const me = state.me;
  const peer = state.peers[id];
  const consumersArray = peer.consumers.map(
    consumerId => state.consumers[consumerId]
  );
  const audioConsumer = consumersArray.find(
    consumer => consumer.track.kind === "audio"
  );
  const videoConsumer = consumersArray.find(
    consumer =>
      consumer.track.kind === "video" &&
      consumer.screenShare === Boolean(screenShare)
  );

  return {
    peer,
    audioConsumer,
    videoConsumer,
    audioMuted: me.audioMuted,
    faceDetection: state.room.faceDetection,
    merged: state.participants.merged,
    host: isHost(state.session),
    sessionID: state.session.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetStatsPeerId: peerId =>
      dispatch(stateActions.setRoomStatsPeerId(peerId)),
    deleteUser: (sessionID, userID) => dispatch(deleteUser(sessionID, userID))
  };
};

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Peer))
);
