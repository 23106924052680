import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AutoSizer, List } from "react-virtualized";
import classNames from "classnames";
import { getNonce } from "../../utils";

const styles = theme => ({
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-track": {
      background: theme.colors.popoverBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  noOutline: {
    "&:focus": {
      outline: "none"
    }
  }
});

class VirtualizedList extends PureComponent {
  recomputeRowHeights = index => {
    if (this.wrappedRef) {
      this.wrappedRef.recomputeRowHeights(index);
    }
  };

  forceUpdateGrid = () => {
    if (this.wrappedRef) {
      this.wrappedRef.forceUpdateGrid();
    }
  };

  render() {
    const {
      classes,
      rowCount,
      rowHeight,
      rowRenderer,
      deferredMeasurementCache
    } = this.props;

    return (
      <AutoSizer nonce={getNonce()}>
        {({ height, width }) => (
          <List
            style={{ willChange: "auto", overflowY: "overlay" }} //text was blurry in chrome without willChange
            ref={r => (this.wrappedRef = r)}
            height={height}
            width={width}
            rowCount={rowCount}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
            className={classNames(
              classes.scrollBar,
              this.props.noFocusOutline ? classes.noOutline : undefined
            )}
            deferredMeasurementCache={deferredMeasurementCache}
          />
        )}
      </AutoSizer>
    );
  }
}

export default VirtualizedList = withStyles(styles)(VirtualizedList);
