export const QAWindowState = {
  CLOSE: 0,
  OPEN: 1
};

export const SessionState = {
  CLOSED: 0,
  INACTIVE: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3
};

export const ActiveSessionType = {
  NOSESSION: 0,
  QAACTIVE: 1,
  VOTEACTIVE: 2
};

export const QAPartyState = {
  AUDIENCE: "AUDIENCE",
  MODERATOR: "MODERATOR",
  FLOORPARTY: "FLOORPARTY",
  PARTY: "PARTY"
};
