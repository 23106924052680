import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  AudioPopper,
  ConnectPopper,
  MorePopper,
  SharePopper,
  QAPopper,
  VideoPopper,
  SignalOperatorPopper
} from "../poppers";
import {
  ConferenceRecordingIndicator,
  ConferenceQAIndicator
} from "../statusIndicators";
import FullScreenIcon from "../Icons/FullScreenIcon";
import { isVideoEnabled, isCM } from "../../utils";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = theme => ({
  root: {
    gridColumn: "3",
    gridRow: "2",
    msGridColumn: "3",
    msGridRow: "2",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    display: "flex",
    flexDirection: "column",
    zIndex: 1
  },
  conferenceIndicatorContainer: {
    display: "block",
    height: "64px"
  },
  popperButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center"
  },
  transparent: {
    opacity: 0.95
  }
});

//TODO - handle disabled states correctly
class RightBarComponent extends Component {
  render() {
    const {
      classes,
      displayContent,
      displayVideoPopper,
      isHost,
      session,
      morePopperDisplayed
    } = this.props;

    const isConferenceRoomControlConfig = session.conferenceRoomControlConfig;

    return (
      <div
        className={classNames(
          classes.root,
          session.fullScreenMode && classes.transparent
        )}
      >
        {displayContent && (
          <Fragment>
            <div className={classes.conferenceIndicatorContainer}>
              <ConferenceRecordingIndicator />
              <ConferenceQAIndicator />
            </div>
            <div className={classes.popperButtonsContainer}>
              <div>
                {isVideoEnabled(session.videoConfig) && <VideoPopper />}
                {window.CtxAppConfigurations.customer !== "TCAD" && (
                  <>
                    <AudioPopper />
                    <ConnectPopper />
                  </>
                )}
                {navigator.mediaDevices.getDisplayMedia &&
                  (window.CtxAppConfigurations.guestScreenSharingEnabled ||
                    isHost) && (
                    <SharePopper
                      disabled={session.sharingActive && !session.isSharer}
                    />
                  )}
                {window.CtxAppConfigurations.customer !== "TCAD" &&
                  isConferenceRoomControlConfig && (
                    <>
                      {!isCM() && <SignalOperatorPopper />}
                      {!isCM() && !isHost && <QAPopper />}
                      {morePopperDisplayed && isHost && <MorePopper />}
                    </>
                  )}
              </div>
            </div>
            <div className={classes.conferenceIndicatorContainer}>
              <FullScreenIcon />
            </div>
          </Fragment>
        )}
        {displayVideoPopper && isVideoEnabled(session.videoConfig) && (
          <div className={classes.popperButtonsContainer}>
            <VideoPopper />
          </div>
        )}
      </div>
    );
  }
}

function RightBar(props) {
  const morePopperDisplayed = useMediaQuery(
    "(min-width: 1000px) and (min-height: 710px)"
  );

  return (
    <RightBarComponent {...props} morePopperDisplayed={morePopperDisplayed} />
  );
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(connect(mapStateToProps)(RightBar));
