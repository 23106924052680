import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import PortalHeader from "./PortalHeader";
import LicenseCard from "./LicenseCard";
import LicenseModalWindow from "./LicenseModalWindow";
import { getConnectNowLicenses } from "./actions";
import { convertUTCtoLocalTime, getIntl } from "../../utils";
import { injectIntl } from "react-intl";
import LicenseExpiringBanner from "./LicenseExpiringBanner";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    minWidth: "1160px",
    height: "50px",
    flexDirection: "row",
    margin: "10px 20px 10px 20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  mainContentContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    margin: "10px 15px 0px 15px",
    padding: "30px 20px 30px 20px",
    flexWrap: "wrap"
  }
});

class PortalLicense extends Component {
  componentDidMount() {
    const { session } = this.props;
    const { portalSessionId } = session;
    this.props.getConnectNowLicenses(portalSessionId);
  }
  //functions

  getLicenseCardComponents = () => {
    const { portal } = this.props;
    const { license } = portal;
    const { licenseArray } = license;

    let res = [];

    if (licenseArray != null) {
      licenseArray.forEach(licenseElement => {
        res.push(
          <LicenseCard
            key={"licenseCard_" + licenseElement.key}
            title={licenseElement.key}
            licenseValue={licenseElement.value}
            expire={
              licenseElement.expire
                ? convertUTCtoLocalTime(licenseElement.expire)
                : ""
            }
            MAC={licenseElement.mac}
            encryption={licenseElement.encryption}
            valid={licenseElement.valid}
          />
        );
      });
    }

    return res;
  };

  getLicenseModalComponents = () => {
    const { portal, intl } = this.props;
    const { license } = portal;
    const { modalStatus, modalText } = license;

    return (
      <LicenseModalWindow
        open={modalStatus}
        mainText={modalText ? intl.formatMessage(getIntl(modalText)) : ""}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <LicenseExpiringBanner />
        <div className={classes.header}>
          <PortalHeader title={"license"} />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          {this.getLicenseCardComponents()}
          {this.getLicenseModalComponents()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  getConnectNowLicenses: sessionId => dispatch(getConnectNowLicenses(sessionId))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(PortalLicense))
  )
);
