import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import CTXDatePicker from "./CTXDatePicker";
import { IconButton, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { getIntl, getNonce } from "../../utils";
import { injectIntl } from "react-intl";
import { setPortalDownloadReportDialogWindowVisible } from "../../actions";
import LocalizedText from "./../reusable/LocalizedText";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import classNames from "classnames";
import SvgIcon from "../Icons/SvgIcon";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    paddingRight: "10px",
    paddingBottom: "10px",
    "& > div": {
      backgroundColor: theme.colors.popoverBackgroundColor
    },
    "& th": {
      backgroundColor: theme.colors.popoverBackgroundColor
    },
    "& h6": { color: theme.colors.primaryTextColor },
    "& td": {
      color: theme.colors.primaryTextColor,
      border: 0,
      padding: "11px 16px"
    },
    "& span > div:hover": {
      color: theme.colors.primaryTextColor
    },
    "& span > div": {
      color: theme.colors.primaryTextColor
    },
    "& thead > tr > th > span:hover": {
      color: theme.colors.primaryTextColor
    },
    // Increase CSS specificity
    "&&&&& svg": {
      color: theme.colors.primaryTextColor
    },
    "& ::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "& ::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "& ::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "& ::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  rootSelectionEnabled: {
    "& td:first-child": {
      padding: "0"
    }
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "row"
  },
  nativeToolbarContainer: {
    order: 0,
    "& > div > div": {
      overflow: "initial"
    }
  },
  datePickerContainer: {
    order: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center"
  },
  calendarIcon: {
    marginLeft: "10px",
    cursor: "pointer"
  },
  downloadIcon: { width: "48px" },
  verticalDivider: {
    borderLeft: "1px solid",
    height: "50%",
    marginLeft: "2px"
  },
  flexGrow: {
    flexGrow: 1
  }
});

/*
 * Attribute "maxBodyHeight" controls the max height of CTXTable. Type: number or string
 * Attribute "displayDatePickers" controls the visibility of date pickers. Type: boolean
 * Attribute "handleRefreshButtonClick" pass in the function which is executed after clicking refresh button. Type: func
 * Attribute "onChangeDatePickerFrom" pass in the function which is executed when from date picker value changes. Type: func
 * Attribute "onChangeDatePickerTo" pass in the function which is executed when from date picker value changes. Type: func
 * Attribute "enableSelectedRowStyle" controls CTXTable displays selected row style. Type: boolean
 * Attribute "enableDownloadIcon" controls to display download icon. Type: boolean
 * Attribute "enableSearch" controls to display Search component. Type: boolean
 * Attribute "toolbar" controls to display cutomized toolbar component. Type: node
 * Attribute "enableSelection" controls to display selection checkbox each row. Type: boolean
 * Attribute "PortalUserTable". Used for PortalUserTable only. Type: boolean
 * Attribute "flexGrowNativeToolbarContainer". FlexGrow is set to 1 in NativeToolBarContainer if true. Type: boolean
 */

class CTXTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null
    };
    this.tableData = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //unselect row in UserManagement tab when switching Hosts/Admin tabs
    if (
      this.props.PortalUserTable &&
      prevState &&
      prevState.selectedRow &&
      prevState.selectedRow.tableData
    ) {
      if (
        this.tableData &&
        this.tableData.length > prevState.selectedRow.tableData.id
      ) {
        if (
          this.tableData[prevState.selectedRow.tableData.id].userID !==
          prevState.selectedRow.userID
        ) {
          this.setState({ selectedRow: null });
        }
      } else {
        this.setState({ selectedRow: null });
      }
    }
  }

  getTableOptions = () => {
    let res = {};

    //Set max & min table height
    res.maxBodyHeight =
      this.props.maxBodyHeight === undefined
        ? "320px"
        : this.props.maxBodyHeight;
    res.minBodyHeight = "300px";

    res.headerStyle = {
      fontSize: "0.75rem"
    };

    res.rowStyle = rowData => {
      return {
        backgroundColor:
          //Enable row selection styling
          this.props.enableSelectedRowStyle &&
          this.state.selectedRow &&
          this.state.selectedRow.tableData.id === rowData.tableData.id
            ? this.props.theme.primaryBackgroundColor
            : undefined
      };
    };

    res.cellStyle = {
      fontSize: "0.8125rem"
    };

    //Set 5 items per page
    res.pageSizeOptions = [5];

    //enableSearch
    if (this.props.enableSearch != null) {
      res.search = this.props.enableSearch;
    }

    //enable Selection checkbox
    if (this.props.enableSelection) {
      res.selection = this.props.enableSelection;
      res.showTextRowsSelected = false;
    }

    res.thirdSortClick = false;

    res.cspNonce = getNonce();

    return res;
  };

  handleRefreshButtonClick = () => {
    this.props.handleRefreshButtonClick();
  };

  handleOnRowClick = (evt, selectedRow) => {
    this.setState({ selectedRow });

    if (this.props.onRowClick) {
      this.props.onRowClick(evt, selectedRow);
    }
  };

  getDivider = () => {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.verticalDivider} />
        <div className={classes.verticalDivider} />
      </Fragment>
    );
  };

  getToolbar = mTableToolbarProps => {
    const { classes, intl } = this.props;
    const { translations } = this.props.languages;

    if (this.props.displayDatePickers) {
      const downloadCSVComponent = (
        <div className={classes.downloadIcon}>
          <IconButton
            onClick={() =>
              this.props.setDownloadReportDialogWindowVisible(true)
            }
            title={intl.formatMessage(getIntl("exportReportCSV"))}
          >
            <SvgIcon iconName="download" color="primaryTextColor" />
          </IconButton>
        </div>
      );

      return (
        <div className={classes.toolbarContainer}>
          <div
            className={classNames(
              classes.nativeToolbarContainer,
              this.props.flexGrowNativeToolbarContainer
                ? classes.flexGrow
                : undefined
            )}
          >
            <MTableToolbar {...mTableToolbarProps} />
          </div>
          <div className={classes.datePickerContainer}>
            {this.props.enableDownloadIcon && downloadCSVComponent}
            {this.props.enableDownloadIcon && this.getDivider()}
            <IconButton onClick={() => this.props.handleRefreshButtonClick()}>
              <RefreshIcon />
            </IconButton>
            <CTXDatePicker
              id="CTXTableDatePickTo"
              label={translations.to}
              value={this.props.CTXTableDatePickToValue}
              onChange={this.props.onChangeDatePickerTo}
              minDate={this.props.CTXTableDatePickFromValue}
              underline
            />
            <Icon
              className={classes.calendarIcon}
              onClick={() => {
                document
                  .getElementsByName("CTXTableDatePickTo")[0]
                  .nextElementSibling.focus();
              }}
            >
              <CalendarToday />
            </Icon>
            <CTXDatePicker
              id="CTXTableDatePickFrom"
              label={translations.from}
              value={this.props.CTXTableDatePickFromValue}
              onChange={this.props.onChangeDatePickerFrom}
              underline
            />
            <Icon
              className={classes.calendarIcon}
              onClick={() => {
                document
                  .getElementsByName("CTXTableDatePickFrom")[0]
                  .nextElementSibling.focus();
              }}
            >
              <CalendarToday />
            </Icon>
            <LocalizedText value="filterBy" />
          </div>
        </div>
      );
    } else if (this.props.toolbar) {
      return (
        <div className={classes.toolbarContainer}>
          <div
            className={classNames(
              classes.nativeToolbarContainer,
              this.props.flexGrowNativeToolbarContainer
                ? classes.flexGrow
                : undefined
            )}
          >
            <MTableToolbar {...mTableToolbarProps} />
          </div>
          {this.props.toolbar}
        </div>
      );
    } else {
      return (
        <div className={classes.toolbarContainer}>
          <div
            className={classNames(
              classes.nativeToolbarContainer,
              this.props.flexGrowNativeToolbarContainer
                ? classes.flexGrow
                : undefined
            )}
          >
            <MTableToolbar {...mTableToolbarProps} />
          </div>
        </div>
      );
    }
  };

  // alter material-table's sort to do a localeCompare on strings to ignore case
  newSort(a, b) {
    if (a == null) {
      if (b == null) {
        return 0;
      }
      return -1;
    }
    if (b == null) {
      return 1;
    }
    if (typeof a === "string") {
      return a.localeCompare(b);
    }
    return a < b ? -1 : a > b ? 1 : 0;
  }

  render() {
    const { classes } = this.props;
    const tableOptions = this.getTableOptions();
    const columns = [...this.props.columns];
    this.tableData = [...this.props.data];

    for (const column of columns) {
      if (!column.customSort) {
        column.customSort = (object1, object2) =>
          this.newSort(object1[column.field], object2[column.field]);
      }

      if (column.defaultSort) {
        this.tableData.sort(column.customSort);
      }
    }

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootSelectionEnabled]: this.props.enableSelection
        })}
      >
        <MaterialTable
          icons={{
            Check: Check,
            FirstPage: FirstPage,
            LastPage: LastPage,
            NextPage: ChevronRight,
            PreviousPage: ChevronLeft,
            ResetSearch: Clear,
            Search: Search,
            SortArrow: ArrowDownward,
            ThirdStateCheck: Remove
          }}
          columns={columns}
          data={this.tableData}
          title={this.props.title}
          onRowClick={this.handleOnRowClick}
          options={tableOptions}
          handleRefreshButtonClick={this.props.handleRefreshButtonClick}
          components={{
            Toolbar: props => this.getToolbar(props)
          }}
          acions={this.props.actions}
          onSelectionChange={rows => {
            if (this.props.onSelectionChange) {
              this.props.onSelectionChange(rows);
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ languages, theme }) => ({
  languages,
  theme
});

const mapDispatchToProps = dispatch => ({
  setDownloadReportDialogWindowVisible: isVisible =>
    dispatch(setPortalDownloadReportDialogWindowVisible(isVisible))
});

export default withTheme(
  injectIntl(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CTXTable))
  )
);
