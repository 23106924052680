import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { getIntl } from "../../utils";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PortalLogo from "../Icons/PortalLogo";
import PoweredByCpx from "../Icons/PoweredByCpx";
import TextField from "@material-ui/core/TextField";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import { loginPortalUser } from "../../actions";
import { PortalLoginErrors } from "../../errors";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
    color: theme.colors.primaryBackgroundImageTextColor
  },
  logo: {
    maxHeight: "200px",
    width: "330px",
    objectFit: "contain",
    marginBottom: "20px"
  },
  textField: {
    width: "60%px",
    height: "49px"
  },
  signInButton: {
    marginTop: "30px"
  },
  requiredLabel: {
    alignSelf: "flex-end"
  },
  compunetix: {
    position: "fixed",
    bottom: "2em",
    right: "6em",
    opacity: 100
  },
  compunetixDimension: {
    height: "20px"
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "20px",
    color: theme.palette.error.main,
    marginTop: "5px",
    marginBottom: "5px"
  },
  inputColor: {
    color: theme.colors.primaryBackgroundImageTextColor
  },
  error: {
    "&:after": {
      borderBottom: "1px solid " + theme.colors.errorMainColor
    }
  },
  underlineColor: {
    "&:before": {
      borderBottomColor: theme.colors.primaryBackgroundImageTextColor
    }
  },
  underlineColorFocused: {
    "&:after": {
      borderBottomColor: theme.colors.primaryBackgroundImageTextColor
    }
  }
});

class PortalLoginForm extends Component {
  state = {
    username: undefined,
    password: undefined
  };

  handleUsernameChange = event => {
    this.setState({
      username: event.target.value
    });
  };

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value
    });
  };

  handleButtonClick = (username, password) => {
    const { loginPortalUser } = this.props;
    loginPortalUser(username, password, sessionId =>
      this.handleResponse(sessionId)
    );
  };

  handleResponse = sessionId => {
    const { match, history } = this.props;
    if (sessionId !== undefined) {
      match.params.vi = sessionId;
      history.replace("/portal?u=" + sessionId);
    }
  };

  renderError = portalError => {
    if (!portalError) {
      return null;
    }
    switch (portalError) {
      case PortalLoginErrors.INVALID_LOGIN:
        return (
          <Fragment>
            <LocalizedText value="invalidLoginCredentials" />
            <span>&nbsp;</span>
            <LocalizedText value="pleaseTryAgain" />
          </Fragment>
        );
      case PortalLoginErrors.SERVER_ERROR:
      default:
        return (
          <Fragment>
            <LocalizedText value="loginUnknown" />
            <span>&nbsp;</span>
            <LocalizedText value="pleaseTryAgain" />
          </Fragment>
        );
    }
  };

  handleTextFieldKeyDown = event => {
    if (event.keyCode === 13) {
      const { username, password } = this.state;
      if (
        username != null &&
        username.trim().length !== 0 &&
        password != null &&
        password.length !== 0
      ) {
        this.handleButtonClick(username, password);
      }
    }
  };

  render() {
    const { classes, intl, session } = this.props;
    const { username, password } = this.state;
    const errorComponent = this.renderError(session.portalError);

    return (
      <div className={classes.root}>
        <PortalLogo classes={{ root: classes.logo }} logoType="body" />
        <TextField
          id="portalUsername"
          label={intl.formatMessage(getIntl("username"))}
          onChange={this.handleUsernameChange}
          onKeyDown={this.handleTextFieldKeyDown}
          className={classes.textField}
          margin="dense"
          required
          fullWidth
          InputProps={{
            classes: {
              input: classes.inputColor,
              error: classes.error,
              underline: classes.underlineColor,
              focused: classes.underlineColorFocused
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputColor
            }
          }}
        />
        <TextField
          id="portalPassword"
          label={intl.formatMessage(getIntl("password"))}
          onChange={this.handlePasswordChange}
          onKeyDown={this.handleTextFieldKeyDown}
          className={classes.textField}
          type="password"
          margin="dense"
          required
          fullWidth
          InputProps={{
            classes: {
              input: classes.inputColor,
              error: classes.error,
              underline: classes.underlineColor,
              focused: classes.underlineColorFocused
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputColor
            }
          }}
        />
        <LocalizedText
          className={classes.requiredLabel}
          value="requiredFields"
        />
        <div className={classes.errorContainer}>{errorComponent}</div>
        <TextButton
          onClick={() => this.handleButtonClick(username, password)}
          className={classes.signInButton}
        >
          <LocalizedText value="signIn" />
        </TextButton>
        <div className={classes.compunetix}>
          <PoweredByCpx classes={{ root: classes.compunetixDimension }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  loginPortalUser: (username, password, callback) =>
    dispatch(loginPortalUser(username, password)).then(sessionId =>
      callback(sessionId)
    )
});

export default withTheme(
  withStyles(styles)(
    withRouter(
      injectIntl(connect(mapStateToProps, mapDispatchToProps)(PortalLoginForm))
    )
  )
);
