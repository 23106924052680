import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "../Icons/SvgIcon";

const styles = theme => ({
  rootBackground: {
    background: theme.colors.sideBarBackgroundColor
  },
  rootPadding: {
    padding: "1px 40px 1px 40px"
  },
  textColor: {
    color: theme.colors.talkingColor
  },
  primaryMainTextColor: {
    color: theme.colors.primaryMainTextColor
  },
  lowerBorderRadius: {
    borderRadius: "0px 0px 20px 20px"
  },
  fullBorderRadius: {
    borderRadius: "5px 5px 5px 5px"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  volumeUpIcon: {
    width: "1em",
    height: "1.25em",
    marginRight: "5px"
  }
});

class ParticipantTalkingIndicator extends Component {
  getTalkerComponent = (partyName, index) => {
    const { classes } = this.props;

    return (
      <div className={classes.displayFlexRow} key={"talker" + index}>
        <SvgIcon
          iconName="volumeUp"
          color={
            this.props.primaryMainTextColor ? "primaryMainText" : "connect"
          }
          className={classes.volumeUpIcon}
        />
        <Typography
          noWrap
          variant="body2"
          className={
            this.props.primaryMainTextColor
              ? classes.primaryMainTextColor
              : classes.textColor
          }
        >
          {partyName}
        </Typography>
      </div>
    );
  };

  getTalkerArrayComponent = partyNameArray => {
    let res = [];

    partyNameArray.forEach((partyName, index) => {
      res.push(this.getTalkerComponent(partyName, index));
    });

    return res;
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classNames(
          this.props.noBackground ? undefined : classes.rootBackground,
          this.props.noPadding ? undefined : classes.rootPadding,
          this.props.primaryMainTextColor
            ? classes.primaryMainTextColor
            : classes.textColor,
          classes.displayFlexColumn,
          this.props.fullBorderRadius
            ? classes.fullBorderRadius
            : classes.lowerBorderRadius
        )}
      >
        {Array.isArray(this.props.partyName)
          ? this.getTalkerArrayComponent(this.props.partyName)
          : this.getTalkerComponent(this.props.partyName, 0)}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps)(ParticipantTalkingIndicator))
);
