import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import {
  NotificationLevel,
  SnackbarNotification,
  NotificationWindowState
} from "../notification";

class NotificationWindow extends Component {
  overrideHandleClose = () => {
    this.props.resetThemeUpdateResult();
  };

  getNotificationVariant = notificationLevel => {
    switch (notificationLevel) {
      case NotificationLevel.SUCCESS:
        return "success";
      case NotificationLevel.ERROR:
        return "error";
      case NotificationLevel.INFO:
        return "info";
      case NotificationLevel.WARNING:
        return "warning";
      default:
        return "";
    }
  };

  render() {
    const { notification } = this.props;
    const notificationWindow =
      notification.windowStatus === NotificationWindowState.OPEN ? (
        <SnackbarNotification
          variant={this.getNotificationVariant(notification.notificationLevel)}
        />
      ) : undefined;

    return <div>{notificationWindow}</div>;
  }
}

const mapStateToProps = ({ notification }) => ({ notification });

const mapDispatchToProps = dispatch => ({});

export default withTheme(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationWindow))
);
