import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import AppLogo from "../Icons/AppLogo";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
    color: theme.colors.primaryBackgroundImageTextColor
  },
  logo: {
    maxHeight: "200px",
    width: "100%",
    objectFit: "contain",
    marginBottom: "20px"
  },
  progress: {
    width: "100%"
  }
});

export default withStyles(styles)(
  class Loading extends Component {
    render() {
      const { classes } = this.props;

      return (
        <div className={classes.root}>
          <LinearProgress className={classes.progress} color="secondary" />
          <AppLogo classes={{ root: classes.logo }} logoType="body" />
          <LinearProgress
            className={classes.progress}
            color="secondary"
            variant="query"
          />
        </div>
      );
    }
  }
);
