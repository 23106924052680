import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import { IconButton } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import {
  setQAPopperOpenState,
  setQAPopperContents,
  setQAWindowStatus
} from "../QA/actions";
import { isAudioConnected, getIntl, TitleNewLine } from "../../utils";
import { QAPartyState } from "../QA/types";
import { isQAActive } from "../../utils";
import { QAWindowPopoutState } from "../QA";
import Logger from "../../Logger";

const logger = new Logger("QAPopper");

const styles = theme => ({
  ...theme.style.rightBarPopper,
  layout: {
    ...theme.style.rightBarPopper.layout,
    width: "100%",
    justifyContent: "center"
  },
  qaStateNotification: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  centerAlignItems: {
    alignItems: "center"
  },
  buttonContainer: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  iconButton: {
    width: "64px"
  },
  keypadPrompt: {
    height: "92px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    maxWidth: "200px"
  },
  alignSelfFlexStart: {
    alignSelf: "flex-start"
  }
});

export const QAPopperContents = {
  DEFAULT: 0,
  AUDIO_QA_START: 1,
  AUDIO_QA_END: 2,
  WEB_QA_START: 3,
  WEB_QA_END: 4,
  CONNECT_AUDIO_FIRST: 5,
  HANDS_UP: 6,
  HANDS_DOWN: 7,
  TEXT_HANDS_UP: 8,
  TEXT_HANDS_DOWN: 9,
  ON_PODIUM: 10,
  ALREADY_TALK_LISTEN: 11,
  TRANSIT: 100
};

class QAPopper extends Component {
  state = {
    anchorEl: null
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  componentDidUpdate() {
    const { qa, session } = this.props;
    const { parties } = qa;
    const { partyID } = session;
    let audioConnected = isAudioConnected(session);

    if (audioConnected && parties.has(partyID)) {
      if (
        parties.get(partyID) === QAPartyState.FLOORPARTY &&
        qa.popperContents !== QAPopperContents.ON_PODIUM
      ) {
        this.props.setQAPopperContents(QAPopperContents.ON_PODIUM);
      } else if (
        parties.get(partyID) !== QAPartyState.FLOORPARTY &&
        qa.popperContents === QAPopperContents.ON_PODIUM
      ) {
        this.props.setQAPopperContents(QAPopperContents.TRANSIT);
      }
    }
  }

  //controls
  getRaiseHandButtonControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={this.handRaiseHandButtonOnClick}
          title={
            intl.formatMessage(getIntl("joinAudioQA")) +
            TitleNewLine +
            intl.formatMessage(getIntl("raiseHandToAsk"))
          }
        >
          <SvgIcon iconName="raiseHand" color="active" />
        </IconButton>
        <div>
          <LocalizedText value="raiseHand" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getLowerHandButtonControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={this.handLowerHandButtonOnClick}
          title={
            intl.formatMessage(getIntl("exitAudioQA")) +
            TitleNewLine +
            intl.formatMessage(getIntl("lowerHandToExit"))
          }
        >
          <SvgIcon iconName="lowerHand" color="active" />
        </IconButton>
        <div>
          <LocalizedText value="lowerHand" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getWebQAControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={this.handleWebQAAskQuestionButtonOnClick}
          title={
            intl.formatMessage(getIntl("openWebQAWindow")) +
            TitleNewLine +
            intl.formatMessage(getIntl("typeQuestionWebQA"))
          }
        >
          <SvgIcon iconName="qaWeb" color="active" />
        </IconButton>
        <div>
          <LocalizedText value="webQA" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getAudioQAStartNotification = () => {
    const { classes } = this.props;
    return (
      <div className={classes.qaStateNotification}>
        <LocalizedText value="audioQASessionHasStarted" variant="subtitle1" />
      </div>
    );
  };

  getAudioQAEndNotification = () => {
    const { classes } = this.props;
    return (
      <div className={classes.qaStateNotification}>
        <LocalizedText value="audioQASessionHasEnded" variant="subtitle1" />
      </div>
    );
  };

  getTextRaiseHandControls = () => {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.keypadPrompt)}>
        <LocalizedText value="joinQAUsingKeypad" variant="subtitle1" />
      </div>
    );
  };

  getTextLowerHandControls = () => {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.keypadPrompt)}>
        <LocalizedText value="exitQAUsingKeypad" variant="subtitle1" />
      </div>
    );
  };

  getConnectAudioFirstControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={this.handleConnectAudioPopperClick}
          title={
            intl.formatMessage(getIntl("noAudioDetected")) +
            TitleNewLine +
            intl.formatMessage(getIntl("clickToConnectYourAudio"))
          }
        >
          <SvgIcon iconName="connectCall" color="active" />
        </IconButton>
        <div>
          <LocalizedText value="call" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getOnPodiumControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          disabled
          title={intl.formatMessage(getIntl("nowOnPodium"))}
          style={{ pointerEvents: "auto" }}
        >
          <SvgIcon iconName="qaPodium" color="inactive" />
        </IconButton>
        <div>
          <LocalizedText value="podium" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getWebQAStartNotification = () => {
    const { classes } = this.props;
    return (
      <div className={classes.qaStateNotification}>
        <LocalizedText value="webQASessionHasStarted" variant="subtitle1" />
      </div>
    );
  };

  getWebQAEndNotification = () => {
    const { classes } = this.props;
    return (
      <div className={classes.qaStateNotification}>
        <LocalizedText value="webQASessionHasEnded" variant="subtitle1" />
      </div>
    );
  };

  getAlreadyTalkListenControls = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          disabled
          title={intl.formatMessage(getIntl("alreadyTalkListen"))}
          style={{ pointerEvents: "auto" }}
        >
          <SvgIcon iconName="audioUnmute" color="inactive" />
        </IconButton>
        <div>
          <LocalizedText value="TalkListen" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getAudioTransitStateControl = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.iconButton}
          title={intl.formatMessage(getIntl("OnHold"))}
          disabled
          style={{ pointerEvents: "auto" }}
        >
          <SvgIcon iconName="hold" color="inactive" />
        </IconButton>
        <div>
          <LocalizedText value="OnHold" variant="subtitle1" />
        </div>
      </div>
    );
  };

  getPopperContentsControl = () => {
    const { qa, classes } = this.props;
    const { popperContents } = qa;
    let res;

    switch (popperContents) {
      case QAPopperContents.AUDIO_QA_START:
        res = this.getAudioQAStartNotification();
        break;
      case QAPopperContents.AUDIO_QA_END:
        res = this.getAudioQAEndNotification();
        break;
      case QAPopperContents.WEB_QA_START:
        res = this.getWebQAStartNotification();
        break;
      case QAPopperContents.WEB_QA_END:
        res = this.getWebQAEndNotification();
        break;
      default:
        res = (
          <div
            className={classNames(
              classes.displayFlexColumn,
              classes.centerAlignItems
            )}
          >
            {this.getPopperHeaderControl()}
            <div className={classes.displayFlexRow}>
              {this.getAudioQAPopperContentsControl()}
              {this.getWebQAPopperContentsControl()}
            </div>
          </div>
        );
    }

    return res;
  };

  getAudioQAPopperContentsControl = () => {
    const audioQAPopperContents = this.calculateAudioQAPopperContents();
    let res = undefined;

    switch (audioQAPopperContents) {
      case QAPopperContents.ON_PODIUM:
        res = this.getOnPodiumControls();
        break;
      case QAPopperContents.HANDS_DOWN:
        res = this.getLowerHandButtonControls();
        break;
      case QAPopperContents.HANDS_UP:
        res = this.getRaiseHandButtonControls();
        break;
      case QAPopperContents.TEXT_HANDS_DOWN:
        res = this.getTextLowerHandControls();
        break;
      case QAPopperContents.TEXT_HANDS_UP:
        res = this.getTextRaiseHandControls();
        break;
      case QAPopperContents.ALREADY_TALK_LISTEN:
        res = this.getAlreadyTalkListenControls();
        break;
      case QAPopperContents.CONNECT_AUDIO_FIRST:
        res = this.getConnectAudioFirstControls();
        break;
      case QAPopperContents.TRANSIT:
        res = this.getAudioTransitStateControl();
        break;
      default:
    }

    return res;
  };

  getWebQAPopperContentsControl = () => {
    const { qa } = this.props;
    const { dataQAState } = qa;

    if (dataQAState) {
      return this.getWebQAControls();
    }
  };

  getPopperHeaderControl = () => {
    const { classes } = this.props;
    return (
      <LocalizedText
        value="askQuestion"
        variant="h6"
        className={classes.alignSelfFlexStart}
      />
    );
  };

  //functions
  closePopper = () => {
    this.props.setQAPopperOpenState(false);
  };

  handleQAButtonOnClick = () => {
    let audioQAContents = this.calculateAudioQAPopperContents();
    this.props.setQAPopperContents(audioQAContents);
    this.props.setQAPopperOpenState(true);
  };

  calculateAudioQAPopperContents = () => {
    const { qa, session, conference } = this.props;
    const audioQAInProgress = isQAActive(conference);
    let res = undefined;

    if (audioQAInProgress) {
      //only audio QA is active
      const audioConnected = isAudioConnected(session);
      const { callState } = session;

      if (audioConnected) {
        const { parties } = qa;
        const { partyID } = session;

        if (parties.get(partyID) === QAPartyState.FLOORPARTY) {
          res = QAPopperContents.ON_PODIUM;
        } else if (
          parties.has(partyID) &&
          parties.get(partyID) === QAPartyState.PARTY
        ) {
          if (session.isWebRTCCall) {
            res = QAPopperContents.HANDS_DOWN;
          } else {
            res = QAPopperContents.TEXT_HANDS_DOWN;
          }
        } else {
          if (callState != null && callState === "Monitor") {
            //only party in monitor mode can join audio QA queue
            if (session.isWebRTCCall) {
              res = QAPopperContents.HANDS_UP;
            } else {
              res = QAPopperContents.TEXT_HANDS_UP;
            }
          } else if (callState != null && callState === "TalkListen") {
            res = QAPopperContents.ALREADY_TALK_LISTEN;
          } else {
            //Transit state
            res = QAPopperContents.TRANSIT;
          }
        }
      } else {
        res = QAPopperContents.CONNECT_AUDIO_FIRST;
      }
    }

    return res;
  };

  handRaiseHandButtonOnClick = () => {
    if (window.webRTCSession) {
      window.webRTCSession.sendDTMF("*1");
    } else {
      logger.error("webRTCSession is NOT found!");
    }
  };

  handLowerHandButtonOnClick = () => {
    if (window.webRTCSession) {
      window.webRTCSession.sendDTMF("*2");
    } else {
      logger.error("webRTCSession is NOT found!");
    }
  };

  handleConnectAudioPopperClick = () => {
    this.closePopper();
    document.getElementById("connectPopperButton").click();
  };

  handleWebQAAskQuestionButtonOnClick = () => {
    const { qaPopoutWindow } = this.props;
    const { windowStatus } = qaPopoutWindow;

    if (qaPopoutWindow.windowName) {
      window.open("", qaPopoutWindow.windowName);
    } else {
      if (windowStatus === QAWindowPopoutState.CLOSE) {
        this.props.setQAWindowStatus(true);
      }
    }

    this.closePopper();
  };

  render() {
    const { classes, qa, intl, conference } = this.props;
    const { anchorEl } = this.state;
    const { popperContents, dataQAState } = qa;
    const open = anchorEl != null && qa.popperOpen;

    const audioQAInProgress = isQAActive(conference);

    const disabled =
      popperContents === QAPopperContents.DEFAULT ||
      (!audioQAInProgress && !dataQAState);

    let svgColor;

    if (disabled) {
      svgColor = "inactive";
    } else if (popperContents === QAPopperContents.ON_PODIUM) {
      svgColor = "connect";
    } else {
      svgColor = "active";
    }

    return (
      <Fragment>
        <IconButton
          id="qaPopperButton"
          className={classes.popperIconButton}
          ref={this.iconRef}
          onClick={this.handleQAButtonOnClick}
          disabled={disabled}
          title={intl.formatMessage(getIntl("qaControl"))}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon iconName="qa" color={svgColor} />
        </IconButton>
        <CTXBasePopper
          id="qaPopper"
          open={open}
          anchorEl={anchorEl}
          closePopper={this.closePopper}
          className={classes.layout}
        >
          {this.getPopperContentsControl()}
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ qa, session, conference, qaPopoutWindow }) => ({
  qa,
  session,
  conference,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAPopperOpenState: isOpen => dispatch(setQAPopperOpenState(isOpen)),
  setQAPopperContents: contents => dispatch(setQAPopperContents(contents)),
  setQAWindowStatus: isVisible => dispatch(setQAWindowStatus(isVisible))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(QAPopper))
  )
);
