import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { LoginForm } from "../forms";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";
import {
  getUrlParam,
  redirectUnsupportedBrowsers,
  isCM,
  isVideoEnabled
} from "../../utils";

import {
  setCMLoggedIn,
  setAudioOff,
  setConnectPopperExpansion
} from "../../actions";

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  }
});

class LoginPage extends Component {
  constructor(props) {
    super(props);

    redirectUnsupportedBrowsers();
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (
      isCM() &&
      this.props.session.mergedCallState === "connected" &&
      this.props.session.cmLoggedIn === 0 &&
      this.props.session.videoConfig != null &&
      !isVideoEnabled(this.props.session.videoConfig)
    ) {
      // User dialed into audio only conference.
      // Set cmloggedIn to load the mainpage
      this.props.setCMLoggedIn(1);
      this.props.setAudioOff();
      this.props.setConnectPopperExpansion(false);
    }
  }

  adjustUrl = userId => {
    const { session, history } = this.props;
    if (userId !== undefined) {
      if (!session.vetted) {
        history.push("/waiting?vi=" + userId);
      } else {
        history.push("/main?vi=" + userId);
      }
    }
  };

  getLoginForm = () => {
    if (!isCM() || this.props.session.videoConfig != null) {
      return (
        <LoginForm
          onSuccess={userId => this.adjustUrl(userId)}
          passcode={getUrlParam("passcode")}
          username={getUrlParam("username")}
          userDefined={getUrlParam("udf")}
          userDefined2={getUrlParam("udf2")}
          userDefined3={getUrlParam("udf3")}
          userDefined4={getUrlParam("udf4")}
          agent={this.props.agent}
          recorder={this.props.recorder}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    const component = this.getLoginForm();

    return (
      <Fragment>
        <CssBaseline />
        <Header displayLogout={isCM()} />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar />
          <MainContent component={component} />
          <RightBar />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = {
  setCMLoggedIn,
  setAudioOff,
  setConnectPopperExpansion
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
);
