import { VIDEO_POPOUT_SHOW, VIDEO_POPOUT_HIDE } from "./actions";
import { VideoPopoutState } from "../types";
import { USER_LOGOUT } from "../../../../actions/types";

export default function videoPopoutWindowReducer(
  state = {
    windowStatus: VideoPopoutState.CLOSE,
    windowName: undefined
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case VIDEO_POPOUT_SHOW:
      newState = {
        ...state,
        windowStatus: VideoPopoutState.OPEN,
        windowName: action.payload
      };
      return newState;
    case USER_LOGOUT:
    case VIDEO_POPOUT_HIDE:
      newState = {
        ...state,
        windowStatus: VideoPopoutState.CLOSE,
        windowName: undefined
      };
      return newState;
    default:
      return state;
  }
}
