import { THEME_UPDATE, CONFERENCE_STATE_UPDATE } from "../actions/types";

const initialState = {
  primaryMainColor: window.CtxThemeConfigurations.color1,
  primaryTextColor: window.CtxThemeConfigurations.color2,
  primaryMainTextColor: window.CtxThemeConfigurations.color3,
  primaryBackgroundImageTextColor: window.CtxThemeConfigurations.color4,
  primaryBackgroundColor: "#333333",
  sideBarBackgroundColor: "#000000",
  activeIconColor: "#ffffff",
  secondaryTextColor: "#686868",
  popoverBackgroundColor: "#141414",
  videoBackgroundColor: "#171717",
  holdColor: "#e9b610",
  connectIconColor: "#a9e910",
  talkingColor: "#a9e910",
  errorMainColor: "#e90303",
  errorTextColor: "#ffffff",
  disconnectIconColor: "#e90303",
  inactiveIconColor: "#686868",
  inactiveButtonTextColor: "#cccccc",
  secondaryMainColor: "#686868",
  telephoneInputBackgroundColor: "#ffffff",
  telephoneInputTextColor: "#000000",
  opacity: "0.9",
  portalLeftBarMouseHoverColor: "#444",
  notificationSuccessColor: "#43a047",
  notificationErrorColor: "#d32f2f",
  notificationWarningColor: "#ffa000",
  notificationInfoColor: "#1976d2",
  lockedIconColor: "#e90303"
};

export default function themeReducer(state, action) {
  if (state == null) {
    state = { ...initialState };
    if (
      window.CtxThemeConfigurations.themeId === 1 ||
      window.CtxThemeConfigurations.themeId === 2
    ) {
      state.imagesLocation = "assets";
    } else {
      state.imagesLocation = "images";
    }
  }
  let newState = {};
  switch (action.type) {
    case THEME_UPDATE:
      newState = { ...state };
      setTheme(newState);
      return newState;
    case CONFERENCE_STATE_UPDATE:
      if (action.payload.data && action.payload.data.persistentSpaceTheme) {
        newState = { ...state };
        window.CtxThemeConfigurations =
          action.payload.data.persistentSpaceTheme;
        setTheme(newState);
        return newState;
      }
      return state;
    default:
      return state;
  }
}

function setTheme(newState) {
  let data = window.CtxThemeConfigurations;
  newState.primaryMainColor = data.color1;
  newState.primaryTextColor = data.color2;
  newState.primaryMainTextColor = data.color3;
  newState.primaryBackgroundImageTextColor = data.color4;
  newState.imagesLocation = data.imagesLocation;
}
