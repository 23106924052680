import { isCM } from "../utils";
import axios from "axios";
import cmWebRest from "./cmWebRest";

let contexWebRest;

if (isCM()) {
  // In CM context, route the REST requests so
  // that they are re-processed as commands
  // consumable by the CM server.
  contexWebRest = cmWebRest.create();
} else {
  contexWebRest = axios.create({
    baseURL: window.CtxAppConfigurations.restUri
  });
}

export default contexWebRest;
