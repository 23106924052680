import { useSelector } from "react-redux";
import { MainPage, LoginPage } from "../pages";

export default function CMPage() {
  const cmLoggedIn = useSelector(state => state.session.cmLoggedIn);
  if (cmLoggedIn) {
    return <MainPage />;
  } else {
    return <LoginPage />;
  }
}
