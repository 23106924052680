//-----------Action types----------//

export const VIDEO_POPOUT_SHOW = "VIDEO_POPOUT_SHOW";
export const VIDEO_POPOUT_HIDE = "VIDEO_POPOUT_HIDE";

//---------Action creators-------------//

export const showVideoPopout = windowName => {
  return {
    type: VIDEO_POPOUT_SHOW,
    payload: windowName
  };
};

export const hideVideoPopout = () => {
  return {
    type: VIDEO_POPOUT_HIDE
  };
};
