import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { PortalLoginForm, PortalView } from "../../components/portalComponents";
import { PortalHeader, PortalContent } from "../../components/layouts";
import { authenticatePortalUser } from "./../../actions/index";
import { getUrlParam, redirectUnsupportedBrowsers } from "../../utils";

const styles = theme => ({
  ...theme.style.portalPageLayout
});

class PortalPage extends Component {
  constructor(props) {
    super(props);
    document.title = "ConnectNow Portal";
    redirectUnsupportedBrowsers();
  }

  authenticate = (session, history) => {
    const vi = getUrlParam("u");

    if (vi) {
      switch (session.isPortalUserLoggedIn) {
        case false:
          history.replace("portal");
          break;
        case true:
          break;
        case null:
        default:
          this.props.authenticatePortalUser(vi);
      }
    }
  };

  componentDidMount() {
    const { session, history } = this.props;
    this.authenticate(session, history);
  }

  // Handle an update so that the false case can
  // be handled in the authenticate method
  componentDidUpdate() {
    const { session, history } = this.props;
    this.authenticate(session, history);
  }

  render() {
    const { classes, session } = this.props;
    const isPortalUserLoggedIn = session.isPortalUserLoggedIn;
    const component = !isPortalUserLoggedIn ? (
      <PortalLoginForm />
    ) : (
      <PortalView />
    );

    return (
      <Fragment>
        <CssBaseline />
        <PortalHeader displayLogout={isPortalUserLoggedIn} />
        <div className={classes.root}>
          <div className={classes.portalHeaderSpacer} />
          <PortalContent component={component} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  authenticatePortalUser: vi => dispatch(authenticatePortalUser(vi))
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalPage))
);
