import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import { withTheme } from "@material-ui/core/styles";
import LocalizedText from "../reusable/LocalizedText";
import {
  hideInviteByPhone,
  updateInviteByPhoneParticipant,
  createAndCallParty,
  disconnectAndRemoveParty
} from "./actions";
import IntlTelInput from "react-intl-tel-input";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { getIntl } from "../../utils";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextButton from "../reusable/TextButton";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/***
 * If InviteByPhoneWindowState.OPEN  ---> InviteParticipantPopper is shown
 * If InviteByPhoneWindowState.CLOSE ----> InviteParticipantPopper is closed
 *
 * Stateful component that sets InviteByPhoneWindowState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set InviteByPhoneWindowState.OPEN in the store.
 * This component reads from the redux store to decide if the popper should be shown. *
 */

// InviteByPhoneWindowState is exported for the reducer to set OPEN and CLOSE states.
export const InviteByPhoneWindowState = {
  CLOSE: 1,
  OPEN: 2
};

const InviteByPhoneFeedbackType = {
  ERROR: 1,
  INFO: 2
};

const InviteByPhoneErrorType = {
  UNKNOWN: 0,
  DUPLICATE_PHONE_NUM: 1,
  EMPTY_PHONE_NUM: 2,
  INVALID_PHONE_NUM: 3,
  EMPTY_NAME: 4,
  RESTRICTED_CHAR: 5,
  CONFERENCE_NOT_ACTIVE: 6
};

export const InviteByPhoneParticipantState = {
  ERROR: -1,
  INIT: 0,
  CALLING: 1,
  CONNECTED: 2
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    float: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  mainContent: {
    display: "flex",
    flexDirection: "column"
  },
  partyInfo: {
    display: "flex",
    flexDirection: "column"
  },
  displayinviteByPhoneParticipantPartyInfoEnter: {
    opacity: 0.01
  },
  displayinviteByPhoneParticipantPartyInfoEnterActive: {
    opacity: 1,
    transition: "opacity .5s ease-in"
  },
  partyNameDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "10px",
    marginBottom: "10px"
  },
  partyNameLabel: {
    flexBasis: "auto",
    alignSelf: "center",
    marginRight: "10px"
  },
  partyNameTextField: {
    flexBasis: "auto",
    flexGrow: "1"
  },
  partyNameTextFieldBorder: {
    "&:before": {
      borderBottom: "1px solid " + alpha(theme.colors.primaryTextColor, 0.7)
    }
  },
  partyNameTextFieldBorderFocused: {
    "&:after": {
      borderBottom: "1px solid " + alpha(theme.colors.primaryTextColor, 0.7)
    }
  },
  telephoneInputDiv: {
    width: "100%",
    marginTop: "20px"
  },
  intlTelInput: {
    width: "100%",
    marginBottom: "5px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    "& .selected-flag": {
      outline: "none"
    },
    "& .country-list": {
      backgroundColor: theme.colors.telephoneInputBackgroundColor,
      color: theme.colors.telephoneInputTextColor,
      borderColor: alpha(theme.colors.telephoneInputTextColor, 0.2),
      // It is important for the country-list to be relatively positioned for it to be visible above the elements underneath
      position: "relative",
      whiteSpace: "normal",
      marginTop: "10px",
      maxHeight: "180px"
    },
    "& .country-list .divider": {
      borderBottomColor: alpha(theme.colors.telephoneInputTextColor, 0.2)
    },
    "& .country-list .country.highlight": {
      backgroundColor: alpha(theme.colors.telephoneInputTextColor, 0.1)
    },
    "& .country-list .country .dial-code": {
      color: alpha(theme.colors.telephoneInputTextColor, 0.5)
    }
  },
  input: {
    width: "100%",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "16px",
    backgroundColor: "transparent",
    color: theme.colors.primaryTextColor,
    border: "none",
    borderBottom: "1px solid",
    borderBottomColor: alpha(theme.colors.primaryTextColor, 0.7),
    fontWeight: 400,
    outline: "none",
    "&::placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7),
      // Needed for Firefox
      opacity: 1
    },
    // IE
    "&:-ms-input-placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7)
    },
    // Edge
    "&::-ms-input-placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7)
    }
  },
  feedbackContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    width: "290px",
    height: "50px",
    textAlign: "center"
  },
  error: {
    "&$error": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.error.main
    },
    "&$error::placeholder": {
      color: theme.palette.error.main
    }
  },
  iconButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    marginBottom: "20px"
  }
});

class InviteByPhoneWindow extends Component {
  constructor(props) {
    super(props);
    this.phoneNumberInputRef = React.createRef();
  }

  state = {
    partyName: this.props.inviteParticipant.inviteByPhoneParticipant.partyName,
    phoneNumber: "",
    isModerator:
      this.props.inviteParticipant.inviteByPhoneParticipant.partyIsModerator,
    userDefined1:
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined1,
    userDefined2:
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined2,
    userDefined3:
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined3,
    userDefined4:
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined4,
    phoneNumberValid: null,
    displayFeedback: false,
    feedbackType: InviteByPhoneFeedbackType.INFO,
    errorType: InviteByPhoneErrorType.UNKNOWN
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.phoneNumberInputRef.current != null &&
      this.state.phoneNumber !== ""
    ) {
      this.phoneNumberInputRef.current.setNumber(this.state.phoneNumber);
    }
    //partyName
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyName !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyName
    ) {
      this.setState({
        partyName:
          this.props.inviteParticipant.inviteByPhoneParticipant.partyName
      });
    }
    //userDefined1
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyUserDefined1 !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined1
    ) {
      this.setState({
        userDefined1:
          this.props.inviteParticipant.inviteByPhoneParticipant
            .partyUserDefined1
      });
    }
    //userDefined2
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyUserDefined2 !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined2
    ) {
      this.setState({
        userDefined2:
          this.props.inviteParticipant.inviteByPhoneParticipant
            .partyUserDefined2
      });
    }
    //userDefined3
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyUserDefined3 !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined3
    ) {
      this.setState({
        userDefined3:
          this.props.inviteParticipant.inviteByPhoneParticipant
            .partyUserDefined3
      });
    }
    //userDefined4
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyUserDefined4 !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyUserDefined4
    ) {
      this.setState({
        userDefined4:
          this.props.inviteParticipant.inviteByPhoneParticipant
            .partyUserDefined4
      });
    }
    //isModerator
    if (
      prevProps.inviteParticipant.inviteByPhoneParticipant.partyIsModerator !==
      this.props.inviteParticipant.inviteByPhoneParticipant.partyIsModerator
    ) {
      this.setState({
        isModerator:
          this.props.inviteParticipant.inviteByPhoneParticipant.partyIsModerator
      });
    }
  }

  overrideHandleClose = () => {
    this.props.updateInviteByPhoneParticipant({
      userId: undefined,
      partyName: "",
      partyIsModerator: false,
      partyUserDefined1: "",
      partyUserDefined2: "",
      partyUserDefined3: "",
      partyUserDefined4: "",
      partyNameReadOnly: false
    });
    this.props.hideInviteByPhone();
  };

  createGuestParty = (
    partyName,
    phoneNumber,
    isModerator,
    ud1,
    ud2,
    ud3,
    ud4
  ) => {
    return {
      name: partyName,
      phoneNumber: phoneNumber,
      moderator: isModerator ? 1 : 0,
      userDefined: ud1,
      userDefined2: ud2,
      userDefined3: ud3,
      userDefined4: ud4
    };
  };

  checkDuplicateParty = (name, phoneNumber) => {
    let participants = this.props.participants.parties;
    for (let i = 0; i < participants.length; i++) {
      var existingParty = participants[i];

      if (
        name === existingParty.name &&
        phoneNumber === existingParty.phoneNumber &&
        existingParty.connectState !== "NotConnected"
      ) {
        return true;
      }
    }
    return false;
  };

  handlePartyNameChange = event => {
    this.setState({
      partyName: event.target.value.substring(0, 30)
    });
  };

  handlePhoneNumberChange = (valid, value, countryData, number) => {
    this.setState({
      phoneNumberValid: valid,
      phoneNumber: number,
      duplicatePhoneNumber: false,
      displayFeedback: false
    });
  };

  handlePhoneNumberBlur = () => {
    if (!this.state.duplicatePhoneNumber) {
      let feedbackTypeValue = this.state.phoneNumberValid
        ? InviteByPhoneFeedbackType.INFO
        : InviteByPhoneFeedbackType.ERROR;

      let errorTypeValue;

      if (this.state.phoneNumber == null || this.state.phoneNumber === "") {
        errorTypeValue = InviteByPhoneErrorType.EMPTY_PHONE_NUM;
      } else if (this.state.phoneNumberValid === false) {
        errorTypeValue = InviteByPhoneErrorType.INVALID_PHONE_NUM;
      }

      this.setState({
        displayFeedback: !this.state.phoneNumberValid,
        feedbackType: feedbackTypeValue,
        errorType: errorTypeValue
      });
    }
  };

  handleSelectFlag = (value, countryData, number, valid) => {
    this.setState({
      phoneNumberValid: valid,
      phoneNumber: number,
      displayFeedback: false,
      duplicatePhoneNumber: false
    });
  };

  handleCallNewParticipant = () => {
    //Check Name
    if (
      this.state.partyName === undefined ||
      this.state.partyName === "" ||
      this.state.partyName.replace(/(^\s+|\s+$)/g, "") === ""
    ) {
      this.setState({
        displayFeedback: true,
        feedbackType: InviteByPhoneFeedbackType.ERROR,
        errorType: InviteByPhoneErrorType.EMPTY_NAME
      });
      return;
    } else if (
      this.state.partyName.indexOf("~") !== -1 ||
      this.state.partyName.indexOf("\\") !== -1 ||
      this.state.partyName.indexOf("|") !== -1 ||
      this.state.partyName.indexOf("%") !== -1
    ) {
      this.setState({
        displayFeedback: true,
        feedbackType: InviteByPhoneFeedbackType.ERROR,
        errorType: InviteByPhoneErrorType.RESTRICTED_CHAR
      });
      return;
    }

    //Check Phone
    if (
      this.state.phoneNumber.trim().length === 0 ||
      !this.state.phoneNumberValid
    ) {
      this.setState({
        displayFeedback: true,
        feedbackType: InviteByPhoneFeedbackType.ERROR,
        errorType: InviteByPhoneErrorType.INVALID_PHONE_NUM
      });
      return;
    }

    //CreateAndCallParty
    const guestParty = this.createGuestParty(
      this.state.partyName,
      this.state.phoneNumber,
      this.state.isModerator,
      this.state.userDefined1,
      this.state.userDefined2,
      this.state.userDefined3,
      this.state.userDefined4
    );

    if (!this.checkDuplicateParty(guestParty.name, guestParty.phoneNumber)) {
      this.setState({
        displayFeedback: true,
        feedbackType: InviteByPhoneFeedbackType.INFO,
        errorType: undefined,
        duplicatePhoneNumber: false
      });

      if (this.props.session.confActive) {
        this.props.createAndCallParty(
          guestParty,
          this.props.session.userId,
          this.props.inviteParticipant.inviteByPhoneParticipant.userId != null,
          this.props.inviteParticipant.inviteByPhoneParticipant.userId
        );
      } else {
        this.setState({
          displayFeedback: true,
          feedbackType: InviteByPhoneFeedbackType.ERROR,
          errorType: InviteByPhoneErrorType.CONFERENCE_NOT_ACTIVE
        });
        return;
      }
    } else {
      this.setState({
        displayFeedback: true,
        feedbackType: InviteByPhoneFeedbackType.ERROR,
        errorType: InviteByPhoneErrorType.DUPLICATE_PHONE_NUM,
        duplicatePhoneNumber: true
      });
    }
  };

  handleCancelCall = () => {
    this.props.disconnectAndRemoveParty(
      this.props.inviteParticipant.inviteByPhoneParticipant.partyid,
      this.props.session.userId
    );
  };

  handlePartyConnectedOK = () => {
    if (
      this.props.inviteParticipant.inviteByPhoneParticipant.partyNameReadOnly
    ) {
      this.props.hideInviteByPhone();
    }

    this.props.updateInviteByPhoneParticipant({
      state: InviteByPhoneParticipantState.INIT,
      partyid: undefined,
      userId: undefined,
      partyName: "",
      partyIsModerator: false,
      partyUserDefined1: "",
      partyUserDefined2: "",
      partyUserDefined3: "",
      partyUserDefined4: "",
      partyNameReadOnly: false
    });

    this.setState({
      partyName: "",
      phoneNumber: "",
      userDefined1: "",
      userDefined2: "",
      userDefined3: "",
      userDefined4: ""
    });
  };

  handlePartyDisconnectOK = () => {
    this.props.disconnectAndRemoveParty(
      this.props.inviteParticipant.inviteByPhoneParticipant.partyid,
      this.props.session.userId
    );
  };

  handleButtonClick = () => {
    const phoneParticipant =
      this.props.inviteParticipant.inviteByPhoneParticipant;

    if (phoneParticipant != null) {
      switch (phoneParticipant.state) {
        case InviteByPhoneParticipantState.INIT:
          this.handleCallNewParticipant();
          break;
        case InviteByPhoneParticipantState.CALLING:
          this.handleCancelCall();
          break;
        case InviteByPhoneParticipantState.CONNECTED:
          this.handlePartyConnectedOK();
          break;
        case InviteByPhoneParticipantState.ERROR:
          this.handlePartyDisconnectOK();
          break;
        default:
          break;
      }
    } else {
      this.handleCallNewParticipant();
    }
  };

  getTranslation = value => {
    return this.props.intl.formatMessage(getIntl(value));
  };

  render() {
    const { classes } = this.props;
    const { partyName, errorType } = this.state;
    const enableFlagSelection = window.CtxAppConfigurations.enableFlagSelection;
    const placeholder = this.getTranslation("phone");
    let feedbackMessage = "";
    let buttonMessage;

    if (this.props.inviteParticipant.inviteByPhoneParticipant !== undefined) {
      switch (this.props.inviteParticipant.inviteByPhoneParticipant.state) {
        case InviteByPhoneParticipantState.INIT:
          buttonMessage = this.getTranslation("callNewParticipant");
          switch (errorType) {
            case InviteByPhoneErrorType.DUPLICATE_PHONE_NUM:
              feedbackMessage = this.getTranslation("duplicateParty");
              break;
            case InviteByPhoneErrorType.EMPTY_PHONE_NUM:
              feedbackMessage = this.getTranslation("emptyPhone");
              break;
            case InviteByPhoneErrorType.INVALID_PHONE_NUM:
              feedbackMessage = this.getTranslation("invalidPhone");
              break;
            case InviteByPhoneErrorType.EMPTY_NAME:
              feedbackMessage = this.getTranslation("emptyName");
              break;
            case InviteByPhoneErrorType.RESTRICTED_CHAR:
              feedbackMessage = this.getTranslation(
                "loginFormErrorMessageNotAccept"
              );
              break;
            case InviteByPhoneErrorType.CONFERENCE_NOT_ACTIVE:
              feedbackMessage = this.getTranslation(
                "connectAudioToActivateConference"
              );
              break;
            case InviteByPhoneErrorType.UNKNOWN:
              feedbackMessage = this.getTranslation("unknownError");
              break;
            default:
              feedbackMessage = "";
              break;
          }
          break;
        case InviteByPhoneParticipantState.CALLING:
          buttonMessage = this.getTranslation("cancel");
          feedbackMessage = this.getTranslation("connectingDotDotDot");
          break;
        case InviteByPhoneParticipantState.CONNECTED:
          buttonMessage = this.getTranslation("ok");
          feedbackMessage =
            (partyName !== undefined && partyName.length > 0
              ? partyName
              : "???") +
            " " +
            this.getTranslation("hasJoinedTheConference");
          break;
        case InviteByPhoneParticipantState.ERROR:
          buttonMessage = this.getTranslation("ok");
          feedbackMessage = this.getTranslation("invitedPartyDidNotAnswer");
          break;
        default:
          buttonMessage = this.getTranslation("callNewParticipant");
          break;
      }
    } else {
      buttonMessage = this.getTranslation("callNewParticipant");

      switch (errorType) {
        case InviteByPhoneErrorType.DUPLICATE_PHONE_NUM:
          feedbackMessage = this.getTranslation("duplicateParty");
          break;
        case InviteByPhoneErrorType.EMPTY_PHONE_NUM:
          feedbackMessage = this.getTranslation("emptyPhone");
          break;
        case InviteByPhoneErrorType.INVALID_PHONE_NUM:
          feedbackMessage = this.getTranslation("invalidPhone");
          break;
        case InviteByPhoneErrorType.UNKNOWN:
          feedbackMessage = this.getTranslation("unknownError");
          break;
        default:
          feedbackMessage = "";
          break;
      }
    }

    let feedbackColor;

    if (
      this.props.inviteParticipant.inviteByPhoneParticipant !== undefined &&
      this.props.inviteParticipant.inviteByPhoneParticipant.state !==
        undefined &&
      this.props.inviteParticipant.inviteByPhoneParticipant.state ===
        InviteByPhoneParticipantState.ERROR
    ) {
      feedbackColor = "error";
    } else {
      feedbackColor =
        this.state.feedbackType === InviteByPhoneFeedbackType.INFO
          ? "inherit"
          : "error";
    }

    let feedback = (
      <Typography color={feedbackColor} variant="body2">
        {feedbackMessage}
      </Typography>
    );

    const partyInfoComponent = (
      <div key={"showInviteByPhoneParticipantPartyInfoDiv"}>
        <div className={classes.partyNameDiv}>
          <LocalizedText
            value="nameColon"
            variant="body1"
            className={classes.partyNameLabel}
          />
          <TextField
            id="inviteByPhone-partyName"
            onChange={this.handlePartyNameChange}
            value={partyName}
            className={classNames(classes.partyNameTextField)}
            InputProps={{
              classes: {
                underline: classes.partyNameTextFieldBorder,
                focused: classes.partyNameTextFieldBorderFocused
              },
              readOnly:
                this.props.inviteParticipant.inviteByPhoneParticipant
                  .partyNameReadOnly
            }}
          />
        </div>
        <div className={classes.telephoneInputDiv}>
          <IntlTelInput
            allowDropdown={enableFlagSelection}
            ref={this.phoneNumberInputRef}
            onPhoneNumberChange={this.handlePhoneNumberChange}
            onPhoneNumberBlur={this.handlePhoneNumberBlur}
            onSelectFlag={this.handleSelectFlag}
            placeholder={placeholder}
            containerClassName={classNames(
              classes.intlTelInput,
              "intl-tel-input"
            )}
            inputClassName={classNames(
              {
                [classes.error]:
                  this.state.displayFeedback &&
                  this.state.phoneNumberValid === false
              },
              classes.input
            )}
            format
            preferredCountries={window.CtxAppConfigurations.preferredCountryCodes
              .split(",")
              .map(item => item.trim())
              .filter(item => item !== "")}
          />
        </div>
      </div>
    );

    const component = (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.header}>
            <div>
              <LocalizedText
                value="inviteByPhone"
                variant="h6"
                className={classes.headerText}
              />
            </div>
            <div className={classes.hrDiv} />
          </div>

          <div className={classes.mainContent}>
            <div className={classes.partyInfo}>
              <TransitionGroup>
                {this.props.inviteParticipant.inviteByPhoneParticipant.state ===
                  InviteByPhoneParticipantState.INIT && (
                  <CSSTransition
                    classNames={{
                      enter:
                        classes.displayinviteByPhoneParticipantPartyInfoEnter,
                      enterActive:
                        classes.displayinviteByPhoneParticipantPartyInfoEnterActive
                    }}
                    timeout={{ enter: 300, exit: 1 }}
                  >
                    {partyInfoComponent}
                  </CSSTransition>
                )}
              </TransitionGroup>
            </div>
            <div className={classes.feedbackContainer}>
              {this.state.displayFeedback && feedback}
            </div>
            <div className={classes.iconButtonsContainer}>
              <TextButton
                onClick={this.handleButtonClick}
                disabled={false}
                color={
                  this.props.inviteParticipant.inviteByPhoneParticipant
                    .state === InviteByPhoneParticipantState.CALLING
                    ? "error"
                    : undefined
                }
              >
                <Typography color="inherit" variant="body2">
                  {buttonMessage}
                </Typography>
              </TextButton>
            </div>
          </div>
        </div>
      </Fragment>
    );

    const hideWindowCloseButton =
      this.props.inviteParticipant.inviteByPhoneParticipant.state ===
      InviteByPhoneParticipantState.INIT
        ? false
        : true;

    return (
      <Fragment>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={this.overrideHandleClose}
          disableClickAwayClose={true}
          hideCloseButton={hideWindowCloseButton}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ inviteParticipant, participants, session }) => ({
  inviteParticipant,
  participants,
  session
});

const mapDispatchToProps = dispatch => ({
  hideInviteByPhone: () => dispatch(hideInviteByPhone()),
  updateInviteByPhoneParticipant: newState =>
    dispatch(updateInviteByPhoneParticipant(newState)),
  createAndCallParty: (party, userid, associate, userID) =>
    dispatch(createAndCallParty(party, userid, associate, userID)),
  disconnectAndRemoveParty: (partyids, userid) =>
    dispatch(disconnectAndRemoveParty(partyids, userid))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(InviteByPhoneWindow)
    )
  )
);
