import {
  PARTICIPANTS_BUFFERED_UPDATE,
  PARTICIPANTS_SORT,
  USER_LOGOUT,
  USERS_UPDATE,
  WEBSOCKET_RECONNECT,
  USER_LOGIN,
  AUTHENTICATE_USER,
  SET_USER_ID,
  CONFERENCE_STATE_UPDATE
} from "../actions/types";
import { SortDirection } from "../utils";

const initialState = {
  merged: [],
  parties: [],
  users: [],
  sort: { by: "name", direction: SortDirection.ASCENDING },
  id: undefined,
  waitingRoomParties: [],
  connectedParties: [],
  idleParties: [],
  recorder: false,
  maxDisplayedUsers: null,
  displayedUsers: []
};

export default function participantsReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case USER_LOGIN:
      // Clear the state on user login since the PARTICIPANTS_BUFFERED_UPDATE
      // action is sometimes called after USER_LOGOUT.
      newState = { ...initialState };
      newState.id = action.payload.id;
      return newState;
    case AUTHENTICATE_USER:
      newState = { ...state };
      newState.id = action.payload.id;
      return newState;
    case SET_USER_ID:
      newState = { ...state };
      newState.id = action.payload;
      return newState;
    case USERS_UPDATE:
      newState = { ...state };

      if (
        action.payload.id === newState.id &&
        action.payload.username === "Recorder"
      ) {
        newState.recorder = true;
      }

      newState.users = newState.users.slice();

      let newUser = action.payload;
      const index = newState.users.findIndex(item => item.id === newUser.id);
      if (index > -1) {
        if (!newUser.connected) {
          newState.users.splice(index, 1);
        } else {
          // The user events do not use change tracking, so
          // the existing user object should be overwritten
          newState.users[index] = { ...newUser };
        }
      } else {
        newState.users.push({ ...newUser });
      }

      newState.merged = mergeLists(
        newState.users,
        newState.parties,
        newState.id
      );
      updateMaps(newState); //handle splitting waiting room parties, connected parties & idle parties
      sort(
        newState.connectedParties,
        newState.sort.by,
        newState.sort.direction
      );
      updateDisplayedUsers(state, newState);
      return newState;
    case PARTICIPANTS_BUFFERED_UPDATE:
      newState = { ...state };
      newState.parties = newState.parties.slice();

      let newData = mergeNew(action.payload);
      mergeIntoExisting(newData, newState.parties);

      //old
      // merge(newState.merged, action.payload);
      // sort(newState.connectedParties, newState.sort.by, newState.sort.direction);
      newState.merged = mergeLists(
        newState.users,
        newState.parties,
        newState.id
      );
      updateMaps(newState); //handle splitting waiting room parties, connected parties & idle parties
      sort(
        newState.connectedParties,
        newState.sort.by,
        newState.sort.direction
      );
      updateDisplayedUsers(state, newState);
      return newState;
    case CONFERENCE_STATE_UPDATE:
      newState = { ...state };
      newState.maxDisplayedUsers = action.payload.data.maxDisplayedUsers;
      updateDisplayedUsers(state, newState);
      return newState;
    case USER_LOGOUT:
      return initialState;
    case WEBSOCKET_RECONNECT:
      newState = { ...initialState };
      newState.id = state.id;
      return newState;
    case PARTICIPANTS_SORT:
      newState = { ...state };
      let { dataKey, rootDataKey } = action.payload.sort.by;
      let { direction } = action.payload.sort;
      if (!rootDataKey) {
        rootDataKey = dataKey;
      }
      if (newState.sort.by === dataKey) {
        if (direction === SortDirection.TOGGLE) {
          newState.sort = toggleSort(newState.sort.direction, dataKey);
        } else {
          newState.sort.direction = direction;
          newState.sort.by = dataKey;
        }
      } else {
        if (direction === SortDirection.TOGGLE) {
          newState.sort.by = dataKey;
          newState.sort.direction = SortDirection.ASCENDING;
        } else {
          newState.sort.direction = direction;
          newState.sort.by = dataKey;
        }
      }
      if (
        newState.connectedParties !== undefined &&
        newState.connectedParties.length > 0
      ) {
        sort(newState.connectedParties, rootDataKey, newState.sort.direction);
      }
      return newState;
    default:
      return state;
  }
}

function createParticipant() {
  return {
    //agc: false,
    //ani: "",
    //beingVetted: false,
    //billingField: "",
    //billingType: "",
    //bridgeTime: "",
    connectState: "NotConnected",
    dataConfID: "",
    dialedIn: false,
    displayable: true,
    //dnis: "",
    //dnisDescription: "",
    firstName: "",
    //hostCtrlLevel: 0,
    id: "",
    lastName: "",
    //location: 0,
    moderator: "Participant",
    name: "",
    operatorSignal: false,
    phoneNumber: "",
    pin: "",
    //qaConfPosition: 0,
    //qaState: "AUDIENCE",
    talkTime: 0,
    talking: false,
    userDefined: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: ""
  };
}

function toggleSort(previousDirection, sortBy) {
  if (previousDirection === SortDirection.ASCENDING) {
    return { by: sortBy, direction: SortDirection.DESCENDING };
  } else {
    return { by: sortBy, direction: SortDirection.ASCENDING };
  }
}

function mergeNew(data) {
  let mergedData = [];
  for (var i = 0; i < data.length; i++) {
    if (!data[i]) {
      continue;
    }
    let item = { ...data[i] };
    for (var j = i + 1; j < data.length; j++) {
      if (!data[j]) {
        continue;
      }
      if (item.id === data[j].id) {
        item = { ...item, ...data[j] };
        data[j] = undefined;
      }
    }
    mergedData.push(item);
  }
  return mergedData;
}

function mergeIntoExisting(data, existingData) {
  for (let candidate of data) {
    if (candidate) {
      const index = existingData.findIndex(item => item.id === candidate.id);
      if (index > -1) {
        if (candidate.connectState === "Removed") {
          existingData.splice(index, 1);
        } else {
          existingData[index] = { ...existingData[index], ...candidate };
        }
      } else {
        existingData.push({ ...createParticipant(), ...candidate });
      }
    }
  }
}

function mergeLists(users, parties, id) {
  let list = [];
  let partyList = [...parties];
  for (let user of users) {
    let index = partyList.findIndex(item => item.id === user.partyID);
    let newUser;
    if (index > -1) {
      newUser = { ...partyList[index] };
      partyList.splice(index, 1);
      newUser.partyID = user.partyID;
    } else {
      newUser = createParticipant();
      newUser.partyID = null;
    }
    newUser.id = user.id;
    newUser.name = user.username;
    let lastIndexOf = user.username.lastIndexOf(" ");
    newUser.firstName =
      lastIndexOf > -1 ? user.username.slice(0, lastIndexOf) : user.username;
    newUser.lastName =
      lastIndexOf > -1 ? user.username.slice(lastIndexOf + 1) : "";
    newUser.userDefined = user.userDefined;
    newUser.userDefined2 = user.userDefined2;
    newUser.userDefined3 = user.userDefined3;
    newUser.userDefined4 = user.userDefined4;
    newUser.dataConnected = true;
    newUser.userLevel = user.userLevel;
    newUser.me = id === user.id;
    newUser.displayable = true;
    newUser.usingWebRTCCall = user.usingWebRTCCall;
    newUser.presenterMode = user.presenterMode;
    newUser.vetted = user.vetted;
    newUser.audioModeLocked = user.audioModeLocked;
    newUser.videoPinned = user.videoPinned;
    list.push(newUser);
  }
  for (let party of partyList) {
    if (!party.displayable) {
      continue;
    }
    let newParty = { ...party };
    newParty.partyID = party.id;
    // CONTEXWeb incorrectly treats a one word name as a last name
    // instead of a first name, so the values are overwritten here
    let lastIndexOf = newParty.name.lastIndexOf(" ");
    newParty.firstName =
      lastIndexOf > -1 ? newParty.name.slice(0, lastIndexOf) : newParty.name;
    newParty.lastName =
      lastIndexOf > -1 ? newParty.name.slice(lastIndexOf + 1) : "";
    newParty.dataConnected = false;
    if (newParty.moderator === "Moderator") {
      newParty.userLevel = "1";
    } else {
      newParty.userLevel = "2";
    }
    newParty.vetted = true;
    list.push(newParty);
  }

  return list;
}

// function merge(data, updatedData) {
//   const index = data.findIndex(item => item.id === updatedData.id);
//   if (index > -1) {
//     if (updatedData.connectState === "Removed") {
//       data.splice(index, 1);
//     } else {
//       data[index] = { ...data[index], ...updatedData };
//     }
//   } else {
//     data.push({ ...createParticipant(), ...updatedData });
//   }
// }

function sort(data, sortBy, sortDirection) {
  //TODO use enums
  if (data[0]) {
    if (sortBy === "phoneNumber") {
      return sortString(
        data,
        sortBy,
        sortDirection === SortDirection.DESCENDING
      );
    } else if (isString(data[0][sortBy])) {
      return sortString(
        data,
        sortBy,
        sortDirection === SortDirection.DESCENDING
      );
    } else {
      return sortNumber(
        data,
        sortBy,
        sortDirection === SortDirection.DESCENDING
      );
    }
  }
}

function sortString(data, value, descending) {
  if (descending) {
    return data.sort((a, b) => b[value].localeCompare(a[value]));
  } else {
    return data.sort((a, b) => a[value].localeCompare(b[value]));
  }
}

function sortNumber(data, value, descending) {
  if (descending) {
    return data.sort((a, b) => a[value] - b[value]);
  } else {
    return data.sort((a, b) => b[value] - a[value]);
  }
}

/*
  returns -1: a<b
           0: a=b
           1: a>b
*/
// function compare(a, b, asString) {
//   if (asString || isString(a)) {
//     a.localeCompare(b);
//   } else {
//     return a - b;
//   }
// }

function isString(value) {
  return typeof value === "string" || value instanceof String;
}

function updateMaps(data) {
  data.waitingRoomParties = [];
  data.connectedParties = [];
  data.idleParties = [];

  for (let participant of data.merged) {
    if (participant) {
      if (
        participant.connectState === "NotConnected" &&
        !participant.dataConnected
      ) {
        data.idleParties.push(participant);
      } else if (!participant.vetted) {
        data.waitingRoomParties.push(participant);
      } else {
        data.connectedParties.push(participant);
      }
    }
  }
}

function updateDisplayedUsers(state, newState) {
  // When sorting the users, the ones that are currently talking all have higher
  // priority than the ones that are not talking. For the users that are
  // currently talking, the ones that started talking the most recently have the
  // highest priority. For the users that are not talking, the ones that stopped
  // talking the most recently have the highest priority. Note that the talkTime
  // property is also updated when a user stops talking.
  let newUsers = newState.merged
    .filter(
      user => user.id !== newState.id && user.dataConnected && user.vetted
    )
    .sort((user1, user2) => {
      if (user1.videoPinned !== user2.videoPinned) {
        return user2.videoPinned - user1.videoPinned;
      } else if (user1.talking !== user2.talking) {
        return user2.talking - user1.talking;
      } else {
        return user2.talkTime - user1.talkTime;
      }
    });

  if (newState.maxDisplayedUsers != null) {
    newUsers = newUsers.slice(
      0,
      newState.recorder || window.CtxAppConfigurations.experimentalModeEnabled
        ? newState.maxDisplayedUsers
        : newState.maxDisplayedUsers - 1
    );
  }

  const previousPinnedUsers = [];
  const previousUnpinnedUsers = [];
  for (const userid of state.displayedUsers) {
    let user = state.merged.find(item => item.id === userid);
    if (user) {
      if (user.videoPinned) {
        previousPinnedUsers.push(user.id);
      } else {
        previousUnpinnedUsers.push(user.id);
      }
    }
  }

  const newPinnedUsers = [];
  const newUnpinnedUsers = [];
  for (const user of newUsers) {
    if (user.videoPinned) {
      newPinnedUsers.push(user.id);
    } else {
      newUnpinnedUsers.push(user.id);
    }
  }

  const pinnedDisplayedUsers = transitionDisplayedUsers(
    previousPinnedUsers,
    newPinnedUsers
  );

  const unpinnedDisplayedUsers = transitionDisplayedUsers(
    previousUnpinnedUsers,
    newUnpinnedUsers
  );

  if (
    previousPinnedUsers !== pinnedDisplayedUsers ||
    previousUnpinnedUsers !== unpinnedDisplayedUsers
  ) {
    newState.displayedUsers = [
      ...pinnedDisplayedUsers,
      ...unpinnedDisplayedUsers
    ];
  }
}

export function transitionDisplayedUsers(previousUsers, newUsers) {
  const removedUsers = [];
  for (const userid of previousUsers) {
    if (!newUsers.includes(userid)) {
      removedUsers.push(userid);
    }
  }

  const addedUsers = [];
  for (const userid of newUsers) {
    if (!previousUsers.includes(userid)) {
      addedUsers.push(userid);
    }
  }

  if (removedUsers.length === 0 && addedUsers.length === 0) {
    return previousUsers;
  }

  const displayedUsers = [...previousUsers];
  for (const userid of removedUsers) {
    const position = displayedUsers.indexOf(userid);
    if (addedUsers.length > 0) {
      displayedUsers[position] = addedUsers.shift();
    } else {
      displayedUsers.splice(position, 1);
    }
  }

  for (const userid of addedUsers) {
    displayedUsers.push(userid);
  }

  return displayedUsers;
}
