/**
 * MediaPipe video filter util
 * This class was derived from video-filter.mediapipe.ts in Companion Web.
 */
export default class VideoFilter {
  constructor(stream, backgroundBlurAmount, mediaPipe) {
    this._backgroundBlurAmount = backgroundBlurAmount;
    this._mediaPipe = mediaPipe;
    this._width = 1280;
    this._height = 720;
    this._frameRate = 30;

    // Prepare elements
    this._video = document.createElement("video");
    document.body.appendChild(this._video);
    this._video.style.display = "none";
    this._video.width = this._width;
    this._video.height = this._height;
    this._video.autoplay = true;

    this._canvas = document.createElement("canvas");
    document.body.appendChild(this._canvas);
    this._canvas.style.display = "none";
    this._canvas.width = this._width;
    this._canvas.height = this._height;

    this._context = this._canvas.getContext("2d");

    this.stream = stream;

    // Start
    this._active = true;
    this._foreverSegment();
    this._filteredStream = this._canvas.captureStream(this._frameRate);
  }

  get filteredStream() {
    return this._filteredStream;
  }

  set stream(stream) {
    if (this._video.srcObject === stream) {
      return;
    }
    this._video.srcObject = stream;
  }

  set blur(blur) {
    this._backgroundBlurAmount = blur;
  }

  /**
   * Stop and clean up.
   */
  close() {
    if (!this._active) {
      return;
    }

    this._active = false;
    if (this._filteredStream) {
      this._filteredStream.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (this._canvas) {
      document.body.removeChild(this._canvas);
    }
    if (this._video) {
      document.body.removeChild(this._video);
    }
  }

  _foreverSegment() {
    setTimeout(async () => {
      if (!this._active) {
        return;
      }

      if (this._video && this._video.readyState >= 2 && !this._video.paused) {
        const results = await this._mediaPipe.send({ image: this._video });
        this._drawBlurryBackground(results.image, results.segmentationMask);
      }
      this._foreverSegment();
    }, 1000 / this._frameRate);
  }

  /**
   * Blur the background, and draw on canvas.
   */
  _drawBlurryBackground = (image, segmentationMask) => {
    this._context.save();
    this._context.clearRect(0, 0, this._width, this._height);
    this._context.drawImage(image, 0, 0, this._width, this._height);
    // "destination-in" - "The existing canvas content is kept where both the
    // new shape and existing canvas content overlap. Everything else is made
    // transparent."
    // Crop what's not the person using the mask from the original image.
    this._context.globalCompositeOperation = "destination-in";
    this._context.drawImage(segmentationMask, 0, 0, this._width, this._height);
    this._context.globalCompositeOperation = "destination-over";
    this._context.filter = `blur(${this._backgroundBlurAmount}px)`;
    this._context.drawImage(image, 0, 0, this._width, this._height);

    this._context.restore();
  };
}
