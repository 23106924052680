import React, { Component, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import SvgIcon from "../Icons/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import { setVideoInput, setUpdatingVideoInput } from "../../actions";
import classNames from "classnames";
import * as cookiesManager from "../mediasoup/cookiesManager";
import { getIntl, modifyFilter, resetPreviewStream } from "../../utils";
import Logger from "../../Logger";
import CTXSlider from "../reusable/CTXSlider";
import LocalizedText from "../reusable/LocalizedText";
import { withRoomContext } from "../mediasoup/RoomContext";

const logger = new Logger("VideoDeviceSettings");

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  selectContainer: {
    width: "100%",
    height: "5%",
    borderRadius: "10px",
    color: theme.colors.primaryTextColor,
    paddingRight: "30px"
  },
  selectIcon: {
    top: "calc(29% - 12px)",
    color: theme.colors.primaryTextColor,
    fontSize: "2.25em"
  },
  inputBox: {
    borderBottom: `2px solid ${theme.colors.secondaryMainColor}`,
    color: theme.colors.primaryTextColor,
    textAlign: "left"
  },
  menuItem: {
    color: theme.colors.primaryTextColor,
    paddingRight: "20px",
    width: "100%"
  },
  menuItemGroup: {
    border: `3px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "5px",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  settingsSvgIcon: {
    width: "1.75em",
    padding: "3px"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column"
  },
  hiddenSvgIcon: {
    width: "1.75em",
    padding: "3px",
    visibility: "hidden"
  },
  flexRowBox: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "15px",
    alignItems: "center"
  },
  flexItemSmall: {
    flexGrow: 1
  },
  flexItemLarge: {
    flexGrow: 4,
    width: "95%"
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },
  video: {
    width: "160px",
    height: "90px",
    paddingTop: "10px",
    alignSelf: "center"
  }
});

class VideoDeviceSettings extends Component {
  _isMounted = false;

  state = {
    videoInputDevices: []
  };

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.getDevices();
      navigator.mediaDevices.addEventListener("devicechange", this.getDevices);
      try {
        await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: this.props.session.videoInput,
            width: 1280,
            height: 720
          }
        });
      } catch (error) {
        logger.error("Error: %o", error);
      }

      if (!this._isMounted) {
        return;
      }

      await this.getDevices();
      this.setVideoStream();
    }
  }

  getDevices = () => {
    this.enumeratorPromise = navigator.mediaDevices.enumerateDevices();
    this.enumeratorPromise
      .then(this.listDevices)
      .catch(this.errorCallbackOnListDevices);
  };

  listDevices = devices => {
    let inputError;
    let videoInputDevices = [];
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === "videoinput") {
        logger.debug("%s:%s:%s", device.deviceId, device.label, device.kind);
        if (device.deviceId === "" || device.label === "") {
          inputError = "noPermission";
        } else {
          videoInputDevices.push(device);
        }
      }
    }

    if (inputError == null && videoInputDevices.length === 0) {
      inputError = "noDevices";
    }

    this.setState({
      videoInputDevices: videoInputDevices,
      inputError: inputError
    });
  };

  errorCallbackOnListDevices = error => {
    logger.error("Error: %o", error);
  };

  componentWillUnmount() {
    if (this._isMounted) {
      resetPreviewStream(this);
    }

    this._isMounted = false;
    navigator.mediaDevices.removeEventListener("devicechange", this.getDevices);
  }

  setVideoStream = () => {
    let deviceId = this.props.session.videoInput;
    logger.debug("setVideoStream's deviceId: %s", deviceId);

    if (deviceId !== "default" && deviceId !== "") {
      var videoElement = document.getElementById("settingsVideoElement");
      if (videoElement) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: { exact: deviceId },
              width: 1280,
              height: 720
            }
          })
          .then(stream => {
            if (this._isMounted) {
              this.stream = stream;
              modifyFilter(
                this,
                this.props.session.blur,
                "settingsVideoElement"
              );
            }
          })
          .catch(error => {
            logger.error("Error: %o", error);
          });
      }
    }
  };

  handleSelectChange = event => {
    var deviceId = event.target.value;
    if (this.props.session.videoInput !== deviceId) {
      this.props.setUpdatingVideoInput(true);
      var videoElement = document.getElementById("settingsVideoElement");
      if (videoElement && videoElement.srcObject) {
        if (this.stream) {
          // Stop the unfiltered track
          this.stream.getVideoTracks().forEach(track => {
            track.stop();
          });
          this.stream = null;
        }

        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: { exact: deviceId },
              width: 1280,
              height: 720
            }
          })
          .then(stream => {
            if (this._isMounted) {
              this.stream = stream;
              modifyFilter(
                this,
                this.props.session.blur,
                "settingsVideoElement"
              );
            }
          })
          .catch(error => {
            logger.error("Error: %o", error);
          });
      }
      this.props.setVideoInput(deviceId);
    }
    cookiesManager.setVideoInput({ videoInputDeviceId: deviceId });
  };

  setBlur = (event, blur) => {
    const { session, roomClientProvider } = this.props;
    if (session.blur === blur) {
      return;
    }

    modifyFilter(this, blur, "settingsVideoElement");
    roomClientProvider.setBlur(blur);
  };

  increaseBlur = () => {
    let blur = this.props.session.blur + 3;
    if (blur > 30) {
      blur = 30;
    }
    this.setBlur(null, blur);
  };

  decreaseBlur = () => {
    let blur = this.props.session.blur - 3;
    if (blur < 0) {
      blur = 0;
    }
    this.setBlur(null, blur);
  };

  listVideoInputDevices = (classes, videoInputDeviceId) => {
    let videoInputItems = [];
    if (!this.state.inputError) {
      for (let device of this.state.videoInputDevices) {
        let deviceLabel = device.label;
        videoInputItems.push(
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
            classes={{ root: classes.menuItem }}
          >
            <span className={classes.primaryTextColor}>{deviceLabel}</span>
          </MenuItem>
        );
      }
    } else {
      let errorText;
      if (this.state.inputError === "noDevices") {
        errorText = this.props.intl.formatMessage(getIntl("noCameras"));
      } else {
        errorText = this.props.intl.formatMessage(
          getIntl("permissionNotGranted")
        );
      }
      videoInputItems.push(
        <MenuItem
          key="inputError"
          value="inputError"
          classes={{ root: classes.menuItem }}
        >
          <span className={classes.primaryTextColor}>{errorText}</span>
        </MenuItem>
      );
    }

    return (
      <Fragment>
        <div className={classes.flexContainer}>
          <div className={classes.flexRowBox}>
            <div className={classes.flexItemSmall}>
              <SvgIcon iconName="video" className={classes.settingsSvgIcon} />
            </div>
            <div className={classes.flexItemLarge}>
              <Select
                disabled={this.state.inputError != null}
                autoWidth={false}
                value={
                  videoInputItems.length > 0
                    ? this.state.inputError
                      ? "inputError"
                      : videoInputDeviceId
                    : ""
                }
                onChange={this.handleSelectChange}
                classes={{
                  icon: classNames({
                    [classes.selectIcon]: this.state.inputError == null
                  }),
                  root: classes.selectContainer,
                  select: classes.menuItem
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuItemGroup }
                }}
                input={
                  <InputBase
                    id="selectedVideoInput"
                    fullWidth={false}
                    className={classNames(
                      classes.inputBox,
                      classes.flexItemLarge
                    )}
                  />
                }
              >
                {videoInputItems}
              </Select>
            </div>
          </div>
          <video
            id="settingsVideoElement"
            poster={process.env.PUBLIC_URL + "/assets/videoMutePreview.png"}
            autoPlay
            className={classes.video}
          />
        </div>
      </Fragment>
    );
  };

  getBackgroundBlurControl = () => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.flexRowBox}>
          <div className={classes.flexItemSmall}>
            <SvgIcon
              iconName="blur"
              color="active"
              className={classes.settingsSvgIcon}
            />
          </div>
          <div className={classes.flexItemLarge}>
            <LocalizedText
              value="backgroundBlur"
              variant="subtitle1"
              className={classes.inputBox}
            />
          </div>
        </div>
        <div className={classes.flexRowBox}>
          <div className={classes.flexItemSmall}>
            <SvgIcon iconName="blur" className={classes.hiddenSvgIcon} />
          </div>
          <div className={classes.flexItemLarge}>
            <CTXSlider
              value={this.props.session.blur}
              onChange={this.setBlur}
              onIncrease={this.increaseBlur}
              onDecrease={this.decreaseBlur}
              step={0.3}
              max={30}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { classes, session } = this.props;
    const videoInputDeviceId = session.videoInput;
    return (
      <div className={classes.root}>
        {this.listVideoInputDevices(classes, videoInputDeviceId)}
        {this.getBackgroundBlurControl()}
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({
  setVideoInput: videoInput => dispatch(setVideoInput(videoInput)),
  setUpdatingVideoInput: value => dispatch(setUpdatingVideoInput(value))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      withRoomContext(
        connect(mapStateToProps, mapDispatchToProps)(VideoDeviceSettings)
      )
    )
  )
);
