import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";

/**
 * Automatically creates a localized text using a key from the translations file.
 *  Ex: <LocalizedText value="connectPopper.callMe" /> , for this to work connectPopper.callMe needs to be present in the translation file.
 */

const styles = {
  root: {
    display: "inline-block"
  }
};

class LocalizedText extends Component {
  render() {
    return (
      <Typography
        color={this.props.color ? this.props.color : "inherit"}
        className={classNames(
          { [this.props.classes.root]: this.props.inline },
          this.props.className
        )}
        variant={this.props.variant || "body2"}
        style={this.props.style}
        inline={this.props.inline}
        noWrap={this.props.noWrap || false}
        align={this.props.align}
      >
        <FormattedMessage id={this.props.value} defaultMessage="???" />
      </Typography>
    );
  }
}

export default withStyles(styles)(LocalizedText);
