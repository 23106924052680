import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import PortalHeader from "./PortalHeader";
import { selectPortalSettingTabView } from "./actions";
import UserManagementView from "./UserManagementView";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    minWidth: "1160px",
    height: "50px",
    flexDirection: "row",
    margin: "10px 20px 10px 20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  mainContentContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    margin: "10px 15px 0px 15px"
  },
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "center",
    backgroundColor: theme.colors.primaryMainColor,
    color: theme.colors.primaryMainTextColor
  }
});

class PortalUserManagement extends Component {
  //Banner control
  getBannerControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.bannerContainer}>
        <LocalizedText value="betaReleaseBannerText" variant="body2" />
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <PortalHeader title={"userManagement"} />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          {this.getBannerControl()}
          <UserManagementView />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ portal }) => ({ portal });

const mapDispatchToProps = dispatch => ({
  selectPortalSettingTabView: index =>
    dispatch(selectPortalSettingTabView(index))
});

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PortalUserManagement)
  )
);
