import React, { Component } from "react";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withTheme } from "@material-ui/core/styles";

/**
 * Generic popper with any children inside it.
 * @param {props} props
 */

const styles = theme => ({
  ...theme.style.rightBarPopper
});

class CTXBasePopper extends Component {
  state = {
    arrowRef: null
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  //TODO make this better
  //basically, on click away we check that the element actually clicked (or a parent) is not given the special class "popperPopover" - we give this class to popovers spawned from poppers
  closePopper = event => {
    let close = true;
    let target = event.target;
    while (target && target !== document) {
      if (target.classList.contains("popperPopover")) {
        close = false;
        break;
      }
      target = target.parentElement;
    }
    if (close) {
      this.props.closePopper();
    }
  };

  render() {
    const { props } = this;
    const { arrowRef } = this.state;
    const { classes } = this.props;
    return (
      <Popper
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        placement="left"
        className={classes.root}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
      >
        <ClickAwayListener onClickAway={this.closePopper}>
          <div>
            <div className={props.className || classes.layout}>
              {props.children}
            </div>
            <span className={classes.arrow} ref={this.handleArrowRef} />
          </div>
        </ClickAwayListener>
      </Popper>
    );
  }
}

export default withTheme(withStyles(styles)(CTXBasePopper));
