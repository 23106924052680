import {
  PORTAL_SELECT_VIEW,
  LOGIN_PORTAL_USER,
  LOGOUT_PORTAL_USER,
  PORTAL_UPDATE_REPORT_CONFERENCE,
  PORTAL_UPDATE_REPORT_USER,
  PORTAL_UPDATE_REPORT_FEATURE_ACTION,
  PORTAL_UPDATE_CONFERENCE_RECORDINGS,
  PORTAL_REPORT_DATE_FROM,
  PORTAL_REPORT_DATE_TO,
  PORTAL_RECORDING_DATE_FROM,
  PORTAL_RECORDING_DATE_TO,
  PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE
} from "../actions/types";

import {
  PORTAL_USERMANAGEMENT_SET_TAB_INDEX,
  UPDATE_PORTAL_USERMANAGEMENT_USER_LIST,
  SET_PORTAL_USERMANAGEMENT_SELECTED_USER,
  SET_PORTAL_USERMANAGEMENT_SELECTED_USERS_ID_LIST,
  UPDATE_PORTAL_USERMANAGEMENT_SELECTED_USER_PERSISTENT_SPACE,
  SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_STATUS,
  SET_PORTAL_USERMANAGEMENT_NEW_USER_CREATE_STEP,
  SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_PROMPT,
  SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_INFORMATION,
  SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_CONF_TYPE,
  SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES,
  SET_PORTAL_USERMANAGEMENT_SELECTED_EXTERNAL_CONFERENCE,
  SET_PORTAL_USERMANAGEMENT_BUTTON_LOADER,
  SET_PORTAL_USERMANAGEMENT_SHOW_DISABLED_CHECKBOX,
  SET_PORTAL_USERMANAGEMENT_CONFIRM_DELETE_USER_MODAL_STATUS,
  SET_PORTAL_USER_AUDIO_CONF_DETAILS,
  SET_PORTAL_USERMANAGEMENT_PROFILE_SETTING_TAB_INDEX,
  SET_PORTAL_USERMANAGEMENT_SELECTED_USER_AUDIO_CONFERENCE,
  SET_PORTAL_LICENSE,
  SET_PORTAL_LICENSE_MODAL_WINDOW_STATUS,
  SET_PORTAL_LICENSE_MODAL_WINDOW_TEXT,
  SET_PORTAL_THEMES,
  SET_PORTAL_SELECTED_THEME_ID,
  SET_THEME_EDITOR_MODAL_STATE,
  SET_THEME_EDITOR_TAB_INDEX,
  SET_GRAPHICS_LIST
} from "../components/portalComponents/actions";

import {
  PORTAL_SETTINGS_SET_MAIN_TAB_INDEX,
  PORTAL_UPDATE_PROFILE_PROMPT
} from "../components/portalComponents/actions";

import { AudioConferenceType } from "../components/portalComponents/LinkAudioConference";

export default function portalReducer(
  state = {
    profileSettings: { mainTabIndex: 0 },
    portalUserManagement: {
      userTableTabIndex: 0,
      newUserModalStatus: false,
      confirmDeleteUserModalStatus: false,
      newUserCreationProgress: 1,
      newUserAudioConferenceType: AudioConferenceType.Internal,
      showDisabledUser: false,
      selectedUser: null,
      selectedUserMap: new Map(),
      userProfileSettingsTabIndex: 0,
      selectedUserPersistentSpace: {}
    },
    license: {
      licenseArray: [],
      modalStatus: false,
      modalText: ""
    },
    theme: {
      themeMap: null,
      graphicsList: null,
      selectedThemeId: 0,
      selectedTheme: null,
      isThemeEditorModalOpen: false,
      themeEditorTabIndex: 0
    }
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case PORTAL_SELECT_VIEW:
      newState = { ...state, selectedView: action.payload };
      return newState;
    case PORTAL_UPDATE_REPORT_CONFERENCE:
      newState = { ...state, reportConferences: { ...action.payload } };
      return newState;
    case PORTAL_UPDATE_REPORT_USER:
      newState = { ...state, reportUsers: { ...action.payload } };
      return newState;
    case PORTAL_UPDATE_REPORT_FEATURE_ACTION:
      newState = { ...state, reportFeatureActions: { ...action.payload } };
      return newState;
    case PORTAL_REPORT_DATE_FROM:
      newState = { ...state, reportDateFrom: action.payload };
      return newState;
    case PORTAL_REPORT_DATE_TO:
      newState = { ...state, reportDateTo: action.payload };
      return newState;
    case PORTAL_RECORDING_DATE_FROM:
      newState = { ...state, recordingDateFrom: action.payload };
      return newState;
    case PORTAL_RECORDING_DATE_TO:
      newState = { ...state, recordingDateTo: action.payload };
      return newState;
    case PORTAL_UPDATE_CONFERENCE_RECORDINGS:
      newState = { ...state, conferenceRecordings: { ...action.payload } };
      return newState;
    case LOGIN_PORTAL_USER:
    case LOGOUT_PORTAL_USER:
      newState = {
        selectedView: undefined,
        profileSettings: { mainTabIndex: 0 },
        portalUserManagement: {
          userTableTabIndex: 0,
          newUserModalStatus: false,
          confirmDeleteUserModalStatus: false,
          newUserCreationProgress: 1,
          newUserAudioConferenceType: AudioConferenceType.Internal,
          showDisabledUser: false,
          selectedUser: null,
          selectedUserMap: new Map(),
          userProfileSettingsTabIndex: 0,
          selectedUserPersistentSpace: {}
        },
        license: {
          licenseArray: [],
          modalStatus: false,
          modalText: ""
        },
        theme: {
          themeMap: null,
          graphicsList: null,
          selectedThemeId: 0,
          selectedTheme: null,
          isThemeEditorModalOpen: false,
          themeEditorTabIndex: 0
        }
      };
      return newState;
    case PORTAL_DOWNLOAD_REPORT_DIALOG_VISIBLE:
      newState = { ...state, downloadReportDialogVisible: action.payload };
      return newState;
    case PORTAL_SETTINGS_SET_MAIN_TAB_INDEX:
      newState = { ...state };
      newState.profileSettings.mainTabIndex = action.payload;
      return newState;
    case PORTAL_UPDATE_PROFILE_PROMPT:
      newState = { ...state };
      newState.profilePromptLevel = action.payload.profilePromptLevel;
      newState.profilePromptContents = action.payload.profilePromptContents;
      return newState;
    case PORTAL_USERMANAGEMENT_SET_TAB_INDEX:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        userTableTabIndex: action.payload
      };
      return newState;
    case UPDATE_PORTAL_USERMANAGEMENT_USER_LIST:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        userList: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_SELECTED_USER:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        selectedUser: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_SELECTED_USERS_ID_LIST:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement
      };
      newState.portalUserManagement.selectedUserMap.clear();
      action.payload.forEach(rowData => {
        newState.portalUserManagement.selectedUserMap.set(
          rowData.userID,
          rowData
        );
      });
      return newState;
    case UPDATE_PORTAL_USERMANAGEMENT_SELECTED_USER_PERSISTENT_SPACE:
      newState = { ...state };
      newState.portalUserManagement = { ...newState.portalUserManagement };
      newState.portalUserManagement.selectedUserPersistentSpace = {};
      if (
        newState.portalUserManagement.selectedUser &&
        action.payload != null
      ) {
        for (let [key, value] of Object.entries(action.payload)) {
          newState.portalUserManagement.selectedUserPersistentSpace.persistentSpaceName =
            key;
          for (let settingName in value) {
            newState.portalUserManagement.selectedUserPersistentSpace[
              settingName
            ] = value[settingName];
          }
        }
      }
      return newState;
    case SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_STATUS:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        newUserModalStatus: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_NEW_USER_CREATE_STEP:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        newUserCreationProgress: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_PROMPT:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        newUserModalPromptLevel: action.payload.promptLevel,
        newUserModalPromptContents: action.payload.promptContents
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_INFORMATION:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        newUserProfileInformation: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_CONF_TYPE:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        newUserAudioConferenceType: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        externalConferences: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_SELECTED_EXTERNAL_CONFERENCE:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        selectedExternalConferenceID: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_BUTTON_LOADER:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        buttonLoader: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_SHOW_DISABLED_CHECKBOX:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        showDisabledUser: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_CONFIRM_DELETE_USER_MODAL_STATUS:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        confirmDeleteUserModalStatus: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_PROFILE_SETTING_TAB_INDEX:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        userProfileSettingsTabIndex: action.payload
      };
      return newState;
    case SET_PORTAL_USERMANAGEMENT_SELECTED_USER_AUDIO_CONFERENCE:
      newState = { ...state };
      newState.portalUserManagement = {
        ...newState.portalUserManagement,
        selectedUserAudioConference: action.payload
      };
      return newState;
    case SET_PORTAL_USER_AUDIO_CONF_DETAILS:
      newState = { ...state };
      newState.audioConferenceDetails = action.payload;
      return newState;
    case SET_PORTAL_THEMES:
      newState = { ...state };
      newState.theme.themeMap = [];
      for (let [key, theme] of Object.entries(action.payload)) {
        if (theme.id === 1 || theme.id === 2) {
          theme.imagesLocation = "assets";
        } else {
          theme.imagesLocation = "images";
        }
        newState.theme.themeMap[key] = theme;
        if (state.theme.selectedThemeId === theme.id) {
          newState.theme.selectedTheme = theme;
        }
      }
      return newState;
    case SET_PORTAL_LICENSE:
      newState = { ...state };
      newState.license = {
        ...newState.license,
        licenseArray: action.payload
      };
      return newState;
    case SET_PORTAL_LICENSE_MODAL_WINDOW_STATUS:
      newState = { ...state };
      newState.license = {
        ...newState.license,
        modalStatus: action.payload
      };
      return newState;
    case SET_PORTAL_LICENSE_MODAL_WINDOW_TEXT:
      newState = { ...state };
      newState.license = {
        ...newState.license,
        modalText: action.payload
      };
      return newState;
    case SET_PORTAL_SELECTED_THEME_ID:
      newState = { ...state };
      newState.theme.selectedThemeId = action.payload.themeId;
      if (newState.theme.themeMap != null) {
        for (let [, theme] of Object.entries(newState.theme.themeMap)) {
          if (theme.id === action.payload.themeId) {
            newState.theme.selectedTheme = theme;
          }
        }
      }
      return newState;
    case SET_THEME_EDITOR_MODAL_STATE:
      newState = { ...state };
      newState.theme.isThemeEditorModalOpen = action.payload;
      return newState;
    case SET_THEME_EDITOR_TAB_INDEX:
      newState = { ...state };
      newState.theme.themeEditorTabIndex = action.payload;
      return newState;
    case SET_GRAPHICS_LIST:
      newState = { ...state };
      newState.theme.graphicsList = action.payload;
      return newState;
    default:
      return state;
  }
}
