import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { Loading } from "../greetings";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  }
});

class LoadingPage extends Component {
  componentDidMount() {}

  render() {
    const { classes, session } = this.props;
    const isLoggedIn = session.isLoggedIn;
    const component = <Loading />;

    return (
      <Fragment>
        <CssBaseline />
        <Header displayLogout={isLoggedIn} />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar displayContent={isLoggedIn} />
          <MainContent component={component} />
          <RightBar displayContent={isLoggedIn} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(LoadingPage))
);
