import {
  USERS_UPDATE,
  USER_LOGOUT,
  WEBSOCKET_RECONNECT
} from "../actions/types";

export default function usersReducer(state = {}, action) {
  let newState = {};
  switch (action.type) {
    case USERS_UPDATE:
      newState = mergeData({ ...state }, action.payload);
      return newState;
    case WEBSOCKET_RECONNECT:
    case USER_LOGOUT:
      newState = {};
      return newState;
    default:
      return state;
  }
}

function mergeData(data, updatedData) {
  if (!updatedData.connected) {
    data[updatedData.id] = undefined;
  } else {
    // The user events do not use change tracking, so
    // the existing user object should be overwritten
    data[updatedData.id] = { ...updatedData };
  }
  //TODO perform sorting at some point
  return data;
}
