import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import LocalizedText from "../reusable/LocalizedText";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import { injectIntl } from "react-intl";
import {
  getIntl,
  TitleNewLine,
  isConnected,
  isConnectedToOperator
} from "../../utils";
import {
  setSignalOperatorPopperExpansion,
  handleSignalOperatorRequest
} from "../../actions";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  textIcon: {
    width: "1em"
  }
});

class SignalOperatorPopper extends Component {
  state = {
    anchorEl: null
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  getSignalOperatorIconButton = () => {
    const { session, classes, intl } = this.props;
    const connected = isConnected(session.callState);

    let title = intl.formatMessage(getIntl("operatorSignalControl"));
    let click;
    let color;
    if (connected && !isConnectedToOperator(session.callState)) {
      if (!session.operatorSignaled) {
        title +=
          TitleNewLine + intl.formatMessage(getIntl("clickToSignalOperator"));
        color = "connect";
      } else {
        title +=
          TitleNewLine +
          intl.formatMessage(getIntl("clickToCancelOperatorSignal"));
        color = "disconnect";
      }
      click = () =>
        this.props.handleSignalOperatorRequest(
          session.userId,
          !session.operatorSignaled
        );
    } else {
      if (isConnectedToOperator(session.callState)) {
        color = "active";
      } else {
        title += TitleNewLine + intl.formatMessage(getIntl("controlDisabled"));
        color = "inactive";
      }
      click = event => this.handleClickPopper(event);
    }

    let iconName;
    if (session.operatorSignaled) {
      iconName = "cancelSignalOperator";
    } else {
      iconName = "signalOperator";
    }

    return (
      <IconButton
        id="signalOperatorPopperButton"
        className={classes.popperIconButton}
        onClick={click}
        title={title}
        ref={this.iconRef}
      >
        <SvgIcon iconName={iconName} color={color} />
      </IconButton>
    );
  };

  handleClickPopper = event => {
    this.props.setSignalOperatorPopperExpansion(true);
  };

  handleConnectClick = () => {
    this.closePopper();
    //TODO - not very react-like
    document.getElementById("connectPopperButton").click();
  };

  closePopper = () => {
    this.props.setSignalOperatorPopperExpansion(false);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.session.callState !== prevProps.session.callState) {
      if (isConnectedToOperator(this.props.session.callState)) {
        if (!isConnectedToOperator(prevProps.session.callState)) {
          this.props.setSignalOperatorPopperExpansion(true);
        }
      } else {
        this.closePopper();
      }
    }
  }

  render() {
    const { classes, popper } = this.props;
    const { anchorEl } = this.state;
    const open =
      anchorEl != null && popper.signalOperator.expandSignalOperatorPopper;

    return (
      <Fragment>
        {this.getSignalOperatorIconButton()}

        <CTXBasePopper
          id="signalOperatorPopper"
          open={open}
          anchorEl={anchorEl}
          closePopper={this.closePopper}
        >
          {!isConnectedToOperator(this.props.session.callState) ? (
            <Fragment>
              <LocalizedText
                value="noAudioDetected"
                variant="h6"
                className={classes.header}
              />
              {window.CtxAppConfigurations.audioConnectionControlVisible && (
                <div className={classes.content}>
                  <LocalizedText value="click" inline="true" />
                  <IconButton
                    disabled={false}
                    onClick={() => this.handleConnectClick()}
                  >
                    <SvgIcon
                      iconName="connectCall"
                      color="connect"
                      className={classes.textIcon}
                    />
                  </IconButton>
                  <LocalizedText value="toGetConnected" inline="true" />
                </div>
              )}
            </Fragment>
          ) : (
            <LocalizedText value="speakingToTheOperator" />
          )}
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, conference, popper }) => ({
  session,
  conference,
  popper
});

const mapDispatchToProps = dispatch => ({
  setSignalOperatorPopperExpansion: isOpen =>
    dispatch(setSignalOperatorPopperExpansion(isOpen)),
  handleSignalOperatorRequest: (userId, signal) =>
    dispatch(handleSignalOperatorRequest(userId, signal))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(SignalOperatorPopper)
    )
  )
);
