import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import LocalizedText from "../reusable/LocalizedText";
import { QAPopperContents } from "../poppers/QAPopper";
import { isQAActive } from "../../utils";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    color: theme.colors.disconnectIconColor,
    margin: "10px 0px 10px 0px"
  }
});

class ConferenceQAIndicator extends Component {
  getStatus = () => {
    const { qa, conference } = this.props;
    const { popperContents, dataQAState } = qa;
    const audioQAInProgress = isQAActive(conference);
    const inactiveQA =
      popperContents === QAPopperContents.DEFAULT ||
      (!audioQAInProgress && !dataQAState);

    return inactiveQA ? null : <LocalizedText value="qa" variant="subtitle2" />;
  };

  render() {
    const { classes } = this.props;
    return <div className={classes.root}>{this.getStatus()}</div>;
  }
}

const mapStateToProps = ({ qa, conference }) => ({
  qa,
  conference
});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps)(ConferenceQAIndicator))
);
