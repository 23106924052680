import { useRef, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { getIntl } from "../../utils";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      // The minHeight is needed so that the bottom
      // of the transcript div isn't cut off.
      minHeight: 0,
      height: "100%",
      padding: "0 20px",
      color: theme.colors.primaryTextColor
    },
    headingDivider: {
      borderTop: `3px solid ${theme.colors.secondaryMainColor}`
    },
    transcript: {
      padding: "8px 8px 8px 0",
      overflowY: "auto"
    },
    scrollBar: {
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.secondaryMainColor,
        borderRadius: "4px",
        border: "none"
      },
      "&::-webkit-scrollbar-track-piece": {
        background: theme.colors.sideBarBackgroundColor,
        borderRadius: "4px"
      },
      "&::-webkit-scrollbar-corner": {
        background: theme.colors.sideBarBackgroundColor
      },
      scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
      scrollbarWidth: "thin"
    },
    speakersHeading: {
      fontSize: "1.1rem"
    },
    partialTranscript: {
      color: "#aaa"
    },
    divider: {
      borderTop: "1px solid #808080",
      margin: "8px 0"
    }
  }),
  { name: "Transcript" }
);

export default function Transcript({ stylesCopied }) {
  const classes = useStyles();
  const intl = useIntl();

  const finalTranscript = useSelector(state => state.session.transcript);
  const partialTranscript = useSelector(
    state => state.session.partialTranscript
  );

  const transcriptRef = useRef(null);
  const nearBottom = useRef(true);

  useEffect(() => {
    transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
  }, [stylesCopied]);
  useEffect(() => {
    if (nearBottom.current) {
      transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
    }
  }, [finalTranscript, partialTranscript]);

  function handleScroll() {
    if (
      transcriptRef.current.scrollHeight -
        transcriptRef.current.scrollTop -
        transcriptRef.current.offsetHeight <
      100
    ) {
      nearBottom.current = true;
    } else {
      nearBottom.current = false;
    }
  }

  function createHeading(speakers) {
    if (speakers == null || speakers.length === 0) {
      return intl.formatMessage(getIntl("unknownUser"));
    } else if (speakers.length === 1) {
      return speakers[0];
    } else {
      speakers.sort((a, b) => a.localeCompare(b));

      if (speakers.length === 2) {
        return (
          speakers[0] +
          " " +
          intl.formatMessage(getIntl("and")) +
          " " +
          speakers[1]
        );
      } else {
        let heading = "";
        for (let i = 0; i < speakers.length; i++) {
          if (i < speakers.length - 1) {
            heading += speakers[i] + ", ";
          } else {
            heading += intl.formatMessage(getIntl("and")) + " " + speakers[i];
          }
        }
        return heading;
      }
    }
  }

  const transcript = [];
  for (const result of finalTranscript) {
    if (result.type === "entity") {
      if (
        result.entityClass === "phonetic alphanum" ||
        result.entityClass === "tail number"
      ) {
        for (const spokenResult of result.spokenForm) {
          transcript.push({ ...spokenResult, partial: false });
        }
        transcript.push(result);
      } else {
        for (const writtenResult of result.writtenForm) {
          transcript.push({ ...writtenResult, partial: false });
        }
      }
    } else {
      transcript.push({ ...result, partial: false });
    }
  }
  for (const result of partialTranscript) {
    transcript.push({ ...result, partial: true });
  }

  const speakerTranscript = [];

  if (transcript.length > 0) {
    let speakers;
    let contents = [];

    for (const result of transcript) {
      if (createHeading(result.speakers) !== speakers) {
        if (contents.length > 0) {
          speakerTranscript.push(
            <Fragment key={speakerTranscript.length}>
              <Typography className={classes.speakersHeading} variant="h6">
                {speakers}
              </Typography>
              <Typography>{contents}</Typography>
              <div className={classes.divider} />
            </Fragment>
          );
        }

        speakers = createHeading(result.speakers);
        contents = [];
      } else if (result.type !== "punctuation") {
        contents.push(" ");
      }

      let content;
      if (result.entityClass === "tail number") {
        content = (
          <>
            (<mark>{result.content}</mark>)
          </>
        );
      } else if (result.entityClass === "phonetic alphanum") {
        content = <>({result.content})</>;
      } else {
        content = result.content;
      }

      contents.push(
        result.partial ? (
          <span key={contents.length} className={classes.partialTranscript}>
            {content}
          </span>
        ) : (
          <Fragment key={contents.length}>{content}</Fragment>
        )
      );
    }

    speakerTranscript.push(
      <Fragment key={speakerTranscript.length}>
        <Typography className={classes.speakersHeading} variant="h6">
          {speakers}
        </Typography>
        <Typography>{contents}</Typography>
      </Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <LocalizedText value="transcript" variant="h6" />
      <div className={classes.headingDivider} />
      <div
        ref={transcriptRef}
        className={classNames(classes.transcript, classes.scrollBar)}
        onScroll={handleScroll}
      >
        {speakerTranscript}
      </div>
    </div>
  );
}
