import React, { Component } from "react";
import { connect } from "react-redux";
import { hideParticipantListPopout } from "./actions";
import { ParticipantList } from "../";
import CtxNewWindow from "../../NewWindow/CtxNewWindow";
import SvgIcon from "../../Icons/SvgIcon";

/***
 * If ParticipantsPopoutState.OPEN  ---> PopoutWindow is shown
 * If ParticipantsPopoutState.CLOSE ----> PopoutWindow is closed
 *
 * Stateful component that sets ParticipantsPopoutState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set ParticipantsPopoutState.OPEN in the store.
 * This component reads from the redux store to decide if the popout window should be shown. *
 */

// ParticipantsPopoutState is exported for the reducer to set OPEN and CLOSE states.
export const ParticipantsPopoutState = {
  CLOSE: 1,
  OPEN: 2
};

export const participantListPopoutWindowName = "ParticipantListWindow";

const defaultWindowWidth = 980;
const defaultWindowHeight = 600;
const minWindowWidth = 391; //375 inner + 16 scrollbar
const minWidthCheckTimeout = 150;

let resizeTimer;

class ParticipantListPopoutWindow extends Component {
  state = {
    containerHeight: defaultWindowHeight,
    containerWidth: defaultWindowWidth,
    popoutRef: undefined
  };

  ensureMinWidth = (width, ref) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (width < minWindowWidth) {
        ref.window.resizeTo(minWindowWidth, ref.window.outerHeight);
      }
    }, minWidthCheckTimeout);
  };

  handleResize = () => {
    if (this.state.popoutRef) {
      this.ensureMinWidth(
        this.state.popoutRef.window.outerWidth,
        this.state.popoutRef
      );
    }
    this.setState({
      ...this.state,
      containerHeight: this.selfRef.offsetHeight,
      containerWidth: this.selfRef.offsetWidth
    });
  };

  handleUnload = () => {
    this.props.hideParticipantListPopout();
    this.setState({
      ...this.state,
      containerHeight: defaultWindowHeight,
      containerWidth: defaultWindowWidth,
      popoutRef: undefined
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.popoutRef && this.popoutRef) {
      this.setState({ ...this.state, popoutRef: this.popoutRef });
    } else if (
      prevState.popoutRef &&
      this.state.popoutRef &&
      prevState.popoutRef.window.outerWidth !==
        this.state.popoutRef.window.outerWidth
    ) {
      this.setState({
        ...this.state,
        containerHeight: this.selfRef.offsetHeight,
        containerWidth: this.selfRef.offsetWidth
      });
    }
  }

  render() {
    if (
      this.props.participantListPopoutWindow.windowStatus ===
      ParticipantsPopoutState.OPEN
    ) {
      return (
        <CtxNewWindow
          ref={r => (this.popoutRef = r)}
          onUnload={this.handleUnload}
          resizeCallback={this.handleResize}
          features={{
            width: defaultWindowWidth,
            height: defaultWindowHeight,
            location: 0,
            scrollbars: 0
          }}
          title="ConnectNow-ParticipantList"
          name={participantListPopoutWindowName}
        >
          <div
            className="participantListWindow"
            ref={r => (this.selfRef = r)}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden"
            }}
          >
            {/* TODO fix this - prefetch partylist icons so they load when used*/}
            <div style={{ display: "none" }}>
              <SvgIcon iconName="participantList" />
              <SvgIcon iconName="columnSortedAscending" />
              <SvgIcon iconName="columnSortedDescending" />
              <SvgIcon iconName="columnUnsorted" />
              <SvgIcon iconName="connectCall" />
              <SvgIcon iconName="disconnectCall" />
              <SvgIcon iconName="audioMute" />
              <SvgIcon iconName="audioUnmute" />
              <SvgIcon iconName="disconnectAll" />
              <SvgIcon iconName="collapse" />
              <SvgIcon iconName="expand" />
              <SvgIcon iconName="popout" />
              <SvgIcon iconName="close" />
              <SvgIcon iconName="search" />
              <SvgIcon iconName="disconnectedParty" />
              <SvgIcon iconName="audioParty" />
              <SvgIcon iconName="hostParty" />
            </div>
            <ParticipantList
              containerHeight={this.state.containerHeight}
              containerWidth={this.state.containerWidth}
            />
          </div>
        </CtxNewWindow>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ participantListPopoutWindow }) => ({
  participantListPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  hideParticipantListPopout: () => dispatch(hideParticipantListPopout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantListPopoutWindow);
