import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { Card, Typography, CardContent, CardHeader } from "@material-ui/core";
import { getIntl } from "../../utils";

const styles = theme => ({
  root: {
    minWidth: "780px",
    display: "flex",
    flexDirection: "column"
  },
  rootFullHeight: {
    height: "100%"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.primaryTextColor}`
  },
  rootBackgroundColor: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  cardHeader: {
    padding: "16px 40px 5px 40px"
  },
  cardHeaderTitle: {
    height: "35px"
  },
  cardContent: {
    padding: "0px 40px 5px 40px",
    flexGrow: 1
  },
  conferenceDetailsContainer: {
    padding: "10px 50px 0px 30px"
  },
  valueTypography: {
    width: "75%",
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  }
});

class ConferenceDetailsCard extends Component {
  //Card header
  getCardHeaderControl = () => {
    const { classes, intl } = this.props;

    return (
      <CardHeader
        title={
          this.props.conferenceName
            ? this.props.conferenceName
            : intl.formatMessage(getIntl("na"))
        }
        titleTypographyProps={{ color: "inherit", variant: "h5" }}
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle
        }}
      />
    );
  };
  //Card Content
  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        <div className={classes.hrDivPrimary} />
        {this.getConferenceDetailsControls()}
      </CardContent>
    );
  };

  getConferenceDetailsControls = () => {
    const { classes, intl } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexColumn,
          classes.conferenceDetailsContainer
        )}
      >
        {this.getConfDetailsEntryControls(
          intl.formatMessage(getIntl("conferenceName")),
          this.props.conferenceName
            ? this.props.conferenceName
            : intl.formatMessage(getIntl("na"))
        )}
        {this.getConfDetailsEntryControls(
          intl.formatMessage(getIntl("billingCode")),
          this.props.billingCode
            ? this.props.billingCode
            : intl.formatMessage(getIntl("na"))
        )}
        {this.getConfDetailsEntryControls(
          intl.formatMessage(getIntl("hostPasscode")),
          this.props.hostPasscode
            ? this.props.hostPasscode
            : intl.formatMessage(getIntl("na"))
        )}
        {this.getConfDetailsEntryControls(
          intl.formatMessage(getIntl("guestPasscode")),
          this.props.guestPasscode
            ? this.props.guestPasscode
            : intl.formatMessage(getIntl("na"))
        )}
      </div>
    );
  };

  getConfDetailsEntryControls = (labelName, value) => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.justifyContentSpaceBetween
        )}
      >
        <Typography variant="body1" color="inherit">
          {labelName}
        </Typography>
        <div className={classes.valueTypography}>
          <Typography variant="body1" color="inherit">
            {value}
          </Typography>
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classNames(
          classes.root,
          this.props.fullHeight ? classes.rootFullHeight : undefined,
          this.props.backgroundColor ? undefined : classes.rootBackgroundColor
        )}
        style={{
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : undefined
        }}
      >
        {this.getCardHeaderControl()}
        {this.getCardContentControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(ConferenceDetailsCard)
    )
  )
);
