import React, { Component } from "react";
import { connect } from "react-redux";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import { Pages } from "./AudioComponent";
import { withStyles } from "@material-ui/core/styles";
import { isWebRTCAudioEnabled } from "../../utils";

const styles = {
  root: {
    alignSelf: "flex-start",
    marginBottom: "10px"
  }
};

class ButtonsPage extends Component {
  state = {};

  handleClick = value => {
    this.props.onClick(value);
  };

  render() {
    const { classes, session } = this.props;
    return (
      <React.Fragment>
        <LocalizedText
          className={classes.root}
          value="chooseYourAudioConnection"
          variant="h6"
        />

        {session.callMeConfig && (
          <TextButton onClick={() => this.handleClick(Pages.callMe)}>
            <LocalizedText value="callMe" />
          </TextButton>
        )}

        <TextButton onClick={() => this.handleClick(Pages.dialIn)}>
          <LocalizedText value="dialIn" />
        </TextButton>

        {isWebRTCAudioEnabled(session.webRTCCallConfig) && (
          <TextButton onClick={() => this.handleClick(Pages.connectWithDevice)}>
            <LocalizedText value="connectWithDevice" />
          </TextButton>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(connect(mapStateToProps)(ButtonsPage));
