import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import {
  HostGreeting,
  Loading,
  GuestGreeting,
  WaitingRoomGreeting
} from "../greetings";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";
import {
  authenticateUser,
  setFullScreenMode,
  setHeaderBarsVisibility,
  setTalkerIndicatorOption
} from "../../actions";
import {
  isHost,
  getUrlParam,
  redirectUnsupportedBrowsers,
  getFullscreenElement,
  isCM
} from "../../utils";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  showNotificationWindow,
  setNotificationLevel,
  NotificationLevel,
  setNotificationType,
  NotificationType
} from "../notification";
import Logger from "../../Logger";

const logger = new Logger("MainPage");

const styles = theme => ({
  ...theme.style.basePageLayout,
  headerEnter: {
    opacity: 0.01
  },
  headerEnterActive: {
    opacity: 1,
    transition: "opacity 300ms ease-in"
  },
  headerExit: {
    opacity: 1
  },
  headerExitActive: {
    opacity: 0,
    transition: "opacity 500ms ease-in"
  }
});

class MainPage extends Component {
  constructor(props) {
    super(props);
    redirectUnsupportedBrowsers();
    this.setHeaderBarsInvisibleTimeout = null;
    this.state = {
      width: 0
    };
  }

  authenticate = (session, history) => {
    const u = getUrlParam("vi");
    const isLoggedIn = session.isLoggedIn;

    switch (isLoggedIn) {
      case false:
        if (!isCM()) {
          logger.debug(
            "MainPage not logged in - userId: %s %s",
            session.userId,
            u
          );
          history.replace("login");
        }
        break;
      case true:
        logger.debug("MainPage userId: %d", session.userId);
        break;
      case undefined:
      default:
        if (!isCM()) {
          logger.debug(
            "MainPage login undefined - userId: %s %s",
            session.userId,
            u
          );
          this.props.authenticateUser(u);
        }
        break;
    }
  };

  componentDidMount() {
    const { session, history } = this.props;
    this.authenticate(session, history);
    if (!isCM()) {
      window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    }
    this.updateContainerSize();
    document.addEventListener("fullscreenchange", this.fullScreenChangeHandler);
    document.addEventListener(
      "webkitfullscreenchange",
      this.fullScreenChangeHandler
    );
  }

  componentWillUnmount() {
    if (!isCM()) {
      window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }
    document.removeEventListener(
      "fullscreenchange",
      this.fullScreenChangeHandler
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.fullScreenChangeHandler
    );
  }

  adjustUrl = () => {
    const { session, history } = this.props;
    if (session.userId != null && !session.vetted) {
      history.replace("/waiting?vi=" + session.userId);
    }
  };

  componentDidUpdate(prevProps) {
    const { session, history, participants } = this.props;

    if (prevProps.session.isLoggedIn !== session.isLoggedIn) {
      this.authenticate(session, history);
    }

    if (
      prevProps.session.vetted !== this.props.session.vetted &&
      !this.props.session.vetted
    ) {
      this.adjustUrl();
    }

    let isHostSession = isHost(session);

    //push notifications to hosts when there are parties in the WaitingRoom
    if (
      isHostSession &&
      participants.waitingRoomParties.length > 0 &&
      participants.waitingRoomParties.length >
        prevProps.participants.waitingRoomParties.length &&
      !session.recorder
    ) {
      this.props.setNotificationLevel(NotificationLevel.INFO);
      this.props.setNotificationType(NotificationType.PARTY_IN_WAITING_ROOM);
      this.props.showNotificationWindow();
      let notificationMediaElement =
        document.getElementById("notificationMedia");
      notificationMediaElement.play();
    }

    let previousMergedCallState = prevProps.session.mergedCallState;
    let mergedCallState = session.mergedCallState;

    if (
      previousMergedCallState !== "connected" &&
      mergedCallState === "connected" &&
      session.recorder
    ) {
      this.setHeaderBarsVisibilityAndResize(false);
      this.props.setTalkerIndicatorOption(1);
    }
  }

  handleWindowBeforeUnload = event => {
    event.returnValue = "onbeforeunload";
  };

  fullScreenChangeHandler = () => {
    if (getFullscreenElement()) {
      this.props.setFullScreenMode(true);
      this.updateContainerSize();
      window.dispatchEvent(new Event("resize"));
    } else {
      this.props.setFullScreenMode(false);
      if (this.setHeaderBarsInvisibleTimeout != null) {
        clearTimeout(this.setHeaderBarsInvisibleTimeout);
      }
      this.setHeaderBarsVisibilityAndResize(true);
      this.props.setTalkerIndicatorOption(2);
    }
  };

  updateContainerSize = () => {
    if (this.rootContainer) {
      const width = this.rootContainer.clientWidth;
      this.setState({
        width: width
      });
    }
  };

  handleFullScreenMouseMove(event) {
    const { isHeaderBarsVisible } = this.props.session;

    if (isHeaderBarsVisible) {
      if (!this.isMouseOnHeaderBarsArea(event.clientX, event.clientY)) {
        if (!this.isHideProhibited()) {
          this.setHeaderBarsVisibilityAndResize(false);
          this.props.setTalkerIndicatorOption(1);
        }
      } else {
        if (this.setHeaderBarsInvisibleTimeout != null) {
          clearTimeout(this.setHeaderBarsInvisibleTimeout);
        }
        this.delayHidingHeaderBars(10000);
      }
    } else {
      if (this.isMouseOnShowHeaderBarsArea(event.clientX, event.clientY)) {
        this.setHeaderBarsVisibilityAndResize(true);
        this.props.setTalkerIndicatorOption(2);
      }
    }
  }

  isMouseOnHeaderBarsArea = (posX, posY) => {
    return posX <= 80 || posY <= 80 || this.state.width - posX <= 80;
  };

  isMouseOnShowHeaderBarsArea = (posX, posY) => {
    return posX <= 20 || posY <= 5 || this.state.width - posX <= 20;
  };

  delayHidingHeaderBars = delay => {
    this.setHeaderBarsInvisibleTimeout = setTimeout(() => {
      if (this.isHideProhibited()) {
        this.delayHidingHeaderBars(delay);
        return;
      }

      this.props.setHeaderBarsVisibility(false);
      this.props.setTalkerIndicatorOption(1);

      this.updateContainerSize();
      window.dispatchEvent(new Event("resize"));
    }, delay);
  };

  setHeaderBarsVisibilityAndResize = isVisible => {
    this.props.setHeaderBarsVisibility(isVisible);
    window.dispatchEvent(new Event("resize"));
  };

  isHideProhibited = () => {
    const { session, popper, qa } = this.props;
    const { isHamburgMenuOpen, isLogOutModalOpen } = session;
    const { connect, share, more, audio, signalOperator } = popper;

    return (
      isHamburgMenuOpen ||
      isLogOutModalOpen ||
      connect.expandConnectPopper ||
      share.expandSharePopper ||
      more.expandMorePopper ||
      audio.expandAudioPopper ||
      signalOperator.expandSignalOperatorPopper ||
      qa.popperOpen
    );
  };

  render() {
    const { classes, session } = this.props;
    const {
      talkerIndicatorOption,
      talkerPartySet,
      isHeaderBarsVisible,
      fullScreenMode
    } = session;
    const isLoggedIn = session.isLoggedIn;
    const vetted = session.vetted;
    const host = isHost(session);
    const isLoggedInAndVetted = isLoggedIn && vetted;
    const component = !isLoggedIn ? (
      <Loading />
    ) : isLoggedIn && !vetted ? (
      <WaitingRoomGreeting />
    ) : window.CtxAppConfigurations.customer === "TCAD" || host ? (
      <HostGreeting />
    ) : (
      <GuestGreeting />
    );

    return (
      <Fragment>
        <CssBaseline />
        <TransitionGroup>
          {isHeaderBarsVisible && (
            <CSSTransition
              classNames={{
                enter: classes.headerEnter,
                enterActive: classes.headerEnterActive,
                exit: classes.headerExit,
                exitActive: classes.headerExitActive
              }}
              timeout={{ enter: 300, exit: 900 }}
            >
              <Header
                enableTalkerIndicator={
                  talkerIndicatorOption === 2 && talkerPartySet.size > 0
                }
                displayLogout={isLoggedInAndVetted}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
        <div
          className={classes.root}
          id="mainContent"
          onMouseMove={event => {
            if (fullScreenMode) this.handleFullScreenMouseMove(event);
          }}
          ref={rootContainer => {
            this.rootContainer = rootContainer;
          }}
        >
          {isHeaderBarsVisible && <div className={classes.headerSpacer} />}
          {isHeaderBarsVisible && (
            <LeftBar displayContent={isLoggedInAndVetted} isHost={host} />
          )}
          <MainContent component={component} isHost={host} />
          {isHeaderBarsVisible && (
            <RightBar displayContent={isLoggedInAndVetted} isHost={host} />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, popper, qa, participants }) => ({
  session,
  popper,
  qa,
  participants
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: u => dispatch(authenticateUser(u)).then(),
  setFullScreenMode: isEnabled => dispatch(setFullScreenMode(isEnabled)),
  setHeaderBarsVisibility: visibility =>
    dispatch(setHeaderBarsVisibility(visibility)),
  setTalkerIndicatorOption: option =>
    dispatch(setTalkerIndicatorOption(option)),
  showNotificationWindow: () => dispatch(showNotificationWindow()),
  setNotificationLevel: level => dispatch(setNotificationLevel(level)),
  setNotificationType: type => dispatch(setNotificationType(type))
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage))
);
