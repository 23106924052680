import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { getIntl } from "../../utils";
import { UserPromptLevel, setProfileUserPrompt } from "./actions";
import ConferenceDetailsCard from "./ConferenceDetailsCard";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  mainContentsContainer: {
    margin: "20px",
    display: "flex",
    flexDirection: "column"
  },
  upperContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto"
  },
  conferenceDetailsCardContainer: {
    padding: "0px"
  },
  userPrompt: {
    height: "30px"
  },
  userPromptInfo: {
    color: theme.colors.primaryTextColor
  },
  userPromptError: {
    color: theme.colors.errorMainColor
  },
  userPromptSuccess: {
    color: theme.colors.primaryMainColor
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignSelfFlexStart: {
    alignSelf: "flex-start"
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  }
});

class ConferenceView extends Component {
  componentWillUnmount() {
    this.props.setProfileUserPrompt(UserPromptLevel.ERROR, "");
  }

  getConferenceViewControls = () => {
    const { classes, portal, audioConferenceDetails } = this.props;
    const { profilePromptContents } = portal;

    if (audioConferenceDetails != null) {
      return (
        <Fragment>
          <div
            className={classNames(classes.upperContainer, classes.scrollBar)}
          >
            {this.getUserPromptControls()}
            <div
              className={classNames(
                classes.conferenceDetailsCardContainer,
                classes.displayFlexColumn,
                profilePromptContents !== ""
                  ? classes.justifyContentSpaceBetween
                  : classes.justifyContentFlexEnd
              )}
            >
              <ConferenceDetailsCard
                backgroundColor={this.props.backgroundColor}
                conferenceName={
                  audioConferenceDetails
                    ? audioConferenceDetails.ConferenceName
                    : undefined
                }
                billingCode={
                  audioConferenceDetails
                    ? audioConferenceDetails.BillingCode
                    : undefined
                }
                hostPasscode={
                  audioConferenceDetails
                    ? audioConferenceDetails.HostPasscode
                    : undefined
                }
                guestPasscode={
                  audioConferenceDetails
                    ? audioConferenceDetails.GuestPasscode
                    : undefined
                }
              />
            </div>
          </div>
        </Fragment>
      );
    }
  };

  getUserPromptControls = () => {
    const { classes, portal } = this.props;
    const { profilePromptLevel, profilePromptContents } = portal;

    return (
      <Typography
        className={classNames(
          profilePromptLevel === UserPromptLevel.INFO
            ? classes.userPromptInfo
            : undefined,
          profilePromptLevel === UserPromptLevel.ERROR
            ? classes.userPromptError
            : undefined,
          profilePromptLevel === UserPromptLevel.SUCCESS
            ? classes.userPromptSuccess
            : undefined,
          classes.userPrompt,
          classes.alignSelfFlexStart
        )}
        variant="body1"
      >
        {profilePromptContents
          ? this.props.intl.formatMessage(getIntl(profilePromptContents))
          : ""}
      </Typography>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.mainContentsContainer}>
          {this.getConferenceViewControls()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  setProfileUserPrompt: (promptLevel, promptContents) =>
    dispatch(setProfileUserPrompt(promptLevel, promptContents))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConferenceView))
  )
);
