import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import DatePicker from "react-date-picker";
import classNames from "classnames";
import getUserLocale from "get-user-locale";

const styles = theme => ({
  datePicker: {
    margin: "10px 0 10px 0",
    zIndex: 11,
    "& input": { color: theme.colors.primaryTextColor },
    "& > div": { border: 0 },
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  underline: {
    borderBottom: "2px solid currentColor"
  },
  calendar: {
    color: theme.colors.secondaryTextColor
  }
});

/*
 * Attribute "underline" controls underline display of date picker. Type: boolean
 */

class CTXDatePicker extends Component {
  render() {
    const { classes } = this.props;

    return (
      <DatePicker
        className={classNames(
          classes.datePicker,
          this.props.underline ? classes.underline : undefined
        )}
        calendarClassName={classes.calendar}
        onChange={this.props.onChange}
        value={this.props.value}
        calendarIcon={null}
        clearIcon={null}
        name={this.props.id}
        minDate={this.props.minDate}
        locale={getUserLocale()}
      />
    );
  }
}

export default withTheme(withStyles(styles)(CTXDatePicker));
