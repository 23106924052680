import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PortalLogo from "../Icons/PortalLogo";
import PoweredByCpx from "../Icons/PoweredByCpx";
import { PortalLeftBar, PortalContent } from "../../components/layouts";
import { SelectedViewType } from "../layouts/PortalLeftBar";
import PortalReport from "./PortalReport";
import PortalConferenceRecordings from "./PortalConferenceRecordings";
import PortalSettings from "./PortalSettings";
import PortalUserManagement from "./PortalUserManagement";
import PortalThemeManager from "./PortalThemeManager";
import PortalLicense from "./PortalLicense";
import LicenseExpiringBanner from "./LicenseExpiringBanner";

const styles = theme => ({
  ...theme.style.portalViewLayout,
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.primaryTextColor,
    gridColumn: "2",
    msGridColumn: "2"
  },
  logo: {
    maxHeight: "200px",
    width: "330px",
    objectFit: "contain",
    marginBottom: "20px"
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center"
  },
  compunetix: {
    position: "fixed",
    bottom: "2em",
    right: "6em",
    opacity: 100
  },
  compunetixDimension: {
    height: "20px"
  }
});

class PortalView extends Component {
  render() {
    const { classes, session } = this.props;

    let component;
    switch (this.props.portal.selectedView) {
      case SelectedViewType.VIEW_RECORDINGS:
        component = <PortalConferenceRecordings />;
        break;
      case SelectedViewType.VIEW_REPORTS:
        component = <PortalReport />;
        break;
      case SelectedViewType.SETTINGS:
        component = <PortalSettings />;
        break;
      case SelectedViewType.USER_MANAGEMENT:
        component = <PortalUserManagement />;
        break;
      case SelectedViewType.THEME_MANAGER:
        component = <PortalThemeManager />;
        break;
      case SelectedViewType.LICENSE:
        component = <PortalLicense />;
        break;
      default:
        component = (
          <Fragment>
            {session.portalUser && session.portalUser.admin && (
              <LicenseExpiringBanner />
            )}
            <div className={classes.logoContainer}>
              <PortalLogo classes={{ root: classes.logo }} logoType="body" />
            </div>
            <div className={classes.compunetix}>
              <PoweredByCpx classes={{ root: classes.compunetixDimension }} />
            </div>
          </Fragment>
        );
    }

    return (
      <div className={classes.root}>
        <PortalLeftBar />
        <PortalContent
          classes={{ root: classes.content }}
          component={component}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({
  session,
  portal
});

export default withTheme(
  withStyles(styles)(withRouter(connect(mapStateToProps)(PortalView)))
);
