import RoomClient from "./RoomClient";
import * as stateActions from "../../components/mediasoup/redux/stateActions";
import { setBlur, setExtensionAvailable } from "../../actions";
import deviceInfo from "../mediasoup/deviceInfo";
import MediaPipe from "./MediaPipe";
import VideoFilter from "./VideoFilter";
import { isExtensionAvailable } from "../../utils";

export default class RoomClientProvider {
  constructor(store) {
    this._store = store;

    window.addEventListener("load", () => {
      if (isExtensionAvailable()) {
        this._store.dispatch(setExtensionAvailable(true));
      } else {
        window.handleExtensionInstall = () => {
          window.downloadPlugin();
          this._store.dispatch(setExtensionAvailable(true));
        };
      }
    });
  }

  async init(peerId, userId, roomId, webSocketHandler) {
    // Get current device info.
    const device = deviceInfo();

    this._store.dispatch(
      stateActions.setMe({
        peerId,
        displayName: null,
        displayNameSet: false,
        device
      })
    );

    RoomClient.init({ store: this._store });

    this._roomClient = new RoomClient({
      roomId,
      peerId,
      userId,
      displayName: null,
      device,
      produce: true,
      consume: true,
      enableWebcamLayers: true,
      enableSharingLayers: true,
      roomClientProvider: this,
      webSocketHandler
    });

    await this._roomClient.join();
  }

  close() {
    if (
      this._roomClient !== null &&
      this._store.getState().room.state === "connected"
    ) {
      this._roomClient.close();
      this._roomClient = null;
      this._store.dispatch(stateActions.setRoomUrl(null));
      this._store.dispatch(stateActions.setRoomState("closed"));
    }
  }

  async enableWebcam(deviceId) {
    if (this._roomClient) {
      await this._roomClient.enableWebcam(deviceId);
    }
  }

  async disableWebcam() {
    if (this._roomClient) {
      await this._roomClient.disableWebcam();
    }
  }

  async enableShare() {
    if (this._roomClient) {
      await this._roomClient.enableShare();
    }
  }

  async disableShare() {
    if (this._roomClient) {
      await this._roomClient.disableShare();
    }
  }

  setBlur(blur) {
    if (this._roomClient) {
      this._roomClient.setBlur(blur);
    } else {
      // The roomClient is not available on the login page
      this._store.dispatch(setBlur(blur));
    }
  }

  createVideoFilter(stream, blur) {
    if (!this._mediaPipe) {
      this._mediaPipe = new MediaPipe();
    }

    return new VideoFilter(stream, blur, this._mediaPipe);
  }

  remoteControlMouse(uMsg, wParam, x, y) {
    if (this._roomClient) {
      this._roomClient.remoteControlMouse(uMsg, wParam, x, y);
    }
  }

  remoteControlKeyboard(uMsg, wParam, lParam) {
    if (this._roomClient) {
      this._roomClient.remoteControlKeyboard(uMsg, wParam, lParam);
    }
  }

  startControl() {
    if (this._roomClient) {
      this._roomClient.startControl();
    }
  }

  stopControl() {
    if (this._roomClient) {
      this._roomClient.stopControl();
    }
  }

  controlMouse(uMsg, wParam, x, y) {
    if (this._roomClient) {
      this._roomClient.controlMouse(uMsg, wParam, x, y);
    }
  }

  controlKeyboard(uMsg, wParam, lParam) {
    if (this._roomClient) {
      this._roomClient.controlKeyboard(uMsg, wParam, lParam);
    }
  }
}
