export function generateFieldId(formName, fieldName) {
  return formName + "-" + fieldName;
}

export function generateMessageId(formName, fieldName) {
  return formName + fieldName;
}

export function validate(formFields, values) {
  const errors = {};

  const requiredFields = formFields.filter(field => field.required);
  requiredFields.forEach(({ name }) => {
    if (!values[name]) {
      errors[name] = "loginFormErrorMessageRequired";
    } else if (values[name].replace(/(^\s+|\s+$)/g, "") === "") {
      //all white spaces
      errors[name] = "loginFormErrorMessageInvalid";
    }
  });

  const minDigitsFields = formFields.filter(field => field.minDigits);
  minDigitsFields.forEach(({ name, minDigits }) => {
    if (values[name] && values[name].length < minDigits) {
      errors[name] = "loginFormErrorMessageNotEnoughDigits";
    }
  });

  const emailValidatorFields = formFields.filter(field => field.emailValidator);
  emailValidatorFields.forEach(({ name, emailValidator }) => {
    if (values[name] && !emailValidator.test(values[name])) {
      errors[name] = "loginFormErrorMessageInvalidEmailAddress";
    }
  });

  const bridgeRestrictedFields = formFields.filter(() => true);
  bridgeRestrictedFields.forEach(({ name }) => {
    if (
      values[name] &&
      (values[name].indexOf("~") !== -1 ||
        values[name].indexOf("\\") !== -1 ||
        values[name].indexOf("|") !== -1 ||
        values[name].indexOf("%") !== -1)
    ) {
      errors[name] = "loginFormErrorMessageNotAccept";
    }
  });

  const digitsValidatorFields = formFields.filter(
    field => field.digitsValidator
  );
  digitsValidatorFields.forEach(({ name, digitsValidator }) => {
    if (
      values[name] &&
      values[name] !== "" &&
      !digitsValidator.test(values[name])
    ) {
      errors[name] = "loginFormErrorMessageDigitsOnly";
    }
  });
  return errors;
}
