import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import { ShakeHard } from "reshake";

const styles = theme => ({
  root: {
    width: "1em"
  },
  shaking: {
    width: "2em"
  }
});

class ConferenceLockIndicator extends Component {
  render() {
    const { conference, classes, session } = this.props;
    return conference && conference.secure ? (
      <ShakeHard active={session.shakeSecureIcon} fixed q={2}>
        <SvgIcon
          iconName="conferenceLockIndicator"
          color="primaryMainText"
          className={session.shakeSecureIcon ? classes.shaking : classes.root}
        />
      </ShakeHard>
    ) : null;
  }
}

const mapStateToProps = ({ conference, session }) => ({
  conference,
  session
});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps)(ConferenceLockIndicator))
);
