import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import LocalizedText from "../reusable/LocalizedText";
import { Menu, MenuItem } from "@material-ui/core";
import { handleQuestionRemove } from "./actions";

const styles = theme => ({
  text: {
    color: theme.colors.primaryTextColor
  }
});

class QuestionCardOptionMenu extends Component {
  handleRemoveQuestion = () => {
    const { handleClose, questionId, session } = this.props;
    const { userId } = session;

    this.props.handleQuestionRemove(userId, questionId);
    handleClose();
  };

  render() {
    const { classes, id, open, anchorEl, handleClose } = this.props;

    return (
      <Menu id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={this.handleRemoveQuestion}>
          <LocalizedText
            value="removeQuestion"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
      </Menu>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  handleQuestionRemove: (userId, questionId) =>
    dispatch(handleQuestionRemove(userId, questionId))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(QuestionCardOptionMenu)
    )
  )
);
