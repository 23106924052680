import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "block",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  mainContent: {
    width: "100%",
    height: "100%",
    display: "inline-block"
  },
  title: {
    float: "left",
    margin: "5px"
  },
  hrDiv: {
    borderTop: `4px solid ${theme.colors.primaryTextColor}`
  }
});

class PortalHeader extends Component {
  getMoreComponents = () => {
    return this.props.component;
  };

  render() {
    const { classes, title } = this.props;
    const otherComponents = this.getMoreComponents();
    return (
      <div className={classes.root}>
        <div className={classes.mainContent}>
          <LocalizedText value={title} variant="h5" className={classes.title} />
          {otherComponents}
        </div>
        <div className={classes.hrDiv} />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(PortalHeader))
  )
);
