export const PortalLoginErrors = {
  UNKNOWN: -1,
  SERVER_ERROR: 0,
  INVALID_LOGIN: 1
};

export const handlePortalLoginError = errorCode => {
  switch (errorCode) {
    case 0:
      return PortalLoginErrors.SERVER_ERROR;
    case 1:
      return PortalLoginErrors.INVALID_LOGIN;
    default:
      return PortalLoginErrors.UNKNOWN;
  }
};
