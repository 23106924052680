import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LocalizedText from "../reusable/LocalizedText";
import { Menu, MenuItem } from "@material-ui/core";
import {
  promoteParty,
  removeParty,
  reorderParty,
  announcePartyNameToConference
} from "./actions";

const styles = theme => ({
  text: {
    color: theme.colors.primaryTextColor
  }
});

class QAOptionMenu extends Component {
  handlePromoteToPodium = () => {
    const { partyId, session, handleClose } = this.props;
    this.props.promoteParty(partyId, session.userId);
    handleClose();
  };

  handleRemoveUser = () => {
    const { partyId, session, handleClose } = this.props;
    this.props.removeParty(partyId, session.userId);
    handleClose();
  };

  handleMoveFirst = () => {
    const { partyId, session, handleClose } = this.props;
    this.props.reorderParty(partyId, session.userId, 1);
    handleClose();
  };

  handleMoveLast = () => {
    const { partyId, session, handleClose, qa } = this.props;
    this.props.reorderParty(partyId, session.userId, qa.queue.length);
    handleClose();
  };

  handleMoveUp = () => {
    const { partyId, session, handleClose, qa } = this.props;
    const { queue } = qa;
    const currentIndex = queue.findIndex(id => id === partyId);
    if (currentIndex > 0) {
      this.props.reorderParty(partyId, session.userId, currentIndex);
    }
    handleClose();
  };

  handleMoveDown = () => {
    const { partyId, session, handleClose, qa } = this.props;
    const { queue } = qa;
    const currentIndex = queue.findIndex(id => id === partyId);
    if (currentIndex !== -1 && currentIndex < queue.length - 1) {
      this.props.reorderParty(partyId, session.userId, currentIndex + 2);
    }
    handleClose();
  };

  handleAnnounceName = () => {
    const { partyId, session, handleClose } = this.props;
    this.props.announcePartyNameToConference(partyId, session.userId);
    handleClose();
  };

  render() {
    const { classes, id, open, anchorEl, handleClose, conference } = this.props;

    const { pnrEnabled } = conference;

    return (
      <Menu id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={this.handlePromoteToPodium}>
          <LocalizedText
            value="promoteToPodium"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
        {pnrEnabled && (
          <MenuItem onClick={this.handleAnnounceName}>
            <LocalizedText
              value="announceName"
              variant="body2"
              className={classes.text}
            />
          </MenuItem>
        )}
        <MenuItem onClick={this.handleMoveFirst}>
          <LocalizedText
            value="moveFirst"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
        {/*
        <MenuItem onClick={this.handleMoveUp}>
          <LocalizedText
            value="moveUp"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
        <MenuItem onClick={this.handleMoveDown}>
          <LocalizedText
            value="moveDown"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
        <MenuItem onClick={this.handleMoveLast}>
          <LocalizedText
            value="moveLast"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
        */}
        <MenuItem onClick={this.handleRemoveUser}>
          <LocalizedText
            value="removeFromQueue"
            variant="body2"
            className={classes.text}
          />
        </MenuItem>
      </Menu>
    );
  }
}

const mapStateToProps = ({ session, qa, conference }) => ({
  session,
  qa,
  conference
});

const mapDispatchToProps = dispatch => ({
  promoteParty: (partyId, userId) => dispatch(promoteParty(partyId, userId)),
  removeParty: (partyId, userId) => dispatch(removeParty(partyId, userId)),
  reorderParty: (partyId, userId, position) =>
    dispatch(reorderParty(partyId, userId, position)),
  announcePartyNameToConference: (partyId, userId) =>
    dispatch(announcePartyNameToConference(partyId, userId))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(QAOptionMenu))
  )
);
