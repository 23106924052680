//-----------Action types----------//

export const PARTICIPANT_LIST_POPOUT_SHOW = "showParticipantListPopout";
export const PARTICIPANT_LIST_POPOUT_HIDE = "hideParticipantListPopout";

//---------Action creators-------------//

export const showParticipantListPopout = windowName => {
  return {
    type: PARTICIPANT_LIST_POPOUT_SHOW,
    payload: windowName
  };
};

export const hideParticipantListPopout = () => {
  return {
    type: PARTICIPANT_LIST_POPOUT_HIDE
  };
};
