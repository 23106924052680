import jsCookie from "js-cookie";
import Logger from "../../Logger";

const logger = new Logger("CookiesManager");

const DEVICES_COOKIE = "mediasoup-demo.devices";
const VIDEO_INPUT_COOKIE = "mediasoup-demo.videoInput";

const cookie = jsCookie
  .withAttributes({ expires: 365, sameSite: "Lax", secure: true })
  .withConverter({
    read: value => JSON.parse(value),
    write: value => JSON.stringify(value)
  });

export function getDevices() {
  return cookie.get(DEVICES_COOKIE);
}

export function setDevices(devices) {
  logger.debug("setDevices() [devices:%o]", devices);
  cookie.set(DEVICES_COOKIE, devices);
}

export function getVideoInput() {
  return cookie.get(VIDEO_INPUT_COOKIE);
}

export function setVideoInput(videoInput) {
  logger.debug("setVideoInput() [videoInput:%o]", videoInput);
  cookie.set(VIDEO_INPUT_COOKIE, videoInput);
}
