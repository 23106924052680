//-----------Action types----------//

export const SHOW_CHAT_POPOUT = "SHOW_CHAT_POPOUT";
export const HIDE_CHAT_POPOUT = "HIDE_CHAT_POPOUT";
export const SHOW_TRANSCRIPT_POPOUT = "SHOW_TRANSCRIPT_POPOUT";
export const HIDE_TRANSCRIPT_POPOUT = "HIDE_TRANSCRIPT_POPOUT";

//---------Action creators-------------//

export const showChatPopout = windowName => ({
  type: SHOW_CHAT_POPOUT,
  payload: windowName
});

export const hideChatPopout = () => ({
  type: HIDE_CHAT_POPOUT
});

export const showTranscriptPopout = windowName => ({
  type: SHOW_TRANSCRIPT_POPOUT,
  payload: windowName
});

export const hideTranscriptPopout = () => ({
  type: HIDE_TRANSCRIPT_POPOUT
});
