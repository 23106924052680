import {
  SHOW_CHAT_POPOUT,
  HIDE_CHAT_POPOUT,
  SHOW_TRANSCRIPT_POPOUT,
  HIDE_TRANSCRIPT_POPOUT
} from "../actions/popoutWindowsActions";
import { USER_LOGOUT } from "../actions/types";
import { ChatPopoutState } from "../components/Chat";
import { TranscriptPopoutState } from "../components/transcript";

export default function popoutWindowsReducer(
  state = {
    chatWindowState: ChatPopoutState.CLOSE,
    chatWindowName: null,
    transcriptWindowState: TranscriptPopoutState.CLOSE,
    transcriptWindowName: null
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case SHOW_CHAT_POPOUT:
      newState = {
        ...state,
        chatWindowState: ChatPopoutState.OPEN,
        chatWindowName: action.payload
      };
      return newState;
    case SHOW_TRANSCRIPT_POPOUT:
      newState = {
        ...state,
        transcriptWindowState: TranscriptPopoutState.OPEN,
        transcriptWindowName: action.payload
      };
      return newState;
    case HIDE_CHAT_POPOUT:
      newState = {
        ...state,
        chatWindowState: ChatPopoutState.CLOSE,
        chatWindowName: null
      };
      return newState;
    case HIDE_TRANSCRIPT_POPOUT:
      newState = {
        ...state,
        transcriptWindowState: TranscriptPopoutState.CLOSE,
        transcriptWindowName: null
      };
      return newState;
    case USER_LOGOUT:
      newState = {
        ...state,
        chatWindowState: ChatPopoutState.CLOSE,
        chatWindowName: null,
        transcriptWindowState: TranscriptPopoutState.CLOSE,
        transcriptWindowName: null
      };
      return newState;
    default:
      return state;
  }
}
